import React from "react";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";

const About = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>
        {/* Breadcrumb Section */}
        <div className="breadcrumb">
            <div className="overlay"></div>
            <h1 className="title">QUIENES SOMOS</h1>
        </div>

        {/* About Text */}
        <div className="container about">
            <div className="row mb-4">
                <div className="col-md-12">
                    <Link to={`../`} onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }} className="home-btn">Atrás</Link>
                </div>
            </div>
            <div className="row">
                {/* <div className="col-md-4">
                    <img src="./assets/images/about-icp.png" className="w-100" alt="About ICP"/>
                </div> */}
                <div className="col-md-12">
                    <h2>QUÉ ES IALE</h2>

                    <p>
                        El Índice de Afinidad de Libertad Económica es una herramienta que recoge, analiza y presenta información sobre la actividad legislativa de cada uno de los congresistas (senadores y representantes a la Cámara), arrojando un porcentaje de <b>afinidad con los principios de la libertad económica</b>. 
                    </p>

                    <br/>

                    <h4>A TRAVÉS DE ESTA HERRAMIENTA DE MAPEO LEGISLATIVO</h4>

                    <p>
                        <b>Monitoreamos</b> la forma en que los congresistas toman decisiones, conociendo la congruencia que éstas tienen con los principios de la libertad económica.
                    </p>
                    <p>
                        <b>Determinamos y advertimos</b> el impacto económico de la actividad legislativa promoviendo la responsabilidad política de los congresistas.
                    </p>
                </div>
            </div>

            <div className="row our-values" style={{ marginTop: 30 }}>
                <div className="col-12">
                    <h4>CON LOS RESULTADOS DE IALE LOS CIUDADANOS PODRÁN:</h4>

                    <p>
                        <b>Conocer</b> la forma en que las decisiones legislativas de cada congresista impactan sus derechos económicos fundamentales como consumidores, trabajadores y empresarios.
                    </p>
                    <p>
                        <b>Vigilar</b> el impacto económico de las decisiones legislativas en el desempeño de la economía del país, a partir del monitoreo de indicadores económicos clave.
                    </p>
                    <p>
                        <b>Promover</b> iniciativas que contrarresten/promuevan los efectos negativos/positivos de una decisión legislativa.
                    </p>
                    <p>
                        <b>Acceder</b> a  información basada en datos para tomar mejores decisiones en elecciones legislativas.
                    </p>

                    <br/>

                    <h4>QUÉ METODOLOGÍA USAMOS</h4>

                    <p>
                        Para determinar la afinidad de la actividad legislativa de los congresistas con los principios de la libertad económica empleamos los cinco criterios del <a href="https://www.fraserinstitute.org/" target="_blank" referrerPolicy="no-referrer">Fraser Institute</a> (más uno diseñado por el ICP) y un modelo de ponderación estadística mediante investigación mixta:
                    </p>

                    <br/>

                    <h4>Componente  cualitativo</h4>

                    <p><b>Seis criterios</b> para evaluar la afinidad:</p>

                    <img src="./assets/images/image1.png" style={{ maxWidth: 500 }} alt="about image" />
                
                    <br/>
                    <br/>

                    <h4>Componente Cuantitativo</h4>

                    <p>Modelo estadístico que a partir de un <b>sistema de promedios ponderados</b> por criterios de evaluación, asigna una calificación individual a cada congresista. De aquí surge el Índice de Afinidad de cada uno con los principios de la libertad económica.</p>

                    <p><b>El puntaje que arroja el modelo corresponde al porcentaje de afinidad de la actividad legislativa del Congresista con los principios de la libertad económica.</b></p>

                    <p><b>El espectro de desempeño oscila entre 0 y 100</b></p>

                    <br/>

                    <h4>Los criterios de evaluación</h4>

                    <br/>

                    <img src="./assets/images/image2.png" style={{ maxWidth: 500 }} alt="about image" />

                    <br/>
                    <br/>

                    <h4>Una herramienta de:</h4>

                    <p>
                        <b>Instituto de Ciencia Política Hernán Echavarría Olózaga</b><br/>
                        Somos un centro de pensamiento colombiano de origen empresarial, apartidista, privado e independiente. Desde 1987 promovemos y defendemos la libertad económica y las instituciones democráticas.
                    </p>

                    <br/>

                    <h4>Con el apoyo de:</h4>

                    <p>
                        <b>Atlas Network</b>, organización internacional que busca fortalecer una red de organizaciones asociadas independientes que promuevan la libertad individual y se comprometan con identificar y eliminar las barreras para el florecimiento humano.
                    </p>
                </div>
            </div>
        </div>
    </>
};

export default About;
