import { useEffect } from "react"
import { baseUrl } from "../../../../Config"

const VotersGroup = ({ title, link, votes }) => {

    useEffect(() => {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('.voting-cage [data-bs-toggle="popover"]'))
        popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl) // eslint-disable-line
        })
    }, [])

    return <>
        <div className="voting-cage">
            <p className="voting-cage-title">{ title }</p>

            <div className="voters">
                { votes.map(vote => (
                    <img key={vote.id} src={`${baseUrl}/members/${vote.photo}`} className="authorimg" alt="Author Avatar" data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`${vote.name}`} />
                ))}

                { votes.length === 0 && (
                    <p className="mb-0">Sin datos</p>
                )}
            </div>

            <p className="voting-cage-footer mt-4 mb-0">
                { link != '' && link != null ? (
                    <a href={link} target="_blank">Ver ponencia</a>
                ) : (
                    <span>No hay ponencia</span>
                )}
            </p>
        </div>
    </>
}

export default VotersGroup