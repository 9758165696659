import React, { useState } from 'react';
import Slider from 'react-slick';
import { baseUrl } from '../../Config';

const Carousel = ({ projects }) => {

  const CustomPrevArrow = (props) => (
    <div
      className="slick-arrow slick-prev"
      onClick={props.onClick}
    >
      <img src='./assets/images/arrow-left1.svg' />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div
      className="slick-arrow slick-next"
      onClick={props.onClick}
    >
      <img src='./assets/images/arrow-right.svg' />
    </div>
  );

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: projects.length >= 4 ? 4 : 4,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: projects.length >= 4 ? 4 : projects.length,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: projects.length >= 4 ? 4 : projects.length,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
        {projects.map(project => (
            <div key={project.id} className="thumb-carousel-item">
                <img src={`${baseUrl}/legis/${project.image}`} alt={project.name} />

                <div className="thumb-carousel-caption text-left">
                    <h3>{project.name}</h3>
                    
                    <a href={project.link} target='_blank' className='thumb-btn'>Ver más</a>
                </div>
            </div>
        ))}
    </Slider>
  );
}

export default Carousel;