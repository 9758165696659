import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../Config";
import Loader from "../../components/Loader";

const Methodology = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(true);
    const [ content, setContent ] = useState('');
    
    useEffect(() => {
        window.scrollTo(0, 0);
        getContent();
    }, [pathname]);

    const getContent = () => {
        fetch(`${baseUrl}/api/v1/methodology`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setContent(data.data.content);
            setLoading(false);
        })
    }

    
    if (loading) {
        return <Loader/>;
    }

    return <>
        {/* Breadcrumb Section */}
        <div className="breadcrumb-m">
        </div>

        {/* About Text */}
        <div className="container about">
            {/* <div className="row mb-4">
                <div className="col-md-12">
                    <h2><a href="#" target="_blank">CON IALE PODRÁS</a></h2>
                </div>
            </div> */}

            <div className="row mb-4">
                <div className="col-md-12">
                    <h2>CON IALE PODRÁS</h2>
                </div>
            </div>

            <div className="row m-card-container m-space-bottom">
                <div className="col-md-6 col-xs-12">
                    <div className="m-card">
                        <div className="d-flex align-items-center mb-2">
                            <img src="./assets/images/chart.svg" />&nbsp;&nbsp;
                            

                            <p className="mb-0 m-card-content">
                                <span className="m-card-title">Obtener</span>&nbsp;&nbsp;información sobre el proceso legislativo
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="m-card">
                        <div className="d-flex align-items-center mb-2">
                            <img src="./assets/images/bulb.svg" />&nbsp;&nbsp;
                            
                            <p className="mb-0 m-card-content pt-2">
                                <span className="m-card-title">Analizar</span>&nbsp;&nbsp;el impacto de proyectos de ley y leyes aprobadas en la libertad económica.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="m-card">
                        <div className="d-flex align-items-center mb-2">
                            <img src="./assets/images/search.svg" />&nbsp;&nbsp;

                            <p className="mb-0 m-card-content pt-2">
                                <span className="m-card-title">Conocer</span>&nbsp;&nbsp;el desempeño y las decisiones de los congresistas
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-xs-12">
                    <div className="m-card">
                        <div className="d-flex align-items-center mb-2">
                            <img src="./assets/images/unlock.svg" />&nbsp;&nbsp;

                            <p className="mb-0 m-card-content pt-2">
                                <span className="m-card-title">Ejercer</span>&nbsp;&nbsp;control ciudadano informado.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row m-space-bottom">
                <div className="col-md-6 col-xs-12">
                    <img src="./assets/images/mimage.png" className="w-100" />
                </div>
                <div className="col-md-6 col-xs-12 d-flex justify-content-center flex-column">
                    <h2>METODOLOGÍA</h2>

                    <p>
                        IALE es un Indicador de Afinidad con la Libertad Económica para medir la afinidad de proyectos de ley en curso, leyes aprobadas y congresistas, con la libertad económica.
                    </p>
                    <p>
                        Esta herramienta permite identificar el potencial impacto de la legislación y realizar seguimiento de sus efectos tras su implementación.
                    </p>
                    <p>
                        También ofrece información sobre el desempeño de los congresistas, facilitando así el ejercicio de control ciudadano y proporcionando insumos esenciales para la toma de decisiones en procesos electorales legislativos.
                    </p>
                </div>
            </div>

            <div className="row">
                <p className="m-title">El <b>Indicador</b> está formulado en dos componentes:</p>
            </div>

            <div className="row m-space-top m-space-bottom m-space-y">
                <div className="col-md-6 col-xs-12">
                    <div className="blue-box">
                        <h2 className="blue-box-title">
                            Cualitativo
                        </h2>

                        <p className="blue-box-content mb-0">
                            Establece seis (6) dimensiones que agrupan cuarenta y tres (43) factores, cinco (5) del Fraser Institute de Canadá y una (1) formulada por el Instituto de Ciencia Política.<br/><br/>

                            Estas dimensiones permiten determinar las afectaciones a la libertad económica, arrojando un puntaje numérico al contenido del proyecto de ley.<br/><br/>

                            1. Tamaño del gobierno y tributación.<br/>
                            2. Propiedad privada y Estado de derecho.<br/>
                            3. Dinero Sano.<br/>
                            4. Libertad para comerciar internacionalmente.<br/>
                            5. Intervención en los mercados de empresas, trabajo y capitales.<br/>
                            6. Empoderamiento de la sociedad civil.<br/>
                        </p>
                    </div>
                </div>
                <div className="col-md-6 col-xs-12">
                    <div className="blue-box">
                        <h2 className="blue-box-title">
                            Cuantitativo
                        </h2>

                        <p className="blue-box-content mb-0">
                            Establece dos (2) mediciones: <br/><br/>

                            <b>IALE proyectos de ley o leyes aprobadas.</b> Un sistema de calificación mediante una escala de evaluación ordinal de tres niveles de afinidad con relación a las seis (6) dimensiones que agrupan los cuarenta y tres (43) factores que permiten determinar las afectaciones a la libertad económica, arrojando un puntaje numérico al contenido del proyecto de ley.<br/><br/>

                            <b>IALE congresistas.</b> Un sistema de promedios ponderados establece la afinidad del congresista con la libertad económica, de acuerdo con el puntaje IALE del proyecto de ley y de su participación como autor, ponente y según sus votaciones.
                        </p>
                    </div>
                </div>
            </div>

            {/* <p style={{ color: '#000000' }}>
                El espectro oscila entre 100 y 0. Mientras más cercano a 100 esté el puntaje de un congresista, mayor afinidad tendrá su actividad legislativa con los principios de la libertad económica. 
            </p>

            <p className="m-space-bottom" style={{ color: '#000000' }}>
                La actividad legislativa se evalúa teniendo en cuenta tres variables: <br/>
                I) Autorías<br/>
                II) Ponencias <br/>
                III) Votaciones<br/><br/>

                **Estos criterios fueron determinados teniendo en cuenta el proceso que surte un trámite legislativo en el Congreso de la República en Colombia.  
            </p> */}

            <div className="row m-space-bottom">
                <div className="col-12">
                    <div className="caution">
                        <div className="d-flex align-items-center">
                            <img src="./assets/images/caution.svg" /> &nbsp;&nbsp;
                            <span className="m-card-title">IMPORTANTE</span>
                        </div>

                        <p>
                            La obtención de un nivel de afinidad muy bajo o bajo en el IALE por parte de un congresista no implica necesariamente que sea un mal congresista. La función principal de IALE es medir la afinidad del congresista con el modelo de la libertad económica. Por lo tanto, un puntaje bajo en este indicador refleja únicamente la alineación del congresista con la libertad económica, sin emitir un juicio sobre su competencia o desempeño general como legislador.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="m-card">
                        <p className="m-card-title mb-0">
                            <a href="https://icpcolombia.org/wp-content/uploads/2024/02/METODOLOGIA-IALE.pdf" target="_blank" >
                                Para conocer más detalles técnicos de la metodología
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div className="text-center mt-4 mb-4 px-3">
                <Link className="btn btn-primary ver-mas" target="_blank" to={'https://icpcolombia.org/wp-content/uploads/2024/02/PREGUNTAS-FRECUENTES-IALE.pdf'}>Preguntas Frecuentes</Link>
            </div>
        </div>
    </>
};

export default Methodology;
