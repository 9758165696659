import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Parties from './pages/Parties/Parties';
import MemberProfile from './pages/MemberProfile/MemberProfile';
import Methodology from './pages/About/Methodology';
import Footer from './components/Footer/Footer';
import Projects from './pages/Projects/Projects';

function App() {
    return (
        <div>
            {/* Navbar */}
            <nav className="navbar navbar-expand-lg fixed-top">
                <div className="container-fluid">
                    <Link className="navbar-brand" to={`/`}>
                        <img src="./assets/images/icp-logo-trans.png" alt="ICP Logo" />
                    </Link>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <img src="./assets/images/menu.png" />
                    </button>

                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="comencemos" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Indicador
                                </a>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="comencemos">
                                    <li><a className="dropdown-item" href={'./#seating'}>Congresistas</a></li>
                                    <li><Link className="dropdown-item" to={`./projects`}>Normatividad</Link></li>
                                </ul>
                            </li>
                            {/* <li className="nav-item">
                                <Link to={`#`} className="nav-link">Indicador</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link to={`/methodology`} className="nav-link">Metodología</Link>
                            </li>
                            <li className='nav-item'>
                                <Link target="_blank" to={'https://icpcolombia.org/wp-content/uploads/2024/02/PREGUNTAS-FRECUENTES-IALE.pdf'} className='nav-link'>Preguntas Frecuentes</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Content */}
            <Routes>
                <Route path="/" exact element={<Home/>} />
                <Route path="/about" exact element={<About/>} />
                <Route path="/methodology" exact element={<Methodology/>} />
                <Route path="/parties/:origin/:id" exact element={<Parties/>} />
                <Route path="/parties/:origin" exact element={<Parties/>} />
                <Route path="/member-profile/:member/project/:id/:tab" exact element={<MemberProfile/>} />
                <Route path="/member-profile/:member/project/:id" exact element={<MemberProfile/>} />
                <Route path="/projects" exact element={<Projects/>} />
            </Routes>

            {/* Footer */}
            <Footer />
        </div>
    );
}

export default App;
