import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { baseUrl } from "../../Config";
import { useParams } from 'react-router';
import Loader from '../../components/Loader';

const Parties = () => {
    const projectBox = useRef();

    const [ loading, setLoading ] = useState(true);
    const [ parties, setParties ] = useState([]);
    const [ projects, setProjects ] = useState([]);
    const [ selectedParty, setSelectedParty] = useState(0);
    const [ selectedMember, setSelectedMember] = useState(null);
    const [ lostReason, setLostReason] = useState('');
    const [ showPartiesBox, setShowPartiesBox ] = useState(true);
    const [ showProjectBox, setShowProjectBox ] = useState(true);
    const [ boxHeight, setBoxHeight ] = useState(0);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const params = useParams()

    useEffect(() => {
        window.scrollTo(0, 0);

        fetchProjects();
        fetchUserData();
    }, [pathname]);

    useEffect(() => {
        if (selectedMember && projectBox.current) {
            if (projectBox.current.offsetHeight != 0) {
                projectBox.current.style.height = `${projectBox.current.offsetHeight}px`;
            }
        }
    }, [selectedMember]);

    useEffect(() => {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
        popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl) // eslint-disable-line
        })
    }, [selectedParty])

    const fetchProjects = () => {
        fetch(`${baseUrl}/api/v1/projects`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setProjects(data.data);
        })
    }

    const fetchUserData = () => {
        fetch(`${baseUrl}/api/v1/parties/${params.origin}`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            if (params.id) {
                let party = data.data.filter(function(item) {
                     return item.id == params.id;
                });
                console.log(party)
                setSelectedParty(party[0])
            } else {
                setSelectedParty(data.data[0]);
            }
            
            setParties(data.data);
            setLoading(false);
        })
    }

    const onPartySelect = (party) => {
        setSelectedParty(party);
        setShowPartiesBox(false);
        setSelectedMember(null);
        setShowProjectBox(true);
    }

    const onMemberSelect = (e, member) => {
        e.preventDefault();

        if (member.lost == 1) {
            setLostReason(member.reason)
            // return;
        }

        setSelectedMember(member);
        setShowPartiesBox(false);
    }

    const expandParties = () => {
        setShowPartiesBox(true);
    }

    const getProgressColor = (value) => {
        if (value >= 0 && value <= 30) {
            return '#C60A15';
        } else if (value > 30 && value <= 60) {
            return '#FFB800';
        } else {
            return '#068340';
        }
    }

    const toggleProjectBox = (e) => {
        e.preventDefault();

        if (showProjectBox) {
            setBoxHeight(projectBox.current.offsetHeight);
            projectBox.current.style.height = '0';
        } else {
            projectBox.current.style.height = `${boxHeight}px`;
        }

        setShowProjectBox((showProjectBox) => !showProjectBox);
    }

    if (loading) {
        return <Loader/>;
    }

    return <>
        {/* Breadcrumb Section */}
        <div className="breadcrumb px-2">
            <div className="overlay"></div>
            {/* <h1 className="title font-38">IALE<br/>Datos precisos, decisiones informadas </h1> */}
        </div>

        <div className="text-center p-2 page-title">
            <p className="font-primary-color senado-title">{ params.origin == 'camara' ? 'Cámara' : params.origin }</p>
        </div>

        <div className="text-left ps-4 d-none select-party">
            <p className="senado-title fw-normal" style={{ textTransform: 'none' }}>Seleccione un partido político</p>
        </div>

        <div className="container-fluid senado">
            <div className="row my-3 d-none party-selector">
                <div className="col-12">
                    <div className="card selected-party">
                        <div className="d-flex justify-content-between align-items-center p-3" onClick={expandParties}>
                            <ul className="parties mb-0">
                                <li className="mb-0">
                                    <div className="party-circle me-2" style={{ background: selectedParty.color }}></div>
                                    <b>{selectedParty.count}</b>&nbsp;{selectedParty.name}
                                </li>
                            </ul>

                            <img src={ showPartiesBox ? `./assets/images/arrow-up.png` : `./assets/images/arrow-down.png`} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className={`col-md-4 ${showPartiesBox == false ? 'hide-parties-box' : ''}`}>
                    <div className="card party-list">
                        <div className="card-body p-4">
                            {parties.length > 0 && (
                                <ul className="parties">
                                    {parties.map(party => (
                                        <li className={selectedParty.id == party.id ? "senado-active" : ""} onClick={() => onPartySelect(party)} key={party.id}>
                                            <div className="party-circle me-2" style={{ background: party.color }}></div>
                                            <b>{party.count}</b>&nbsp;{party.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row py-2 mb-0">
                                {selectedParty.members.length > 0 && (
                                    selectedParty.members.map(member => (
                                        <div className="col-lg-2 col-md-3 col-sm-2 col-xs-3 col-2 text-center" style={{ position: 'relative' }} key={member.id}>
                                            { (selectedMember?.id == member.id && member.lost == 1) && (
                                                <img src="./assets/images/lost-icon.svg" className="lost-icon" />
                                            )}
                                            
                                            { member.lost == 1 ? (
                                                <a href="#" data-bs-toggle="modal" data-bs-target="#authors-modal" className="person" onClick={(e) => onMemberSelect(e, member)}>
                                                    <img src={`${baseUrl}/members/${member.photo}`} className={`w-100 avt ${(selectedMember?.id == member.id && member.lost == 1) ? 'lost-member' : ''}`} style={ (selectedMember?.id == member.id && member.lost == 0) ? {border: `3px solid ${selectedParty.color}`, filter: 'grayscale(0)'} : {} } data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`${member.name}<br/>Comisión ${member.comission}`} alt={member.name} />
                                                </a>
                                            ) : (
                                                <a href="#" className="person" onClick={(e) => onMemberSelect(e, member)}>
                                                    <img src={`${baseUrl}/members/${member.photo}`} className={`w-100 avt ${(selectedMember?.id == member.id && member.lost == 1) ? 'lost-member' : ''}`} style={ (selectedMember?.id == member.id && member.lost == 0) ? {border: `3px solid ${selectedParty.color}`, filter: 'grayscale(0)'} : {} } data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`${member.name}<br/>Comisión ${member.comission}`} alt={member.name} />
                                                </a>
                                            )}
                                            
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    {selectedMember != null ? (
                        <div className="card member-info-box">
                            <div className="card-body p-4 member-info-card">
                                <Link to={`/member-profile/${selectedMember.id}/project/${projects[0]?.id}/afinidad`} className="profile d-flex justify-content-between align-items-center mobile-button">
                                    AFINIDAD LIBERTAD ECONÓMICA &nbsp; <img src="./assets/images/arrow-right.png" />
                                </Link>

                                <div className="progress mt-2">
                                    <div className="progress-bar" role="progressbar" style={{width: `${selectedMember.progress}%`, backgroundColor: getProgressColor(selectedMember.progress)}} aria-valuenow={selectedMember.progress} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>

                                <label className="font-primary-color mt-1 fw-bold progress-text">{selectedMember.progress}/100</label>

                                <div className="avatar text-center mt-4">
                                    <img src={`${baseUrl}/members/${selectedMember.photo}`} style={{ border: `4px solid ${selectedParty.color}` }} />

                                    <h3 className="mt-3">{ selectedMember.name }</h3>
                                    <h4>{ selectedMember.position }</h4>
                                </div>

                                <div className="member-info my-3">
                                    <table className="w-100">
                                        <tbody>
                                            <tr>
                                                <td>Partido Político</td>
                                                <td>{ selectedParty.name }</td>
                                            </tr>
                                            <tr>
                                                <td>Periodo constitucional</td>
                                                <td>2022 - 2026</td>
                                            </tr>
                                            <tr>
                                                <td>Comisión</td>
                                                <td>{ selectedMember.comission }</td>
                                            </tr>
                                            <tr>
                                                <td>Circunscripción</td>
                                                <td>{ selectedMember.cincun }</td>
                                            </tr>
                                            {/* <tr>
                                                <td>Votación</td>
                                                <td>{ selectedMember.votacion }</td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>

                                <a href="#" onClick={(e) => toggleProjectBox(e)} className={`profile d-flex justify-content-between align-items-center ${showProjectBox ? 'profile-active' : ''}`} id="impacto">
                                    NORMATIVIDAD &nbsp; <img src={ showProjectBox ? './assets/images/arrow-down-white.png' : './assets/images/arrow-right.png'} id="expand" />
                                </a>

                                <div className="profile-info mt-2 overflow-hidden collapse-transition" id="profile-info" ref={projectBox}>
                                    <ul id="list">
                                        {projects.map(project => (
                                            <li key={project.id}>
                                                { project.status == 'ongoing' ? (
                                                    <>
                                                        <img src="./assets/images/ongoing.svg" alt="pending" style={{ width: 25 }} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src="./assets/images/done.svg" alt="pending" style={{ width: 25 }} />
                                                    </>
                                                ) }

                                                &nbsp;&nbsp;

                                                <Link to={`/member-profile/${selectedMember ? selectedMember.id : 0}/project/${project.id}`}>{ project.name }</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card no-member-card">
                            <div className="card-body p-4 no-member">
                                <div className="empty-box">
                                    Click en un congresista para ver más información
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

        <div className="container mt-8 mb-4 text-center back-container">
            <div className="row">
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <Link to={`../`} onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                    }} className="home-btn">Atrás</Link>

                    &nbsp;&nbsp;

                    <Link to={`/`} className="home-btn"><img src="./assets/images/home.svg" style={{ width: 12 }} alt="home icon" />&nbsp;&nbsp;Inicio</Link>
                </div>
            </div>
        </div>

        {/* Modal Info */}
        <div className="modal fade" id="authors-modal" tabIndex="-1" aria-labelledby="authors-modal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="container">
                            <p>{ lostReason }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Parties;