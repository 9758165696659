import { useEffect } from "react"

const RightCards = ({ project }) => {

    useEffect(() => {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('.rightcard-cage [data-bs-toggle="popover"]'))
        popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl) // eslint-disable-line
        })
    }, [])

    return <>
        <div className="card-body p-0 rightcard-cage" style={{ backgroundColor: '#FAFAFA', textAlign: 'right', }}>
            { project.link1_status == 'enabled' ? (
                <a href={project.link1} target="_blank" className="rightcard">
                    <img src="./assets/images/card1.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                    <h2>{ project.link1_name }</h2>
                </a>
            ) : (
                <a href={'#'} onClick={(e) => {
                    e.preventDefault();
                }} className="rightcard" data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`Próximamente`}>
                    <img src="./assets/images/card1.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                    <h2>{ project.link1_name }</h2>
                </a>
            )}

            { project.link2_status == 'enabled' ? (
                <a href={project.link2} target="_blank" className="rightcard">
                    <img src="./assets/images/card2.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                    <h2>{ project.link2_name }</h2>
                </a>
            ) : (
                <a href={'#'} onClick={(e) => {
                    e.preventDefault();
                }} className="rightcard" data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`Próximamente`}>
                    <img src="./assets/images/card1.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                    <h2>{ project.link2_name }</h2>
                </a>
            )}

            { project.link3_status == 'enabled' ? (
                <a href={project.link3} target="_blank" className="rightcard">
                    <img src="./assets/images/card3.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                    <h2>{ project.link3_name }</h2>
                </a>
            ) : (
                <a href={'#'} onClick={(e) => {
                    e.preventDefault();
                }} className="rightcard" data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`Próximamente`}>
                    <img src="./assets/images/card1.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                    <h2>{ project.link3_name }</h2>
                </a>
            )}

            { project.link4_status == 'enabled' ? (
                <a href={project.link4} target="_blank" className="rightcard">
                    <img src="./assets/images/card4.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                    <h2>{ project.link4_name }</h2>
                </a>
            ) : (
                <a href={'#'} onClick={(e) => {
                    e.preventDefault();
                }} className="rightcard" data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`Próximamente`}>
                    <img src="./assets/images/card1.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                    <h2>{ project.link4_name }</h2>
                </a>
            )}
{/* 
            <a href={project.link2} target="_blank" className="rightcard">
                <img src="./assets/images/card2.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                <h2>{ project.link2_name }</h2>
            </a>

            <a href={project.link3} target="_blank" className="rightcard">
                <img src="./assets/images/card3.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                <h2>{ project.link3_name }</h2>
            </a>

            <a href={project.link4} target="_blank" className="rightcard">
                <img src="./assets/images/card4.png" style={{ aspectRatio: 4, objectFit: 'cover', borderRadius: 15, }} className="w-100 mb-2" />
                <h2>{ project.link4_name }</h2>
            </a> */}
        </div>
    </>
}

export default RightCards