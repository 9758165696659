const ExpandButton = ({ expand, onClick }) => {
    return <>
        <div className="expand-all" onClick={onClick}>
            { !expand ? (
                <>Mostrar más&nbsp;&nbsp;</>
            ) : (
                <>Monstrar menos&nbsp;&nbsp;</>
            )}

            { expand ? (
                <img src="./assets/images/up-caret.svg" />
            ) : (
                <img src="./assets/images/down-caret.svg" />
            )}
        </div>
    </>
}

export default ExpandButton;