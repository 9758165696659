import { useEffect } from "react"
import { baseUrl } from "../../../../Config"

const VotersGroupOrigin = ({ title, link, votes, type, }) => {

    useEffect(() => {
        var popoverTriggerList = [].slice.call(document.querySelectorAll('.voting-cage [data-bs-toggle="popover"]'))
        popoverTriggerList.map(function (popoverTriggerEl) {
            return new bootstrap.Popover(popoverTriggerEl) // eslint-disable-line
        })
    }, [])

    return <>
        <div className="voting-cage">
            <div className="d-flex justify-content-between align-items-center mb-3" style={{ borderBottom: '1px solid #1A3E58', paddingBottom: 5 }}>
                <p className="voting-cage-title mb-0">{ title } ({ votes.length })</p>

                { type === 'yes' && (
                    <img src="./assets/images/check.svg" />
                )}

                { type === 'no' && (
                    <img src="./assets/images/close.svg" />
                )}

                { type === 'na' && (
                    <img src="./assets/images/no.svg" />
                )}
            </div>

            <div className="voters">
                { votes.map(vote => (
                    <img key={vote.id} src={`${baseUrl}/members/${vote.member?.photo}`} data-bs-toggle="popover" data-bs-placement="top" data-bs-html="true" data-bs-trigger="hover focus" data-bs-content={`${vote.member.name}`} className="authorimg" alt="Author Avatar" />
                ))}

                { votes.length === 0 && (
                    <p className="mb-0">Sin votos</p>
                )}
            </div>
        </div>
    </>
}

export default VotersGroupOrigin