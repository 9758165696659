import { useState } from "react";
import ExpandButton from "../ExpandButton/ExpandButton";
import { baseUrl } from "../../../../Config";
import { Link } from "react-router-dom";
import VotersGroup from "../VotersGroup/VotersGroup";
import VotersGroupOrigin from "../VotersGroupOrigin/VotersGroupOrigin";
import Concilia from "../Concilia/Concilia";

const Phase4 = ({ project, votes, concilia, }) => {
    const [ expandAll, setExpandAll ] = useState(false);
    const [ showAllVotes, setShowAllVotes ] = useState(false);

    return <>
        {/* <div className="row">
            <div className="col-12">
                <div className="tax-reform ps-3">
                    { project.titulo_link != null && project.titulo_link != '' ? (
                        <>
                            <a href={ project.titulo_link } target="_blank">{ project?.titulo }</a>
                        </>
                    ) : (
                        <>
                            <p>{ project?.titulo }</p>
                        </>
                    )}
                    <p className="project-subtitle">{ project?.short_description }</p>
                </div>
            </div>
        </div> */}

        <div className="object-container">
            <div className="row">
                <div className="col-12">
                    <ExpandButton
                        expand={expandAll}
                        onClick={() => {
                            setExpandAll((state) => {
                                return !state;
                            });
                        }}
                    />
                </div>
            </div>

            <div className="row accordion mt-4" id="accordionExample">
                { !showAllVotes ? (
                    <>
                        <div className="col-md-6 col-xs-12">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <img src="./assets/images/author.png" alt="Author Icon" /> &nbsp; &nbsp;
                                        Autor(es)
                                    </button>
                                </h2>

                                <div id="collapseOne" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="headingOne">
                                    <div className="accordion-body">
                                        {project.authors?.map(author => (
                                            <div className="d-flex align-items-center" key={author.id}>
                                                {/* <div className="me-2">
                                                    <img src={`${baseUrl}/members/${author?.image}`} className="authorimg" alt="Author Avatar" />
                                                </div> */}
                                                <div>
                                                    {/* <p className="accodian-text-primary">{ author.party }</p> */}
                                                    <p className="accodian-text">{ author.name }</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xs-12">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading3">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseOne">
                                        <img src="./assets/images/paper.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                        No. de proyecto
                                    </button>
                                </h2>

                                <div id="collapse3" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading3">
                                    <div className="accordion-body">
                                        <div className="d-flex">
                                        <div className="me-4">
                                                <p className="accodian-text-primary">Cámara</p>
                                                <p className="accodian-text">{ project.camara_no }</p>
                                            </div>
                                            <div>
                                                <p className="accodian-text-primary">Senado</p>
                                                <p className="accodian-text">{ project.senado_no }</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xs-12">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading6">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                        <img src="./assets/images/calendar.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                        Fecha de aprobación
                                    </button>
                                </h2>

                                <div id="collapse6" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading6">
                                    <div className="accordion-body">
                                        <p className="accodian-text">{ project.aprobacion }</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-xs-12">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading9">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                        <img src="./assets/images/binocular.svg" alt="Author Icon" /> &nbsp; &nbsp;
                                        Observaciones
                                    </button>
                                </h2>

                                <div id="collapse9" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading9">
                                    <div className="accordion-body">
                                        <div className="observation">
                                            <p className="accodian-text">{ project.observation }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-xs-12 mb-4">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading10">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                        <img src="./assets/images/author.png" alt="Author Icon" /> &nbsp; &nbsp;
                                        Conciliadores
                                    </button>
                                </h2>

                                <div id="collapse10" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading10">
                                    <div className="accordion-body">
                                        <div className="row m-space-y">
                                            <div className="col-md-6 col-xs-12">
                                                <Concilia
                                                    votes={ concilia }
                                                />
                                            </div>
                                            <div className="col-md-6 col-xs-12">
                                                <div className="voting-cage">
                                                    <p style={{ color: '#595959', fontWeight: 600 }}>Texto conciliado</p>

                                                    <a href={ project.conciliado } target="_blank" style={{ color: '#1A9D9F', }}>Visitar enlace</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-md-12 col-xs-12 mb-4">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading10">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                        Senado
                                    </button>
                                </h2>

                                <div id="collapse10" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading10">
                                    <div className="accordion-body">
                                        <div className="row m-space-y">
                                            <div className="col-md-4 col-xs-12">
                                                <VotersGroupOrigin
                                                    title={'A favor'}
                                                    link={'https://bit.ly/47O3oEA'}
                                                    votes={ votes.filter(vote => vote.state_id == 4 && vote.vote == 1 && vote.member.party.origin_id == 1) }
                                                    type={'yes'}
                                                />
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <VotersGroupOrigin
                                                    title={'En contra'}
                                                    link={'https://bit.ly/49OtDMS'}
                                                    votes={ votes.filter(vote => vote.state_id == 4 && vote.vote == 0 && vote.member.party.origin_id == 1) }
                                                    type={'no'}
                                                />
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <VotersGroupOrigin
                                                    title={'N/A'}
                                                    link={'https://bit.ly/49OtDMS'}
                                                    votes={ votes.filter(vote => vote.state_id == 4 && vote.vote == -1 && vote.member.party.origin_id == 1) }
                                                    type={'na'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 col-xs-12 mb-4">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="heading11">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                        Cámara
                                    </button>
                                </h2>

                                <div id="collapse11" className={`accordion-collapse collapse ${expandAll && 'show'}`} aria-labelledby="heading11">
                                    <div className="accordion-body">
                                        <div className="row m-space-y">
                                            <div className="col-md-4 col-xs-12">
                                                <VotersGroupOrigin
                                                    title={'A favor'}
                                                    link={'https://bit.ly/47O3oEA'}
                                                    votes={ votes.filter(vote => vote.state_id == 4 && vote.vote == 1 && vote.member.party.origin_id == 2) }
                                                    type={'yes'}
                                                />
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <VotersGroupOrigin
                                                    title={'En contra'}
                                                    link={'https://bit.ly/49OtDMS'}
                                                    votes={ votes.filter(vote => vote.state_id == 4 && vote.vote == 0 && vote.member.party.origin_id == 2) }
                                                    type={'no'}
                                                />
                                            </div>
                                            <div className="col-md-4 col-xs-12">
                                                <VotersGroupOrigin
                                                    title={'N/A'}
                                                    link={'https://bit.ly/49OtDMS'}
                                                    votes={ votes.filter(vote => vote.state_id == 4 && vote.vote == -1 && vote.member.party.origin_id == 2) }
                                                    type={'na'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                { !showAllVotes ? (
                    <Link className="btn btn-primary ver-mas" onClick={(e) => {
                        e.preventDefault();
                        setShowAllVotes(true);
                    }} to={'#'}>Ver votaciones</Link>
                ) : (
                    <Link className="btn btn-primary ver-mas" onClick={(e) => {
                        e.preventDefault();
                        setShowAllVotes(false);
                    }} to={'#'}>Regresar</Link>
                )}
            </div>
        </div>
    </>
}

export default Phase4