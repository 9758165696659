import React from "react";
import { useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import $ from 'jquery';
import Loader from '../../components/Loader';
import { baseUrl } from "../../Config";
import Carousel from "./Carousel";

const Home = () => {
 
    // Set loading state to true initially
    const [ loading, setLoading ] = useState(true);
    const [ origin, setOrigin ] = useState('senado');
    const [ banners, setBanners ] = useState([]);
    const [ showInfo, setShowInfo ] = useState(false);
    const [ senadoCount, setSenadoCount ] = useState([]);
    const [ camaraCount, setCamaraCount ] = useState([]);
    const [ senadoParties, setSenadoParties ] = useState([]);
    const [ camaraParties, setCamaraParties ] = useState([]);
    const [ projects, setProjects ] = useState([]);

    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);

        addEvents();
        // initCarousel();
    }, [pathname, banners, camaraParties])

    useEffect(() => {
        fetchBanners();
    }, [])

    const fetchSenado = () => {
        fetch(`${baseUrl}/api/v1/home/parties/senado`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setSenadoParties(data.data);
            let members = [];

            data.data.map(party => {
                members.push(party.count);
            });

            setSenadoCount(members);
            fetchCamara();
        })
    }

    const fetchCamara = () => {
        fetch(`${baseUrl}/api/v1/home/parties/camara`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setCamaraParties(data.data);
            let members = [];

            data.data.map(party => {
                members.push(party.count);
            });

            setCamaraCount(members);
            fetchLegis();
        })
    }

    const fetchLegis = () => {
        fetch(`${baseUrl}/api/v1/legis`)
        .then(response => {
            return response.json()
        })
        .then(data => {
          setProjects(data.data);
          setLoading(false);
        })
      }

    const initCarousel = () => {
        try {
            const myCarouselElement = document.querySelector('#carousel')

            const carousel = new bootstrap.Carousel(myCarouselElement, { // eslint-disable-line
                interval: 2000,
                touch: false
            })
        } catch (error) {
            
        }
    }

    const fetchBanners = () => {
        fetch(`${baseUrl}/api/v1/banners`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setBanners(data.data);
            fetchSenado();
        })
    }

    const addEvents = () => {

        if (!$('.party1')) {
            return;
        }

        const senado_parties = [
            '.party1',
            '.party2',
            '.party3',
            '.party4',
            '.party5',
            '.party6',
            '.party7',
            '.party8',
            '.party9',
            '.party10',
            '.party11',
            '.party12',
        ];
    
        const senado_buttons = [
            '.senado1',
            '.senado2',
            '.senado3',
            '.senado4',
            '.senado5',
            '.senado6',
            '.senado7',
            '.senado8',
            '.senado9',
            '.senado10',
            '.senado11',
            '.senado12',
        ];
    
        const camara_parties = [
            '.camaraparty1',
            '.camaraparty2',
            '.camaraparty3',
            '.camaraparty4',
            '.camaraparty5',
            '.camaraparty6',
            '.camaraparty7',
            '.camaraparty8',
            '.camaraparty9',
            '.camaraparty10',
            '.camaraparty11',
            '.camaraparty12',
            '.camaraparty13',
            '.camaraparty14',
            '.camaraparty15',
            '.camaraparty16',
            '.camaraparty17',
            '.camaraparty18',
            '.camaraparty19',
            '.camaraparty20',
            '.camaraparty21',
            '.camaraparty22',
            '.camaraparty23',
            '.camaraparty24',
            '.camaraparty25',
            '.camaraparty26',
            '.camaraparty27',
            '.camaraparty28',
            '.camaraparty29',
        ];
    
        const camara_buttons = [
            '.camara1',
            '.camara2',
            '.camara3',
            '.camara4',
            '.camara5',
            '.camara6',
            '.camara7',
            '.camara8',
            '.camara9',
            '.camara10',
            '.camara11',
            '.camara12',
            '.camara13',
            '.camara14',
            '.camara15',
            '.camara16',
            '.camara17',
            '.camara18',
            '.camara19',
            '.camara20',
            '.camara21',
            '.camara22',
            '.camara23',
            '.camara24',
            '.camara25',
            '.camara26',
            '.camara27',
            '.camara28',
            '.camara29',
        ];
        
    
        camara_buttons.forEach((item, index) => {
            $(item).on("mouseenter", function () {
                onMouseEnter(camara_parties[index], 'camara', index);
            });
    
            $(item).on("mouseleave", function () {
                resetCamara();
                $('.totalcamara').text('188');
            });
        });

        camara_parties.forEach((item, index) => {
            $(item).on("mouseenter", function () {
                $(camara_buttons[index]).css('font-weight', 'bold')
                onMouseEnter(camara_parties[index], 'camara', index);
            });
    
            $(item).on("mouseleave", function () {
                resetCamara();
                $(camara_buttons[index]).css('font-weight', 'normal')
                $('.totalcamara').text('188');
            });
        });

        senado_parties.forEach((item, index) => {
            $(item).on("mouseenter", function () {
                $(senado_buttons[index]).css('font-weight', 'bold')
                onMouseEnter(senado_parties[index], 'senado', index);
            });

            $(item).on("mouseleave", function () {
                resetSenado();
                $(senado_buttons[index]).css('font-weight', 'normal')
                $('.totalsenado').text('108');
            });
        });
    
        senado_buttons.forEach((item, index) => {
            $(item).on("mouseenter", function () {
                onMouseEnter(senado_parties[index], 'senado', index);
            });
        });
    
        senado_buttons.forEach(item => {
            $(item).on("mouseleave", function () {
                resetSenado();
                $('.totalsenado').text('108');
            });
        });
    
        function onMouseEnter(target, chart, index) {
            if (chart === 'senado') {
                senado_parties.forEach(item => {
                    if (item != target) {
                        $(item).css('fill', "#f6f6f6");
                        $('.totalsenado').text(senadoCount[index]);
                    }
                })
            } else {
                camara_parties.forEach(item => {
                    if (item != target) {
                        $(item).css('fill', "#f6f6f6");
                        $('.totalcamara').text(camaraCount[index]);
                    }
                })
            }
        }
    
        function resetSenado() {
            $(".party1").css('fill', "#993A87");
            $(".party2").css('fill', "#2F3FC9");
            $(".party3").css('fill', "#F30017");
            $(".party4").css('fill', "#C973FF");
            $(".party5").css('fill', "#3F95F8");
            $(".party6").css('fill', "#7E087C");
            $(".party7").css('fill', "#F6A136");
            $(".party8").css('fill', "#7A0000");
            $(".party9").css('fill', "#0C2355");
            $(".party10").css('fill', "#CB5D5F");
            $(".party11").css('fill', "#BBE77B");
            $(".party12").css('fill', "#1A3E58");
        }
    
        function resetCamara() {
            $(".camaraparty1").css('fill', "#F30017");
            $(".camaraparty2").css('fill', "#993A87");
            $(".camaraparty3").css('fill', "#2F3FC9");
            $(".camaraparty4").css('fill', "#7E087C");
            $(".camaraparty5").css('fill', "#D9D9D9");
            $(".camaraparty6").css('fill', "#3F95F8");
            $(".camaraparty7").css('fill', "#F6A136");
            $(".camaraparty8").css('fill', "#268247");
            $(".camaraparty9").css('fill', "#7A0000");
            $(".camaraparty10").css('fill', "#FAD700");
            $(".camaraparty11").css('fill', "#C973FF");
            $(".camaraparty12").css('fill', "#BBE77B");
            $(".camaraparty13").css('fill', "#89A900");
            $(".camaraparty14").css('fill', "#120881");
            $(".camaraparty15").css('fill', "#A20000");
            $(".camaraparty16").css('fill', "#A34200");
            $(".camaraparty17").css('fill', "#698ED4");
            $(".camaraparty18").css('fill', "#43A089");
            $(".camaraparty19").css('fill', "#0C2355");
            $(".camaraparty20").css('fill', "#D2083C");
            $(".camaraparty21").css('fill', "#E17405");
            $(".camaraparty22").css('fill', "#F8CC2C");
            $(".camaraparty23").css('fill', "#6ED35C");
            $(".camaraparty24").css('fill', "#CFBB5D");
            $(".camaraparty25").css('fill', "#63D2CC");
            $(".camaraparty26").css('fill', "#30697B");
            $(".camaraparty27").css('fill', "#8EBB40");
            $(".camaraparty28").css('fill', "#CB5D5F");
            $(".camaraparty29").css('fill', "#8FC7E0");
        }
    }

    const onTabChanged = (target) => {
        setOrigin(target)
    }

    const handleViewTool = (e) => {
        e.preventDefault();
        setShowInfo(false);
    }

    if (loading) {
        return <Loader/>;
    }
 
    return <>
        <div className="hero">
            {/* <div id="carousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {banners.map((banner, index) => (
                        <button type="button" key={banner.id} data-bs-target="#carousel" data-bs-slide-to={index} className={`${index == 0 ? 'active' : ''}`} aria-current="true" aria-label={`Slide ${index + 1}`}></button>
                    ))}
                </div>
                <div className="carousel-inner">
                    {banners.map((banner, index) => (
                        <div className={`carousel-item h-100 ${index == 0 ? 'active' : ''}`} key={banner.id}>
                            <img className="d-block w-100 h-100" src={`${baseUrl}/banners/${banner.image}`} alt="First slide" style={{objectFit: 'cover'}} />
                            <div className="overlay">
                                <div className="caption">
                                    <p>{banner.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}
            {/* <img src="./assets/images/hero-vector.png" className="hero-vector" /> */}
            <img src="./assets/images/circle.png" className="hero-circle" />
            <img src="./assets/images/circle2.png" className="hero-circle2" />
            <img src="./assets/images/circle3.png" className="hero-circle3" />
            <img src="./assets/images/congress.svg" className="hero-circle4" />

            <div className="container h-100" style={{ position: 'relative' }}>
                <div className="row h-100">
                    <div className="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center flex-column">
                        <h2 className="hero-title">
                            <span className="hero-primary-text">IALE</span> es un <span className="hero-primary-text">indicador que mide la afinidad</span> de proyectos de ley en curso, leyes aprobadas y congresistas, con la <span className="hero-primary-text">libertad económica</span>.
                        </h2>

                        <p className="hero-content">
                            Con esta herramienta podrás ejercer control ciudadano al desempeño de los congresistas sobre el impacto que tienen sus decisiones en la economía.
                        </p>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
                        <a href="#seating" className="hero-btn-1 me-4">
                            Congresistas
                        </a>

                        <a href="./projects" className="hero-btn-2">
                            Normatividad
                        </a>
                    </div>
                </div>
            </div>
        </div>

        {/* Analisis legislativos Section */}
        <div className="analisis">
            <div className="text-center">
                <h2 className="analisis-title">Análisis legislativos</h2>
            </div>

            <div className="thumbnails">
                <Carousel projects={projects}/>
            </div>

            <div className="analisis-footer text-center">
                <div className="text-center mb-4 px-3">
                    <Link className="btn btn-primary ver-mas" style={{ fontSize: 16, }} to={`./projects`}>ver más</Link>
                </div>
            </div>
        </div>

        {/* Seating Section */}
        <div className="seating" id="seating">
            {/* { showInfo ? (
                <div className="container info-panel">
                    <div className="info">
                        <br/>

                        <p>
                            El <b>Índice de Afinidad con la Libertad Económica (IALE)</b> es una herramienta innovadora que diseñamos en el Instituto de Ciencia Política Hernán Echavarría Olózaga, con el apoyo de Atlas Network. 
                        </p>

                        <p>
                            Con este instrumento podrás conocer:
                        </p>

                        <p>
                            <b>El nivel de afinidad del comportamiento de los congresistas con los principios de la libertad económica:</b> si fueron autores o ponentes de alguna iniciativa legislativa y cómo votaron en plenaria de Cámara y/o Senado en proyectos que podrían favorecer o atentar la libertad económica del país. 
                        </p>

                        <p>
                            <b>El impacto de los proyectos de ley aprobados en la economía colombiana</b> a través de indicadores como inflación, desempleo, crecimiento de la producción, déficit fiscal, inversión, comercio nacional e internacional, deuda externa, entre otros. 
                        </p>

                        <div className="text-center my-4 px-3 d-flex justify-content-center">
                            <Link className="btn btn-primary ver-mas" to={'#'} onClick={(e) => {
                                handleViewTool(e);
                            }}>Congresistas</Link>
                        </div>
                    </div>
                </div>
            ) : ( */}
                <div className="container">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" onClick={() => onTabChanged('senado')} id="senado-tab" data-bs-toggle="tab" data-bs-target="#senado-tab-pane" type="button" role="tab" aria-controls="senado-tab-pane" aria-selected="true">SENADO</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" onClick={() => onTabChanged('camara')} id="camara-tab" data-bs-toggle="tab" data-bs-target="#camara-tab-pane" type="button" role="tab" aria-controls="camara-tab-pane" aria-selected="false">CÁMARA</button>
                        </li>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                        {/* Senado */}
                        <div className="tab-pane fade show active" id="senado-tab-pane" role="tabpanel" aria-labelledby="senado-tab" tabIndex="0">
                            <div className="row">
                                <div className="col-md-3 mb-0">
                                    <div className="card">
                                        <div className="card-body overflow-auto">
                                            <ul className="parties">
                                                <li className="senado1">
                                                    <img src="./assets/images/parties/senado/1.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/1`} className="no-text-decoration">{ senadoParties[0].name }</Link>
                                                </li>
                                                <li className="senado2">
                                                    <img src="./assets/images/parties/senado/2.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/2`} className="no-text-decoration">{ senadoParties[1].name }</Link>
                                                </li>
                                                <li className="senado3">
                                                    <img src="./assets/images/parties/senado/3.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/3`} className="no-text-decoration">{ senadoParties[2].name }</Link>
                                                </li>
                                                <li className="senado4">
                                                    <img src="./assets/images/parties/senado/4.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/4`} className="no-text-decoration">{ senadoParties[3].name }</Link>
                                                </li>
                                                <li className="senado5">
                                                    <img src="./assets/images/parties/senado/5.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/5`} className="no-text-decoration">{ senadoParties[4].name }</Link>
                                                </li>
                                                <li className="senado6">
                                                    <img src="./assets/images/parties/senado/6.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/6`} className="no-text-decoration">{ senadoParties[5].name }</Link>
                                                </li>
                                            </ul>
                                            <ul className="parties d-none">
                                                <li className="senado7">
                                                    <img src="./assets/images/parties/senado/7.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/7`} className="no-text-decoration">{ senadoParties[6].name }</Link>
                                                </li>
                                                <li className="senado8">
                                                    <img src="./assets/images/parties/senado/8.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/8`} className="no-text-decoration">{ senadoParties[7].name }</Link>
                                                </li>
                                                <li className="senado9">
                                                    <img src="./assets/images/parties/senado/9.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/9`} className="no-text-decoration">{ senadoParties[8].name }</Link>
                                                </li>
                                                <li className="align-items-start senado10">
                                                    <img src="./assets/images/parties/senado/10.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/10`} className="no-text-decoration">{ senadoParties[9].name }</Link>
                                                </li>
                                                <li className="align-items-start senado11">
                                                    <img src="./assets/images/parties/senado/11.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/11`} className="no-text-decoration">{ senadoParties[10].name }</Link>
                                                </li>
                                                <li className="align-items-start senado12">
                                                    <img src="./assets/images/parties/senado/12.png" alt="Party Badge" />
                                                    <Link to={`/parties/senado/12`} className="no-text-decoration">{ senadoParties[11].name }</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-0">
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-center align-items-center p-0">
                                            <svg width="100%" height="291" viewBox="15 0 600 288" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_1720_1045)">
                                                    <path d="M392 293C392 272.048 383.729 251.954 369.008 237.139C354.286 222.323 334.319 214 313.5 214C292.681 214 272.714 222.323 257.992 237.139C243.271 251.954 235 272.048 235 293L313.5 293L392 293Z" fill="#F2F2F2"/>

                                                    <g clipPath="url(#clip0_1720_1045)">
                                                    <path d="M69.3689 251.851C68.9971 254.267 67.0984 269.578 66.0505 269.575C65.5998 269.575 45.3511 268.36 42.9341 268.051C43.0838 265.633 43.381 263.225 43.8243 260.843C44.4327 257.577 44.8666 254.264 45.7455 251.071C46.0469 249.973 46.1596 247.566 48.3568 248.24C48.6611 248.271 57.5037 249.845 58.9122 250.09C62.394 250.706 65.8843 251.263 69.3689 251.851Z" fill="#993A87" className="party1"/>
                                                    <path d="M41.5 288.227C41.5 282.838 42.024 275.872 42.9339 271.718C50.7821 271.662 58.6331 272.434 66.4813 272.381L66.2897 274.978C65.7489 279.374 66.4418 288.784 64.2671 288.75C58.0866 288.658 47.6974 288.218 41.5 288.227Z" fill="#993A87" className="party1"/>
                                                    <path d="M202.14 74.0538C201.577 74.6696 201.092 75.4609 200.391 75.8761C194.757 79.2196 189.058 82.4295 183.435 85.7758C181.996 86.6312 180.776 87.1383 179.52 85.6365C175.829 79.9775 171.756 73.399 168.066 67.7401C167.364 66.5308 183.038 57.4976 189.007 53.9924C190.534 53.098 191.754 52.7888 192.808 54.5079C195.918 61.0223 199.029 67.5376 202.14 74.0538Z" fill="#F30017" className="party3"/>
                                                    <path d="M90.1358 144.461C90.0734 144.421 90.0132 144.377 89.9555 144.331C88.5132 143.199 88.9301 142.411 89.4231 141.723C93.9303 135.462 98.5671 129.304 103.252 123.177C103.402 122.989 103.585 122.829 103.793 122.706C104.252 122.428 104.792 122.308 105.327 122.364C105.863 122.42 106.365 122.649 106.756 123.015L120.34 135.554C120.784 135.962 121.061 136.516 121.12 137.113C121.179 137.709 121.014 138.306 120.658 138.791C119.329 140.583 117.999 142.375 116.641 144.147C114.089 147.49 111.511 150.795 108.939 154.113C108.561 154.601 108.012 154.931 107.4 155.037C106.788 155.142 106.158 155.017 105.635 154.684C101.812 152.263 93.843 146.872 90.1358 144.461Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M223.834 64.0517L204.188 73.8483C203.258 72.3465 202.216 70.9004 201.425 69.3289C198.909 64.3359 196.489 59.2982 194.033 54.2829C194.376 53.1238 194.374 51.2765 195.117 50.9142C201.718 47.6821 208.447 44.7008 215.158 41.6944C215.498 41.5411 216.034 41.8142 216.479 41.8894L217.8 45.233C218.634 47.397 219.467 49.562 220.299 51.7278L222.107 56.2612C222.682 58.8599 223.257 61.4567 223.834 64.0517Z" fill="#F30017" className="party3"/>
                                                    <path d="M107.835 119.299C110.892 115.905 118.76 107.629 122.013 104.102C122.195 103.905 122.415 103.746 122.66 103.635C122.905 103.525 123.171 103.464 123.44 103.459C123.71 103.453 123.978 103.501 124.228 103.6C124.478 103.7 124.705 103.849 124.895 104.038C128.968 108.095 133.69 112.728 137.732 116.755C138.06 117.081 138.262 117.51 138.303 117.969C138.344 118.427 138.22 118.885 137.955 119.262C137.391 120.07 136.893 120.837 136.245 121.631C133.278 125.253 129.121 129.237 125.602 132.951C124.88 133.715 123.884 134.17 122.827 134.216C121.77 134.263 120.737 133.899 119.948 133.202C116.548 130.187 111.328 125.178 107.956 122.037C107.58 121.686 107.357 121.204 107.334 120.692C107.311 120.181 107.491 119.681 107.835 119.299Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M74.617 228.633C73.1296 235.467 71.6404 242.3 70.1492 249.132L69.2816 250.026C69.2816 250.026 46.6497 245.866 46.7145 245.602C48.4639 238.419 50.2724 231.252 52.0668 224.083C56.6417 224.927 72.5437 228.037 74.617 228.633Z" fill="#993A87" className="party1"/>
                                                    <path d="M52.9907 222.202C53.3767 219.906 57.6247 205.598 59.3628 200.747C61.1685 201.026 76.2114 205.241 81.9299 207.08L75.586 226.643L68.5125 225.751L52.9907 222.202Z" fill="#993A87" className="party1"/>
                                                    <path d="M189.374 101.745C189.723 100.477 189.655 98.5629 190.5 98.0474C195.943 94.6537 201.566 91.5386 207.18 88.4207C207.673 88.1421 208.538 88.5183 209.231 88.5907C212.025 94.52 214.824 100.448 217.628 106.376C217.977 107.85 218.163 109.076 216.369 110.031C211.634 112.553 207.05 115.356 202.323 117.889C201.619 118.268 200.456 117.805 199.506 117.733L191.929 105.453C191.7 105.158 191.475 104.863 191.25 104.565L190.433 103.492L189.374 101.745Z" fill="#F30017" className="party3"/>
                                                    <path d="M519.748 146.609C518.88 146.125 512.666 137.376 509.93 133.832C508.299 131.717 508.24 130.045 510.581 128.234C515.44 124.472 520.159 120.524 524.849 116.562C526.08 115.525 526.925 114.999 528.111 116.579C531.43 120.998 538.782 131.093 539.734 132.098C533.723 136.378 525.745 142.319 519.748 146.609Z" fill="#F6A136" className="party7"/>
                                                    <path d="M463.227 60.4796C464.148 60.4294 465.34 59.9836 465.945 60.3904C470.881 63.7145 475.751 67.1388 480.501 70.6997C481.036 71.1038 481.146 72.8814 480.715 73.578C477.334 79.0419 473.74 84.3721 470.312 89.8054C469.123 91.689 468.016 91.0871 466.621 90.1621C462.114 87.1696 457.579 84.2384 453.052 81.2849C453.745 79.6549 454.35 77.9803 455.142 76.3977C457.801 71.0815 460.528 65.7847 463.227 60.4796Z" fill="#7E087C" className="party6"/>
                                                    <path d="M489.028 109.831C485.309 106.364 480.475 102.165 476.633 98.8219C474.855 97.2672 474.875 95.9604 476.244 94.2747C480.12 89.4989 483.996 84.7232 487.794 79.875C489.202 78.0723 490.4 78.3677 491.907 79.6744C495.997 83.2214 500.856 87.2978 504.98 90.8196C504.42 91.7168 490.121 108.513 489.028 109.831Z" fill="#F6A136" className="party7"/>
                                                    <path d="M103.897 156.565C104.35 156.872 104.669 157.338 104.789 157.868C104.909 158.398 104.822 158.954 104.545 159.423C101.728 164.23 98.911 169.047 95.939 173.762C95.6038 174.291 93.7333 174.455 92.9699 174.04C87.5133 171.187 82.1328 168.189 76.3664 165.013C76.1042 164.869 75.8748 164.674 75.6927 164.438C75.5105 164.203 75.3794 163.933 75.3078 163.645C75.2361 163.357 75.2253 163.058 75.2761 162.766C75.3269 162.474 75.4382 162.195 75.603 161.948C79.1214 156.626 82.3215 151.747 85.6484 146.952C85.9611 146.503 86.7189 146.358 87.4626 146.2C87.7523 146.138 88.0517 146.135 88.3424 146.192C88.633 146.249 88.9089 146.365 89.1528 146.531L103.897 156.565Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M210.419 88.3979C210.893 87.576 211.157 86.3472 211.87 85.9961C217.504 83.2098 223.228 80.5824 228.958 77.9772C229.322 77.8128 229.984 78.2753 230.505 78.4453C230.722 78.8827 230.936 79.3229 231.153 79.7604C231.251 80.2591 231.353 80.7607 231.451 81.2594L232.096 82.4269C232.806 84.3634 233.615 86.272 234.212 88.2419C235.392 92.1427 236.466 96.0937 237.57 100.022C232.761 102.179 227.907 104.249 223.161 106.534C221.471 107.342 220.13 107.855 218.808 106.163C216.012 100.243 213.216 94.3216 210.419 88.3979Z" fill="#F30017" className="party3"/>
                                                    <path d="M565 174.798C564.574 175.094 551.887 181.084 546.185 183.84C544.12 184.835 543.086 184.754 542.069 182.447C540.272 178.345 537.973 174.461 536.083 170.393C535.731 169.641 535.97 167.916 536.534 167.562C542.346 163.892 548.241 160.354 554.219 156.949C554.816 156.609 556.554 156.986 556.819 157.507C559.614 162.951 562.219 168.49 564.87 174.004C564.935 174.265 564.978 174.531 565 174.798Z" fill="#7A0000" className="party8"/>
                                                    <path d="M433.304 69.5628C430.831 68.7046 420.834 63.9623 418.157 62.7921C416.047 61.8726 415.484 60.9058 416.422 58.7492C418.794 53.2991 421.048 47.8027 423.183 42.2598C424.104 39.8719 425.175 39.4317 427.535 40.627C431.811 42.7976 436.285 44.578 440.612 46.6538C441.584 47.1191 442.302 48.125 443.122 48.8829L433.304 69.5628Z" fill="#7E087C" className="party6"/>
                                                    <path d="M358.6 22.7861C360.594 22.8056 373.423 24.6112 377.846 25.4944C377.53 28.5064 374.387 44.614 373.133 49.6572L356.924 47.0019L355.082 46.166C355.724 41.407 358.068 25.8288 358.6 22.7861Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M120.264 162.954C123.793 158.599 127.233 154.169 130.918 149.945C131.813 148.914 132.399 147.794 133.735 147.128C138.538 151.307 144.439 156.239 149.245 160.413C145.321 165.866 141.414 171.33 137.43 176.741C137.171 177.095 136.335 177.033 135.766 177.167C131.458 174.085 127.241 170.87 122.807 167.989C120.768 166.677 119.813 165.267 120.264 162.954Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M506.136 92.9619C508.319 94.7284 510.643 96.3473 512.649 98.2977C514.871 100.474 516.671 103.071 518.888 105.263C520.578 106.913 520.488 108.03 518.86 109.652C513.973 114.511 509.223 119.507 503.969 124.912L490.752 110.953L506.136 92.9619Z" fill="#F6A136" className="party7"/>
                                                    <path d="M290.361 46.1492C285.93 46.7288 273.811 48.0997 272.109 48.2557C271.828 46.152 271.546 42.9617 271.264 40.8441C270.656 35.4238 270.049 30.0035 269.444 24.5833C275.774 23.5523 282.386 22.5242 289.254 21.5518C289.31 21.5518 289.535 26.0433 289.592 27.6538C289.834 33.8199 290.104 39.9832 290.361 46.1492Z" fill="#C973FF" className="party4"/>
                                                    <path d="M540.357 134.271C541.436 134.868 547.171 144.742 549.892 148.674C551.146 150.488 550.861 151.491 549.047 152.597C543.658 155.874 538.275 159.173 533.024 162.658C531.3 163.804 530.489 163.547 529.486 161.937C526.669 157.37 523.748 152.862 520.869 148.328C526.872 143.84 534.362 138.763 540.357 134.271Z" fill="#7A0000" className="party8"/>
                                                    <path d="M60.416 198.936C62.5541 193.411 64.6415 186.785 66.8951 181.304C67.0754 180.87 68.4417 180.496 69.0389 180.705C75.2983 182.895 81.5324 185.163 87.7101 187.559C88.3383 187.802 89.096 189.293 88.8735 189.839C86.7579 195.046 84.4423 200.176 82.1831 205.331C76.7265 203.759 62.095 199.546 60.416 198.936Z" fill="#993A87" className="party1"/>
                                                    <path d="M89.7637 229.953C89.4546 229.878 89.1882 229.685 89.0224 229.416C88.8567 229.147 88.8052 228.824 88.8792 228.518C89.0256 227.914 89.1355 227.462 89.3665 226.74C90.7947 222.263 92.3328 217.824 94.0428 213.43C94.3245 212.692 94.6259 211.694 95.2428 211.232C95.5245 211.009 96.7245 211.374 97.0767 211.483L113.646 216.498C115.886 217.15 117.1 216.891 115.7 220.399C114.252 224.857 112.815 229.29 111.283 233.703C111.125 234.152 111.049 234.314 110.95 234.539C110.837 234.805 110.63 235.021 110.368 235.147C110.106 235.273 109.806 235.301 109.525 235.225C105.784 234.233 93.7751 230.923 89.7637 229.953Z" fill="#993A87" className="party1"/>
                                                    <path d="M451.449 79.3899C450.074 78.958 435.831 70.6437 435.028 69.5933L445.217 48.9746C449.443 51.304 460.827 58.3366 462.277 59.5263C461.714 60.1644 453.925 74.408 451.449 79.3899Z" fill="#7E087C" className="party6"/>
                                                    <path d="M164.007 144.682C159.64 149.054 154.803 154.991 150.364 159.307L134.87 145.846C139.377 140.761 144.448 135.139 148.955 130.054C150 130.338 164.381 144.306 164.007 144.682Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M380.46 26.3838C386.207 27.6293 392.667 29.2147 398.413 30.4602C398.098 32.0177 397.568 33.3858 397.182 34.9489C395.698 41.1512 392.45 54.01 392.45 54.01C387.379 52.6781 380.841 51.2961 375.795 49.9002L377.874 38.2952L380.46 26.3838Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M310.443 43.5001C309.809 44.035 309.243 44.687 308.587 44.7149C303.401 44.9239 298.279 44.9935 292.972 45.3585C292.73 43.5447 291.423 21.5774 291.623 21.5384C297.64 20.3765 304.468 20.1258 309.753 20.9171C309.984 28.4457 310.214 35.9733 310.443 43.5001Z" fill="#C973FF" className="party4"/>
                                                    <path d="M83.8512 251.904C83.654 251.113 83.1356 250.232 83.3103 249.538C84.6737 244.049 86.5808 237.189 88.0372 231.723C91.6684 232.525 107.539 236.875 109.418 237.426L104.804 255.47C103.596 255.164 89.626 252.795 83.8512 251.904Z" fill="#993A87" className="party1"/>
                                                    <path d="M445.178 93.8369C447.914 95.3164 450.739 96.6539 453.348 98.3201C455.319 99.5767 456.872 101.463 458.855 102.689C460.951 103.987 460.914 105.116 459.63 106.98C456.413 111.65 453.301 116.391 450.294 121.204C449.018 123.252 447.947 123.868 445.742 122.249C442.606 119.947 439.17 118.047 435.95 115.854C435.189 115.336 434.769 114.336 434.192 113.556L445.178 93.8369Z" fill="#7E087C" className="party6"/>
                                                    <path d="M366.251 87.5288C361.271 86.835 355.803 85.8821 350.842 85.0935C350.259 85.0016 350.022 84.4555 349.507 83.9595C349.563 83.4023 352.651 61.8223 353.008 60.666C358.727 61.8307 365.502 63.079 371.223 64.2408C370.927 65.177 366.544 86.626 366.251 87.5288Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M479.047 120.497C478.633 121.193 478.343 121.89 477.878 122.447C473.83 127.228 469.709 131.94 465.703 136.766C464.171 138.619 462.869 138.544 461.258 136.975C458.368 134.164 455.314 131.503 452.581 128.555C451.993 127.919 451.979 125.969 452.531 125.228C456.193 120.299 460.075 115.52 463.926 110.725C464.771 109.669 465.709 108.538 467.269 109.889C470.788 112.954 474.343 115.969 477.861 119.028C478.295 119.402 478.594 119.928 479.047 120.497Z" fill="#F6A136" className="party7"/>
                                                    <path d="M251.889 134.857C248.06 136.529 237.744 141.644 236.516 141.758C235.235 139.114 230.984 130.335 230.392 128.827L227.73 123.388C227.152 122.232 226.451 120.992 228.319 120.167C233.051 118.083 237.649 115.695 242.404 113.625C242.832 113.438 243.632 114.085 244.257 114.344C246.128 119.231 251.461 132.547 251.889 134.857Z" fill="#F30017" className="party3"/>
                                                    <path d="M489.124 169.162C485.842 164.873 482.56 160.683 479.456 156.367C479.16 155.957 479.738 154.469 480.335 153.968C485.383 149.708 490.563 145.609 495.62 141.351C496.975 140.215 497.699 140.747 498.53 141.833C501.347 145.523 504.254 149.148 506.927 152.934C507.35 153.533 507.105 155.258 506.536 155.754C504.623 157.426 502.372 158.705 500.338 160.262C496.597 163.151 492.921 166.136 489.124 169.162Z" fill="#F6A136" className="party7"/>
                                                    <path d="M427.727 84.7422C428.817 84.9066 430.059 84.7979 430.978 85.2827C435.102 87.4533 439.147 89.791 443.198 92.0702L432.276 113.466C428.102 111.279 423.893 109.145 419.775 106.854C419.014 106.428 418.62 105.364 418.056 104.592C421.279 97.979 424.502 91.3624 427.727 84.7422Z" fill="#7E087C" className="party6"/>
                                                    <path d="M416.726 104.425C411.918 102.219 407.081 100.068 402.36 97.7131C401.895 97.4818 401.723 95.8546 402.013 95.1051C404.292 89.2539 406.734 83.4751 409.089 77.6601C409.681 76.1917 410.34 75.4505 412.106 76.3115C416.05 78.2285 420.112 79.8612 423.994 81.8618C424.935 82.3466 425.36 83.8122 426.025 84.8097L416.726 104.425Z" fill="#7E087C" className="party6"/>
                                                    <path d="M234.809 141.413C234.747 141.987 234.885 142.926 234.595 143.085C229.905 145.562 225.161 147.938 220.431 150.329L216.803 144.868C216.459 144.311 216.113 143.767 215.769 143.218L209.755 134.196C208.91 131.931 209.442 130.295 211.727 129.1C215.293 127.233 218.828 125.277 222.206 123.101C224.502 121.619 225.626 122.485 226.595 124.478L229.308 129.95C229.922 131.449 230.535 132.949 231.147 134.45L234.809 141.413Z" fill="#F30017" className="party3"/>
                                                    <path d="M292.947 84.6787C288.828 85.1747 284.716 85.7375 280.586 86.1192C279.38 86.2279 278.127 85.8127 276.896 85.6372L275.087 72.0623L274.126 63.982C274.211 63.8972 274.264 63.7866 274.277 63.6682C274.29 63.5498 274.261 63.4306 274.197 63.33C279.741 62.3799 285.279 61.4047 290.834 60.5298C291.214 60.4713 291.719 61.1762 292.167 61.5273L293.029 73.3134C293.096 74.4279 293.165 75.5498 293.234 76.6792L292.947 84.6787Z" fill="#C973FF" className="party4"/>
                                                    <path d="M125.225 102.213C124.891 101.874 124.705 101.421 124.705 100.948C124.705 100.476 124.891 100.022 125.225 99.6831C125.836 99.0645 126.239 98.6772 126.836 98.0447C129.399 95.3309 131.997 92.7926 134.667 90.1734C136.357 88.5017 137.665 88.7329 139.09 90.4939C143.034 95.3532 147.065 100.134 150.986 105.008C151.454 105.584 151.121 106.543 150.859 106.763C147.347 109.725 144.493 113.043 140.896 115.908C140.539 116.193 140.086 116.336 139.628 116.308C139.17 116.281 138.739 116.084 138.42 115.757C134.205 111.416 129.456 106.56 125.225 102.213Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M124.836 239.305C125.729 233.152 126.661 227.022 131.081 222.107C136.405 223.751 141.786 225.25 147.02 227.123C148.674 227.713 150.057 228.254 151.893 229.073C150.296 233.517 148.231 239.33 146.626 243.777C141.546 242.857 126.349 239.636 124.836 239.305Z" fill="#993A87" className="party1"/>
                                                    <path d="M270.216 48.7882C266.363 49.5516 262.464 50.1396 258.667 51.1454C256.396 51.75 255.326 51.4993 254.912 49.0334C253.819 42.5636 252.599 36.1161 251.34 29.6769C250.993 27.9076 251.788 27.1414 253.331 26.8906C258.078 26.1272 262.833 25.4195 267.588 24.6895C268.484 31.3264 269.381 37.9634 270.278 44.6004L270.216 48.7882Z" fill="#C973FF" className="party4"/>
                                                    <path d="M163.083 171.017C167.364 164.86 170.81 161.159 175.694 156.779C180.202 161.609 184.709 166.43 189.216 171.243C189.312 171.828 189.712 172.686 189.461 172.954C186.013 176.609 182.492 180.212 178.934 183.762C178.748 183.949 178.075 183.662 177.627 183.595L166.857 174.531L163.083 171.017Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M294.073 60.5403L310.505 59.6152C310.743 66.0014 310.984 72.3877 311.226 78.7739C311.239 80.1614 311.254 81.5499 311.271 82.9394L311.235 82.8948L309.418 83.7725C305.97 83.8812 302.522 84.1068 299.079 84.0511C297.744 84.026 296.423 83.3378 295.096 82.9366C294.794 78.4098 294.493 73.8811 294.195 69.3506C294.144 68.5333 294.092 67.7169 294.04 66.9014L294.073 60.5403Z" fill="#C973FF" className="party4"/>
                                                    <path d="M104.792 257.265C103.933 262.294 103.846 262.81 102.99 267.853C102.843 267.95 102.401 272.238 102.283 272.364C100.874 272.28 83.8451 271.104 80.2056 270.798C80.6197 266.418 82.4366 254.98 82.9324 253.643C87.8171 254.225 103.584 256.981 104.792 257.265Z" fill="#993A87" className="party1"/>
                                                    <path d="M102.573 273.913C102.359 278.354 102.204 281.57 101.992 286.011C101.942 286.3 101.919 286.593 101.925 286.886C100.66 287.488 99.412 288.558 98.1274 288.605C92.5497 288.795 86.9607 288.605 81.3802 288.722C79.0139 288.772 78.0646 287.923 78.2815 285.604C78.6646 281.33 78.9661 277.05 79.3013 272.787C79.8506 272.631 101.161 273.832 102.573 273.913Z" fill="#993A87" className="party1"/>
                                                    <path d="M373.313 64.2412C374.784 64.5505 388.165 68.3454 388.77 69.0253C386.863 76.1527 384.959 84.6676 383.052 91.795L368.708 88.3957C369.592 82.706 372.663 66.604 373.313 64.2412Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M96.5727 209.717C96.3726 209.199 95.874 208.572 96.0093 208.182C97.5304 204.158 99.1812 200.185 100.739 196.176C101.674 193.774 103.01 193.052 105.57 194.259C110.801 196.722 116.128 198.995 121.467 201.224C123.25 201.968 123.14 202.932 122.481 204.362C120.734 208.14 119.075 211.954 117.379 215.752C116.534 215.783 112.787 214.585 111.675 214.239C106.666 212.67 101.612 211.213 96.5727 209.717Z" fill="#993A87" className="party1"/>
                                                    <path d="M529.523 190.982C524.351 193.668 517.857 197.819 512.654 200.455C509.15 202.23 509.144 202.049 507.558 198.505C505.8 194.565 503.769 190.742 501.727 186.607C508.085 183.108 514.685 179.641 520.883 176.2C521.618 176.145 529.593 190.611 529.523 190.982Z" fill="#7A0000" className="party8"/>
                                                    <path d="M119.413 257.214C120.624 252.154 121.835 247.093 123.044 242.032C123.326 241.711 123.594 241.394 123.87 241.073L123.895 241.054L129.391 241.912L145.761 245.601L141.349 260.145L125.729 258.162L119.413 257.214Z" fill="#993A87" className="party1"/>
                                                    <path d="M176.514 184.67C176.638 185.25 177.077 186.091 176.838 186.373C173.522 190.273 170.113 194.093 166.73 197.933L150.462 187.038C150.431 186.464 150.135 185.665 150.411 185.344C154.22 180.909 158.107 176.537 161.975 172.148L165.753 175.556L176.514 184.67Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M217.758 41.6916C218.321 41.0647 218.761 40.1535 219.465 39.861C223.071 38.362 226.764 37.0747 230.386 35.6035C232.246 34.8484 233.178 35.5422 233.739 37.2307C235.792 43.433 237.888 49.6242 239.857 55.8516C240.029 56.4088 239.556 57.6571 239.102 57.8215C234.764 59.3874 230.37 60.7889 225.981 62.2378L222.882 54.3581L221.152 49.836L218.724 44.1603L217.758 41.6916Z" fill="#F30017" className="party3"/>
                                                    <path d="M366.408 103C366.707 103.301 367.003 103.605 367.301 103.909L364.681 116.466C364.568 116.704 364.53 116.971 364.574 117.23C363.941 120.202 363.309 123.181 362.676 126.166C357.686 126.666 352.652 125.819 348.109 123.716C348.478 121.827 348.898 119.947 349.208 118.049C350.213 111.919 351.179 105.753 352.157 99.6038L353.54 99.4756L366.408 103Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M353.062 46.0211C349.177 45.7425 345.273 45.606 341.425 45.0626C340.619 44.9512 339.388 43.3073 339.453 42.4324C339.839 35.904 340.605 29.3952 341.013 22.8669C341.174 20.3286 342.422 19.9107 344.515 20.206C348.371 20.7633 352.721 21.5407 356.577 22.084C356.532 23.622 354.005 39.7603 353.062 46.0211Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M134.893 180.939C132.808 183.876 130.611 186.74 128.695 189.775C127.633 191.446 126.768 192.16 124.771 191.042C119.906 188.317 114.898 185.835 110.052 183.076C109.356 182.681 108.644 180.92 108.959 180.418C111.514 176.361 114.266 172.422 117.086 168.54C117.495 167.983 118.593 167.897 119.371 167.596C123.596 170.552 127.822 173.539 132.093 176.445C133.726 177.565 135.355 178.585 134.893 180.939Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M414.977 120.677C415.648 120.636 416.439 120.34 416.949 120.594C418.977 121.572 420.949 122.678 422.89 123.823C429.344 127.624 429.406 127.677 425.795 134.277C423.642 138.206 421.144 141.948 418.972 145.866C417.958 147.696 417.042 148.198 415.115 146.98C412.45 145.308 409.535 144.002 406.878 142.31C406.087 141.806 405.797 140.535 405.275 139.616L414.977 120.677Z" fill="#7E087C" className="party6"/>
                                                    <path d="M403.965 139.426C402.867 139.401 401.653 139.66 400.689 139.29C398.154 138.326 395.819 136.863 393.261 136.016C390.466 135.088 390.348 133.508 391.337 131.257C393.79 125.662 396.159 120.025 398.731 114.481C399.092 113.703 400.686 112.686 401.224 112.912C405.106 114.539 408.898 116.392 412.616 118.359C413.104 118.618 413.028 119.922 413.211 120.739L403.965 139.426Z" fill="#7E087C" className="party6"/>
                                                    <path d="M178.5 86.6286C179.523 88.0636 178.26 88.8298 177.655 89.2951C174.78 91.4725 171.796 93.5044 168.714 95.3832C168.17 95.7175 166.621 95.3832 166.221 94.8622C162.246 89.6128 158.361 84.2909 154.617 78.8799C154.198 78.2753 154.448 76.4586 155.003 76.0936C158.806 73.4884 162.773 71.1173 166.685 68.6709L178.5 86.6286Z" fill="#F30017" className="party3"/>
                                                    <path d="M467.973 164.439C470.573 167.861 473.092 171.302 475.754 174.626C477.269 176.521 477.317 177.786 475.095 179.271C470.233 182.517 465.455 185.905 460.821 189.46C458.849 190.976 457.874 190.689 456.595 188.747C455.219 186.612 453.637 184.613 451.871 182.779C449.947 180.812 450.006 179.46 452.229 177.764C455.922 174.947 459.457 171.912 463.015 168.934C464.683 167.546 466.235 166.03 467.973 164.439Z" fill="#F6A136" className="party7"/>
                                                    <path d="M436.885 164.776C434.648 163.021 432.513 161.115 430.144 159.557C428.026 158.164 427.761 156.933 429.392 154.96C433.114 150.457 436.75 145.888 440.299 141.251C441.882 139.184 443.282 139.181 445.105 140.87C447.3 142.909 449.522 144.941 451.903 146.76C453.858 148.253 454.218 149.474 452.379 151.371C448.105 155.774 443.978 160.318 439.792 164.807L438.744 166.537L437.879 166.454L436.885 164.776Z" fill="#F6A136" className="party7"/>
                                                    <path d="M468.841 205.846L489.315 193.213C491.515 197.93 493.507 202.243 495.448 206.587C496.183 208.228 495.006 208.518 494.4 208.816C488.707 211.602 482.966 214.322 477.157 216.871C476.447 217.183 474.74 216.729 474.374 216.119C472.385 212.812 470.675 209.321 468.841 205.846Z" fill="#7A0000" className="party8"/>
                                                    <path d="M490.752 264.865C490.152 261.502 489.54 257.654 488.89 254.303C488.431 251.951 488.583 250.46 491.673 250.026C497.324 249.237 502.916 247.997 508.499 246.793C510.53 246.353 511.598 246.663 511.857 248.892C512.257 252.285 512.775 255.668 513.378 259.028C513.806 261.405 512.637 262.266 510.513 262.519C504.834 263.205 491.681 264.941 490.752 264.865Z" fill="#0C2355" className="party9"/>
                                                    <path d="M371.802 104.658C375.923 105.528 380.039 106.411 384.163 107.252C385.853 107.595 386.445 108.467 385.994 110.139C384.332 116.33 382.613 122.51 381.12 128.737C380.611 130.847 379.881 131.368 377.723 130.652C374.306 129.52 370.762 128.757 367.272 127.837L368.033 121.616C368.148 121.395 368.197 121.147 368.173 120.9C369.385 115.485 370.594 110.071 371.802 104.658Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M302.657 121.979C298.214 122.296 293.189 123.026 288.749 123.344C288.385 120.372 288.021 117.4 287.656 114.428C287.359 109.635 287.062 104.842 286.763 100.048C286.983 99.9055 301.958 98.2449 302.141 98.3452C302.203 100.101 302.266 101.855 302.33 103.609C302.282 105.261 302.693 117.364 302.657 121.979Z" fill="#C973FF" className="party4"/>
                                                    <path d="M395.14 51.4659C396.881 44.9961 398.5 38.8273 400.21 32.3825C400.247 32.2439 400.312 32.1141 400.401 32.0012C400.49 31.8883 400.602 31.7946 400.729 31.726C400.856 31.6574 400.996 31.6152 401.141 31.6021C401.285 31.589 401.43 31.6052 401.568 31.6497C403.286 32.207 405.794 33.1181 406.98 33.4831C414.284 35.8877 414.304 35.8905 411.952 43.2352C410.597 47.4815 409.135 51.6944 407.873 55.9686C407.253 58.0723 406.534 59.0001 404.092 57.997C401.602 56.9772 398.258 56.0968 395.455 55.286C395.28 55.2352 395.117 55.1506 394.975 55.0371C394.833 54.9236 394.715 54.7834 394.628 54.6246C394.541 54.4658 394.487 54.2916 394.468 54.112C394.45 53.9323 394.467 53.7508 394.52 53.578C394.754 52.756 395.035 51.8895 395.14 51.4659Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M153.006 130.652C148.397 127.676 158.381 121.282 160.823 119.192C161.143 118.919 161.555 118.774 161.977 118.787C162.4 118.799 162.802 118.968 163.105 119.259C166.866 122.903 173.762 129.588 176.627 132.051C176.317 133.299 168.609 139.688 165.964 142.998C162.001 138.816 157.479 135.001 153.006 130.652Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M246.077 152.176C246.466 151.477 246.68 150.443 247.28 150.134C250.604 148.423 254.041 146.921 257.399 145.277C259.188 144.402 260.134 144.935 260.796 146.712C262.872 152.268 264.979 157.81 267.199 163.31C267.988 165.261 267.591 166.255 265.534 166.932C262.024 168.114 258.593 169.524 255.131 170.833L251.218 162.065C249.505 158.777 247.792 155.481 246.077 152.176Z" fill="#C973FF" className="party4"/>
                                                    <path d="M261.36 65.152L272.214 63.2656L273.157 68.6488L274.112 75.7733L274.974 86.6399C271.371 87.4591 267.78 88.3451 264.151 89.0222C263.61 89.1225 262.408 88.3061 262.309 87.7712C261.036 80.8416 259.906 73.8842 258.74 66.9352L261.36 65.152Z" fill="#C973FF" className="party4"/>
                                                    <path d="M131.225 220.425C132.791 216.435 134.264 212.403 136.014 208.494C136.273 207.92 138.053 207.477 138.845 207.755C144.256 209.653 149.569 211.81 154.955 213.771C157.245 214.607 158.116 215.621 156.789 218.051C155.288 220.798 153.766 224.351 152.485 227.212C151.687 227.084 151.045 226.786 150.231 226.532C143.887 224.546 137.56 222.473 131.225 220.425Z" fill="#993A87" className="party1"/>
                                                    <path d="M348.084 84.3634C344.439 84.2241 340.537 83.7672 336.912 83.4272C336.509 83.3882 335.934 81.6914 335.999 80.8025C336.484 74.1154 337.157 67.4645 337.667 60.7913C337.819 58.8019 339.039 58.7684 340.484 58.9217C343.177 59.2003 345.867 59.5402 348.557 59.8523C350.746 60.613 350.727 62.3238 350.496 64.1627C349.887 68.9635 348.383 82.5663 348.084 84.3634Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M191.56 196.276C191.43 196.167 190.551 194.846 190.884 194.459C193.701 191.177 198.625 185.401 199.43 184.69C199.811 184.353 200.814 184.581 201.662 184.668C201.896 184.689 202.116 184.787 202.287 184.946C206.261 188.769 210.235 192.593 214.209 196.418C214.395 196.599 214.505 196.843 214.517 197.102C214.529 197.36 214.442 197.613 214.273 197.811C211.549 200.918 208.884 203.631 205.698 207.006C205.516 207.201 205.264 207.318 204.996 207.332C204.728 207.347 204.464 207.258 204.262 207.084L191.56 196.276Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M312.351 21.1507C312.353 20.8981 312.455 20.6564 312.635 20.4773C312.815 20.2982 313.059 20.1958 313.314 20.1922C316.466 20.1448 319.621 20.1337 322.771 20C324.703 19.9219 325.452 20.6213 325.41 22.5717C325.267 29.2783 325.281 35.9906 325.129 42.6972V42.7195C325.113 43.2488 324.89 43.7513 324.507 44.1213C324.123 44.4913 323.61 44.7 323.075 44.7033C320.044 44.7284 317.123 44.642 313.134 44.6392C313.004 44.6396 312.876 44.6146 312.757 44.5657C312.637 44.5167 312.529 44.4448 312.438 44.3541C312.346 44.2634 312.274 44.1557 312.226 44.0372C312.177 43.9188 312.152 43.7919 312.153 43.664C312.215 36.4391 312.28 28.3812 312.351 21.1507Z" fill="#CB5D5F" className="party10"/>
                                                    <path d="M360.454 140.457C364.116 141.201 367.815 141.831 371.418 142.795C372.094 142.979 372.967 144.634 372.79 145.389C371.232 152.029 369.502 158.629 367.66 165.199C367.502 165.757 366.023 166.501 365.406 166.314C362.184 165.364 359.043 164.143 355.871 163.015C356.196 160.6 356.519 158.185 356.84 155.771C356.946 155.541 356.984 155.286 356.95 155.035L360.454 140.457Z" fill="#7E087C" className="party6"/>
                                                    <path d="M475.532 266.02C475.774 268.194 475.126 269.186 472.653 269.439C467.424 269.98 462.23 270.877 457.046 271.763C454.897 272.131 453.92 271.365 453.748 269.333C453.649 268.146 453.624 266.951 453.466 265.775C452.469 258.737 452.46 258.751 459.816 257.336C463.5 256.625 467.191 255.942 470.847 255.106C473.174 254.572 474.18 255.276 474.377 257.656C474.602 260.448 475.132 263.229 475.532 266.02Z" fill="#1A3E58" className="party12"/>
                                                    <path d="M165.643 199.948C163.809 203.25 162.02 206.579 160.054 209.803C159.902 210.054 158.423 209.767 157.73 209.407C152.78 206.855 147.921 204.113 142.929 201.647C140.808 200.597 140.228 199.273 141.557 197.468C143.974 194.211 145.701 190.99 148.656 187.774C154.082 191.723 160.217 196.011 165.643 199.948Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M232.144 78.4958C232.172 78.2215 232.152 77.9443 232.085 77.6767L232.265 76.7934C235.618 75.4476 239.012 74.1966 242.302 72.717C244.17 71.8812 245.119 72.3827 245.702 74.1576C247.601 79.967 249.542 85.7569 251.367 91.6082C251.576 92.2825 251.328 93.723 250.956 93.8372C247.201 95.1078 243.373 96.1749 239.567 97.3006C237.713 92.5397 235.862 87.7779 234.012 83.0152L233.217 80.9478C233.239 80.8359 233.236 80.7206 233.207 80.6103C233.178 80.4999 233.125 80.3973 233.051 80.3097C232.809 79.8918 232.565 79.4738 232.319 79.0559C232.364 78.9575 232.371 78.8463 232.338 78.7432C232.306 78.6401 232.237 78.5521 232.144 78.4958Z" fill="#F30017" className="party3"/>
                                                    <path d="M421.375 203.798C426.606 199.248 431.733 194.949 436.654 190.424C438.547 188.685 439.648 189.106 440.927 190.903C442.402 192.971 443.924 195.005 445.494 197.005C446.945 198.85 447.122 200.229 444.835 201.742C440.203 204.807 435.716 208.098 431.257 211.41C429.597 212.642 428.468 212.737 427.163 210.959C425.406 208.571 423.437 206.328 421.375 203.798Z" fill="#F6A136" className="party7"/>
                                                    <path d="M253.903 171.734C250.632 173.283 246.376 175.785 243.105 177.335L235.046 164.495L232.212 160.266C232.219 159.973 232.16 159.683 232.04 159.416L232.212 158.48C235.542 156.529 238.843 154.515 242.226 152.651C242.902 152.277 243.888 152.447 244.734 152.372L250.193 163.322C251.579 166.312 252.63 168.747 253.903 171.734Z" fill="#F30017" className="party3"/>
                                                    <path d="M312.37 59.7101C316.058 59.4314 319.742 59.0581 323.433 58.9717C323.948 58.9717 324.945 60.3314 324.957 61.0726C325.044 67.7597 324.934 74.4468 324.957 81.1507C324.957 82.8029 324.393 83.6973 322.607 83.5831C319.48 83.3825 316.351 83.1986 313.221 83.0091C312.95 76.961 312.679 70.911 312.407 64.8591L312.37 59.7101Z" fill="#C973FF" className="party4"/>
                                                    <path d="M159 245.242C160.349 241.341 161.668 237.44 163.059 233.559C163.71 231.739 165.031 231.695 166.721 232.249C171.708 233.885 176.733 235.414 181.759 236.936C183.849 237.568 182.956 239.26 182.626 240.279C181.567 243.528 180.623 245.54 178.874 249.87L159 245.242Z" fill="#993A87" className="party1"/>
                                                    <path d="M305.046 136.551C305.039 136.217 305.166 135.893 305.4 135.65C305.634 135.408 305.955 135.267 306.294 135.259C309.257 135.172 312.773 135.072 315.528 134.849C318.021 134.648 318.652 135.668 318.57 137.945C318.367 143.777 318.273 149.614 318.288 155.454C318.288 157.766 318.024 157.855 315.378 157.808C312.68 157.761 309.578 157.736 306.685 157.607C306.362 157.594 306.056 157.46 305.828 157.232C305.601 157.005 305.468 156.701 305.457 156.381C305.24 150.199 305.108 140.09 305.046 136.551Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M398.058 155.097C401.72 157.064 405.382 158.972 408.957 161.065C409.365 161.302 409.52 162.737 409.191 163.294C406.14 168.588 403.022 173.837 399.737 178.986C399.373 179.544 397.661 179.856 396.92 179.527C394.179 178.307 390.655 175.905 388.007 174.487C388.41 172.988 395.421 160.346 398.058 155.097Z" fill="#F6A136" className="party7"/>
                                                    <path d="M477.86 275.938C478.046 279.348 478.125 282.772 478.446 286.172C478.683 288.679 477.578 289.677 475.184 289.794C473.378 289.894 464.693 290.206 462.15 290.189C457.223 290.156 457.223 290.173 456.282 285.453C456.192 284.364 456.098 283.275 456 282.184C456.104 277.019 456.107 277.085 461.164 276.865C466.741 276.648 472.296 276.261 477.86 275.938Z" fill="#BBE77B" className="party11"/>
                                                    <path d="M304.181 157.858C300.232 158.647 296.234 159.175 292.214 159.437C291.975 158.418 291.338 153.564 291.259 152.16L290.558 146.364L290.428 145.01C290.169 142.781 289.614 137.858 289.614 137.858C294.496 136.024 298.572 136.147 303.297 135.924C303.581 143.154 303.882 150.619 304.181 157.858Z" fill="#C973FF" className="party4"/>
                                                    <path d="M198.346 120.619C195.83 122.224 193.247 123.739 190.827 125.47C189.374 126.512 188.438 126.306 187.469 124.96C183.883 120.028 180.255 115.11 176.717 110.145C176.379 109.671 176.227 108.437 176.455 108.298C180.151 106.038 182.968 102.26 187.723 101.822L189.506 104.564L190.244 105.679C190.263 105.949 190.377 106.206 190.565 106.403L191.25 107.295C193.143 110.27 195.137 113.19 196.884 116.247C197.633 117.551 197.873 119.15 198.346 120.619Z" fill="#F30017" className="party3"/>
                                                    <path d="M176.618 154.918C178.987 152.848 182.306 149.847 184.627 147.727C186.004 146.467 187.089 146.386 188.396 147.886C192.036 152.065 195.805 156.125 199.442 160.301C199.954 160.889 200.484 162.293 200.208 162.583C197.582 165.37 194.808 168.011 191.988 170.605C191.765 170.811 190.892 170.326 190.32 170.168C186.743 166.166 177.446 156.047 176.618 154.918Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M351.603 138.635L350.169 138.454C350.651 138.476 351.131 138.536 351.603 138.635Z" fill="#F39A01"/>
                                                    <path d="M385.815 173.573C383.37 172.439 380.95 171.249 378.469 170.196C376.584 169.396 374.423 169.029 376.26 165.858C376.824 164.869 382.691 153.679 385.303 148.198C389.528 150.583 392.047 152.191 396.717 154.431C394.827 157.599 392.914 161.185 391.058 164.37C389.28 167.421 387.562 170.503 385.815 173.573Z" fill="#7E087C" className="party6"/>
                                                    <path d="M429.876 177.821C425.087 182.912 420.58 187.76 415.94 192.452C415.414 192.984 413.969 192.642 412.96 192.678C412.847 192.678 412.735 192.491 412.614 192.399C405.129 186.827 405.12 186.827 411.104 179.582C413.957 176.13 416.85 172.708 419.941 169.017L429.876 177.821Z" fill="#F6A136" className="party7"/>
                                                    <path d="M442.852 234.223C442.401 233.665 440.398 229.918 439.753 228.745C436.727 223.122 436.727 223.15 442.643 219.951C446.35 217.948 450.148 216.075 453.677 213.799C455.889 212.372 456.7 213.097 457.799 214.992C458.182 215.652 463.041 223.835 463.041 223.835C460.39 225.117 443.75 233.777 442.852 234.223Z" fill="#7A0000" className="party8"/>
                                                    <path d="M390.765 69.5625C394.452 70.9222 398.715 72.19 402.334 73.1624C402.664 73.2516 401.974 74.7617 401.712 75.5976C399.836 81.5854 397.799 87.523 395.875 93.4941C395.269 95.3804 394.362 95.6841 392.495 94.8705C390.438 93.9705 388.179 93.5164 386.015 92.8671L385.058 92.0313C386.866 84.8092 388.962 76.7651 390.765 69.5625Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M345.028 122.536C342.106 122.472 339.171 122.519 336.272 122.235C335.782 122.188 335.027 120.686 335.084 119.906C335.579 112.959 336.168 106.016 336.937 99.0946C337.013 98.4148 338.506 97.356 339.315 97.3783C342.149 97.4647 344.971 97.9355 347.797 98.2755C347.585 101.078 347.473 103.893 347.138 106.682C346.501 111.97 345.737 117.242 345.028 122.536Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M180.787 208.415C181.097 207.064 181.784 206.565 182.444 205.406C183.835 202.949 185.429 200.61 187.21 198.412C187.816 197.671 188.861 195.994 190.261 197.172C194.813 201.073 199.368 204.199 203.92 208.092C201.971 210.407 198.286 215.297 195.819 218.401C191.027 215.4 185.576 211.402 180.787 208.415Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M286.662 123.353C283.744 123.968 280.842 124.696 277.895 125.108C277.312 125.189 276.073 124.15 275.952 123.484C274.791 117.025 273.766 110.541 272.825 104.046C272.735 103.414 273.388 102.121 273.836 102.054C277.315 101.539 280.828 101.249 284.33 100.887C284.545 102.115 284.761 103.343 284.977 104.573C285.22 106.956 285.461 109.339 285.701 111.72C286.023 115.587 286.343 119.465 286.662 123.353Z" fill="#C973FF" className="party4"/>
                                                    <path d="M245.928 114.569C245.897 114.493 245.873 114.413 245.855 114.333C245.548 112.889 245.973 111.984 247.59 111.446C249.948 110.663 252.247 109.707 254.85 108.66C255.058 108.577 255.281 108.537 255.505 108.543C255.73 108.548 255.951 108.6 256.154 108.694C256.357 108.787 256.539 108.922 256.688 109.088C256.837 109.254 256.949 109.449 257.019 109.66C259.086 115.94 261.12 122.087 263.087 128.253C263.284 128.874 263.706 129.833 262.867 130.276C260.177 131.7 258.438 132.048 254.847 133.422C254.645 133.499 254.43 133.536 254.214 133.531C253.997 133.525 253.784 133.478 253.587 133.39C253.389 133.303 253.211 133.178 253.063 133.022C252.915 132.866 252.799 132.683 252.723 132.483L245.928 114.569Z" fill="#F30017" className="party3"/>
                                                    <path d="M219.372 151.369C216.994 153.119 214.696 154.991 212.186 156.524C211.622 156.866 209.932 156.309 209.419 155.66C205.757 150.937 202.337 146.055 198.743 141.291C197.672 139.873 197.844 139.014 199.323 138.101C201.489 136.763 203.509 135.18 205.738 133.96C206.36 133.62 207.495 134.189 208.394 134.342L214.842 144.211L215.91 145.86L219.372 151.369Z" fill="#F30017" className="party3"/>
                                                    <path d="M203.087 183.612C201.923 182.478 202.83 181.182 203.391 180.516C209.307 173.453 210.47 174.944 216.003 180.483C217.352 181.876 221.958 186.228 223.372 187.605C223.372 187.605 218.513 193.386 215.997 195.994L203.087 183.612Z" fill="#2F3FC9" className="party2"/>
                                                    <path d="M241.79 177.805C241.069 179.691 236.266 182.982 234.933 182.659C234.192 182.478 233.494 181.706 232.995 181.04C229.702 176.626 226.53 172.124 223.183 167.752C221.882 166.047 221.955 164.798 223.797 163.762C225.967 162.539 227.713 161.667 229.913 160.502C231.141 162.597 232.849 164.439 234.074 166.534C236.468 170.134 239.412 174.202 241.79 177.805Z" fill="#F30017" className="party3"/>
                                                    <path d="M339.54 160.24C337.472 160.207 335.363 160.396 333.36 160.028C332.63 159.895 331.593 158.39 331.65 157.576C332.092 151.196 332.754 144.832 333.374 138.462C333.548 136.654 334.531 135.829 336.433 136.136C338.334 136.442 340.312 136.693 342.253 136.949C341.929 141.369 339.923 158.125 339.678 159.354C339.639 159.65 339.591 159.945 339.54 160.24Z" fill="#3F95F8" className="party5"/>
                                                    <path d="M290.403 159.438C282.797 160.524 281.783 161.255 280.617 153.971C279.98 150.009 279.442 146.027 278.701 142.085C278.262 139.75 278.884 138.318 281.394 138.095C283.555 137.905 285.727 137.838 287.893 137.716C288.152 139.538 288.409 141.359 288.665 143.18C288.916 145.412 290.476 157.677 290.403 159.438Z" fill="#C973FF" className="party4"/>
                                                    <path d="M195.624 219.761C194.7 221.921 193.52 224.395 192.388 226.669C192.258 226.925 190.726 226.766 190.021 226.435C185.275 224.206 178.3 221.305 173.731 218.736C172.581 218.09 177.734 209.898 179.866 210.202C184.666 213.208 190.827 216.755 195.624 219.761Z" fill="#993A87" className="party1"/>
                                                    <path d="M173.305 276.711C168.355 277.042 152.386 275.952 151.5 276C151.417 274.819 153.255 265.999 153.98 262.973C156.454 263.182 172.181 265.151 173.439 265.333C175.822 265.925 173.785 276.677 173.305 276.711Z" fill="#993A87" className="party1"/>
                                                    <path d="M140.154 275.278C138.597 275.278 137.354 275.209 135.988 275.128C129.255 274.727 122.985 274.027 116.269 273.406C116.269 273.161 118.599 261.821 119.218 259.148C121.32 259.452 136.053 261.272 141.239 262.116C140.94 264.637 140.154 275.047 140.154 275.278Z" fill="#993A87" className="party1"/>
                                                    <path d="M177.356 257.946L177.368 257.893L177.349 257.921C177.349 257.921 177.351 257.947 177.351 257.958C177.069 259.457 176.185 262.589 175.912 264.046C175.027 264.126 160.149 261.431 154.663 260.802C154.663 260.802 156.806 251.046 157.584 248.031L160.494 248.491L162.22 248.654L178.5 251.5C178.319 253.856 177.764 255.458 177.356 257.946Z" fill="#993A87" className="party1"/>
                                                    <path d="M177.703 260.04C177.699 260.058 177.694 260.076 177.688 260.093C177.688 260.093 177.688 260.068 177.688 260.057L177.703 260.04Z" fill="#993A87" className="party1"/>
                                                    <path d="M318.776 96.8291C318.728 101.446 318.477 112.48 318.694 117.086C318.826 119.939 319.694 121.931 317.063 121.803C313.646 121.638 308.263 121.803 304.843 121.747C304.865 119.989 304.446 103.814 304.209 97.4839C305.972 97.417 318.776 96.8291 318.776 96.8291Z" fill="#C973FF" className="party4"/>
                                                    <path d="M354.484 158.056C353.715 161.274 352.62 162.481 349.532 162.16C347.135 161.812 343.616 160.745 341.216 160.397C341.673 158.641 344.726 139.892 345.002 137.797L350.169 138.454L351.603 138.636L357.775 139.608C357.194 143.52 355 155.894 354.484 158.056Z" fill="#7E087C" className="party6"/>
                                                    <path d="M351.603 138.635L350.169 138.454C350.651 138.476 351.131 138.536 351.603 138.635Z" fill="#F39B05"/>
                                                    <path d="M261.36 65.1521L258.751 66.9325C258.692 65.0156 259.869 64.8595 261.36 65.1521Z" fill="#E7494F"/>
                                                    <path d="M139.893 277.176C139.394 280.436 139.113 284.699 138.766 288.215C138.718 288.684 135.507 288.6 134.259 288.628C128.388 288.753 122.512 288.711 116.638 288.728C114.557 288.856 113.593 287.892 113.923 285.858C114.486 282.311 115.128 278.77 115.734 275.229C123.317 275.833 132.307 276.594 139.893 277.176Z" fill="#993A87" className="party1"/>
                                                    <path d="M150.307 278.831C156.629 279.129 162.95 279.45 169.274 279.714C173.162 279.876 173.5 280.255 172.72 283.894C172.505 284.902 172.362 285.928 172.184 286.945C172.162 287.223 172.136 287.502 172.117 287.781L171.196 288.667C164.998 288.667 158.773 288.722 152.564 288.667C148.696 288.628 148.451 288.249 149.183 284.51C149.561 282.617 149.936 280.724 150.307 278.831Z" fill="#993A87" className="party1"/>
                                                    <path d="M177.703 260.04L177.688 260.093C177.688 260.093 177.688 260.068 177.688 260.057L177.703 260.04Z" fill="#993A87" className="party1"/>
                                                    </g>

                                                    <path d="M506.108 231.305C506.722 233.403 506.255 234.492 503.863 235.169C498.806 236.605 493.845 238.386 488.893 240.155C486.839 240.888 485.744 240.302 485.224 238.331C484.922 237.179 484.69 236.006 484.332 234.875C482.133 228.115 482.127 228.131 489.127 225.466C492.634 224.129 496.151 222.819 499.608 221.364C501.807 220.435 502.92 220.956 503.525 223.265C504.229 225.976 505.231 228.624 506.108 231.305Z" fill="#0C2355" className="party9"/>
                                                    <path d="M539.108 217.925C539.722 220.023 539.255 221.112 536.863 221.79C531.806 223.225 526.845 225.006 521.893 226.775C519.839 227.508 518.744 226.923 518.224 224.952C517.922 223.799 517.69 222.626 517.332 221.496C515.133 214.736 515.127 214.751 522.127 212.086C525.634 210.749 529.151 209.439 532.608 207.984C534.807 207.055 535.92 207.576 536.525 209.886C537.229 212.597 538.231 215.244 539.108 217.925Z" fill="#0C2355" className="party9"/>
                                                    <path d="M450.198 252.532C449.237 249.76 448.201 246.578 447.198 243.822C446.492 241.889 446.42 240.604 448.983 239.82C453.67 238.39 458.247 236.586 462.821 234.814C464.485 234.167 465.432 234.286 465.952 236.142C466.748 238.967 467.643 241.766 468.606 244.536C469.289 246.494 468.413 247.382 466.646 247.883C461.921 249.228 450.997 252.471 450.198 252.532Z" fill="#0C2355" className="party9"/>
                                                </g>
                                                <text x="52%" y="90%" alignmentBaseline="middle" strokeWidth="0" fill="#1A3E58" textAnchor="middle" className="totalsenado" style={{ fontWeight: '500', fontSize: 24, width: '100%', textAlign: 'center'}}>108</text>

                                                <defs>
                                                    <filter id="filter0_d_1720_1045" x="0" y="0" width="639" height="331" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                        <feOffset dy="1"/>
                                                        <feGaussianBlur stdDeviation="3"/>
                                                        <feComposite in2="hardAlpha" operator="out"/>
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"/>
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1720_1045"/>
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1720_1045" result="shape"/>
                                                    </filter>

                                                    <clipPath id="clip0_1720_1045">
                                                        <rect width="523.5" height="269.525" fill="white" transform="translate(41.5 20)"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 mb-0">
                                    <div className="card">
                                        <div className="card-body overflow-auto">
                                            <ul className="parties">
                                                <li className="senado7">
                                                    <img src="./assets/images/parties/senado/7.png" />
                                                    <Link to={`/parties/senado/7`} className="no-text-decoration">{ senadoParties[6].name }</Link>
                                                </li>
                                                <li className="senado8">
                                                    <img src="./assets/images/parties/senado/8.png" />
                                                    <Link to={`/parties/senado/8`} className="no-text-decoration">{ senadoParties[7].name }</Link>
                                                </li>
                                                <li className="senado9">
                                                    <img src="./assets/images/parties/senado/9.png" />
                                                    <Link to={`/parties/senado/9`} className="no-text-decoration">{ senadoParties[8].name }</Link>
                                                </li>
                                                <li className="align-items-start senado10">
                                                    <img src="./assets/images/parties/senado/10.png" />
                                                    <Link to={`/parties/senado/10`} className="no-text-decoration">{ senadoParties[9].name }</Link>
                                                </li>
                                                <li className="align-items-start senado11">
                                                    <img src="./assets/images/parties/senado/11.png" />
                                                    <Link to={`/parties/senado/11`} className="no-text-decoration">{ senadoParties[10].name }</Link>
                                                </li>
                                                <li className="align-items-start senado12">
                                                    <img src="./assets/images/parties/senado/12.png" />
                                                    <Link to={`/parties/senado/12`} className="no-text-decoration">{ senadoParties[11].name }</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Camara */}
                        <div className="tab-pane fade" id="camara-tab-pane" role="tabpanel" aria-labelledby="camara-tab" tabIndex="0">
                            <div className="row">
                                {/* Parties */}
                                <div className="col-md-3 mb-0">
                                    <div className="card">
                                        <div className="card-body overflow-auto">
                                            <ul className="parties">
                                                <li className="camara1">
                                                    <img src="./assets/images/parties/camara/1.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/13`} className="no-text-decoration">{ camaraParties[0].name }</Link>
                                                </li>
                                                <li className="camara2">
                                                    <img src="./assets/images/parties/camara/2.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/14`} className="no-text-decoration">{ camaraParties[1].name }</Link>
                                                </li>
                                                <li className="camara3">
                                                    <img src="./assets/images/parties/camara/3.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/15`} className="no-text-decoration">{ camaraParties[2].name }</Link>
                                                </li>
                                                <li className="camara4">
                                                    <img src="./assets/images/parties/camara/4.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/16`} className="no-text-decoration">{ camaraParties[3].name }</Link>
                                                </li>
                                                <li className="camara5">
                                                    <img src="./assets/images/parties/camara/5.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/17`} className="no-text-decoration">{ camaraParties[4].name }</Link>
                                                </li>
                                                <li className="camara6">
                                                    <img src="./assets/images/parties/camara/61.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/18`} className="no-text-decoration">{ camaraParties[5].name }</Link>
                                                </li>
                                                <li className="camara7">
                                                    <img src="./assets/images/parties/camara/6.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/19`} className="no-text-decoration">{ camaraParties[6].name }</Link>
                                                </li>
                                                <li className="camara8">
                                                    <img src="./assets/images/parties/camara/7.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/20`} className="no-text-decoration">{ camaraParties[7].name }</Link>
                                                </li>
                                                <li className="camara9">
                                                    <img src="./assets/images/parties/camara/8.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/21`} className="no-text-decoration">{ camaraParties[8].name }</Link>
                                                </li>
                                                <li className="camara10">
                                                    <img src="./assets/images/parties/camara/9.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/22`} className="no-text-decoration">{ camaraParties[9].name }</Link>
                                                </li>
                                                <li className="camara11">
                                                    <img src="./assets/images/parties/camara/10.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/23`} className="no-text-decoration">{ camaraParties[10].name }</Link>
                                                </li>
                                                <li className="camara12">
                                                    <img src="./assets/images/parties/camara/11.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/24`} className="no-text-decoration">{ camaraParties[11].name }</Link>
                                                </li>
                                                <li className="camara13">
                                                    <img src="./assets/images/parties/camara/12.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/25`} className="no-text-decoration">{ camaraParties[12].name }</Link>
                                                </li>
                                                <li className="camara14">
                                                    <img src="./assets/images/parties/camara/13.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/26`} className="no-text-decoration">{ camaraParties[13].name }</Link>
                                                </li>
                                            </ul>
                                            <ul className="parties d-none">
                                                <li className="camara15">
                                                    <img src="./assets/images/parties/camara/14.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/27`} className="no-text-decoration">{ camaraParties[14].name }</Link>
                                                </li>
                                                <li className="camara16">
                                                    <img src="./assets/images/parties/camara/15.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/28`} className="no-text-decoration">{ camaraParties[15].name }</Link>
                                                </li>
                                                <li className="camara17">
                                                    <img src="./assets/images/parties/camara/17.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/29`} className="no-text-decoration">{ camaraParties[16].name }</Link>
                                                </li>
                                                <li className="camara18">
                                                    <img src="./assets/images/parties/camara/16.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/30`} className="no-text-decoration">{ camaraParties[17].name }</Link>
                                                </li>
                                                <li className="camara19">
                                                    <img src="./assets/images/parties/camara/18.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/31`} className="no-text-decoration">{ camaraParties[18].name }</Link>
                                                </li>
                                                <li className="camara20">
                                                    <img src="./assets/images/parties/camara/19.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/32`} className="no-text-decoration">{ camaraParties[19].name }</Link>
                                                </li>
                                                <li className="camara21">
                                                    <img src="./assets/images/parties/camara/20.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/33`} className="no-text-decoration">{ camaraParties[20].name }</Link>
                                                </li>
                                                <li className="camara22">
                                                    <img src="./assets/images/parties/camara/22.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/34`} className="no-text-decoration">C{ camaraParties[21].name }</Link>
                                                </li>
                                                <li className="camara23">
                                                    <img src="./assets/images/parties/camara/23.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/35`} className="no-text-decoration">{ camaraParties[22].name }</Link>
                                                </li>
                                                <li className="camara24">
                                                    <img src="./assets/images/parties/camara/24.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/36`} className="no-text-decoration">{ camaraParties[23].name }</Link>
                                                </li>
                                                <li className="camara25">
                                                    <img src="./assets/images/parties/camara/25.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/37`} className="no-text-decoration">{ camaraParties[24].name }</Link>
                                                </li>
                                                <li className="camara26">
                                                    <img src="./assets/images/parties/camara/26.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/38`} className="no-text-decoration">{ camaraParties[25].name }</Link>
                                                </li>
                                                <li className="camara27">
                                                    <img src="./assets/images/parties/camara/27.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/39`} className="no-text-decoration">{ camaraParties[26].name }</Link>
                                                </li>
                                                <li className="camara28">
                                                    <img src="./assets/images/parties/camara/28.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/40`} className="no-text-decoration">{ camaraParties[27].name }</Link>
                                                </li>
                                                <li className="camara29">
                                                    <img src="./assets/images/parties/camara/29.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/41`} className="no-text-decoration">{ camaraParties[28].name }</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* Hemicircle */}
                                <div className="col-md-6 mb-0">
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-center align-items-center p-0">
                                            <svg width="560" height="291" viewBox="0 -20 560 291" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M352.739 274.431C352.739 253.479 344.469 233.385 329.747 218.569C315.026 203.754 295.059 195.431 274.239 195.431C253.42 195.431 233.453 203.754 218.731 218.569C204.01 233.385 195.739 253.479 195.739 274.431L274.239 274.431L352.739 274.431Z" fill="#F2F2F2"/>
                                                
                                                <text x="49%" y="85%" alignmentBaseline="middle" strokeWidth="0" fill="#1A3E58" textAnchor="middle" className="totalcamara" style={{ fontWeight: '500', fontSize: 24, width: '100%', textAlign: 'center'}}>188</text>

                                                <g clipPath="url(#clip0_829_9842)">
                                                <path d="M87.6853 241.291C87.4006 243.135 85.9468 254.822 85.1444 254.82C84.7993 254.82 69.295 253.893 67.4443 253.657C67.559 251.811 67.7866 249.973 68.1259 248.154C68.5918 245.662 68.924 243.133 69.597 240.696C69.8278 239.858 69.9141 238.02 71.5965 238.535C71.8295 238.558 78.6002 239.76 79.6787 239.947C82.3447 240.417 85.0172 240.842 87.6853 241.291Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M66.3467 269.057C66.3467 264.944 66.7479 259.627 67.4446 256.456C73.4539 256.413 79.4654 257.002 85.4747 256.962L85.328 258.944C84.9139 262.3 85.4445 269.482 83.7793 269.457C79.0469 269.387 71.092 269.051 66.3467 269.057Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M189.348 105.576C188.917 106.046 188.546 106.65 188.008 106.967C183.694 109.519 179.331 111.969 175.026 114.524C173.923 115.176 172.989 115.564 172.027 114.417C169.202 110.098 166.083 105.076 163.257 100.757C162.72 99.8335 174.721 92.9383 179.292 90.2628C180.461 89.58 181.395 89.344 182.202 90.6562C184.583 95.6288 186.965 100.602 189.348 105.576Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M103.587 159.319C103.539 159.288 103.493 159.255 103.448 159.219C102.344 158.356 102.663 157.754 103.041 157.228C106.492 152.449 110.042 147.749 113.629 143.072C113.744 142.929 113.885 142.807 114.044 142.713C114.396 142.501 114.809 142.409 115.219 142.452C115.629 142.494 116.013 142.669 116.313 142.949L126.714 152.52C127.054 152.831 127.266 153.254 127.311 153.71C127.356 154.165 127.23 154.621 126.957 154.991C125.939 156.358 124.921 157.726 123.881 159.079C121.927 161.631 119.954 164.153 117.984 166.686C117.695 167.059 117.274 167.31 116.806 167.391C116.337 167.472 115.854 167.376 115.454 167.122C112.527 165.274 106.425 161.159 103.587 159.319Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M205.959 97.9408L190.916 105.419C190.204 104.272 189.406 103.169 188.8 101.969C186.874 98.1578 185.021 94.3125 183.14 90.4842C183.403 89.5994 183.401 88.1893 183.971 87.9128C189.024 85.4457 194.177 83.17 199.315 80.8751C199.576 80.7582 199.986 80.9666 200.327 81.024L201.339 83.5762C201.977 85.2281 202.615 86.8806 203.252 88.5339L204.637 91.9942C205.077 93.9778 205.517 95.96 205.959 97.9408Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M117.139 140.112C119.48 137.521 125.504 131.204 127.995 128.512C128.134 128.361 128.303 128.24 128.49 128.156C128.678 128.071 128.881 128.025 129.088 128.021C129.294 128.016 129.499 128.053 129.691 128.129C129.882 128.205 130.056 128.319 130.202 128.463C133.321 131.56 136.936 135.097 140.031 138.17C140.282 138.419 140.437 138.747 140.468 139.096C140.499 139.446 140.405 139.796 140.202 140.084C139.77 140.701 139.388 141.286 138.892 141.892C136.621 144.657 133.437 147.698 130.743 150.533C130.19 151.116 129.427 151.463 128.618 151.499C127.809 151.534 127.018 151.257 126.414 150.724C123.811 148.423 119.814 144.599 117.232 142.202C116.944 141.934 116.773 141.566 116.755 141.175C116.738 140.785 116.876 140.403 117.139 140.112Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M91.7042 223.568C90.5654 228.785 89.4251 234 88.2833 239.215L87.6189 239.898C87.6189 239.898 70.2899 236.723 70.3395 236.521C71.6789 231.038 73.0637 225.568 74.4377 220.095C77.9406 220.74 90.1167 223.113 91.7042 223.568Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M75.145 218.66C75.4405 216.907 78.6932 205.986 80.0241 202.283C81.4067 202.496 92.9249 205.714 97.3036 207.117L92.4461 222.05L87.0299 221.369L75.145 218.66Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M179.572 126.713C179.84 125.745 179.788 124.284 180.435 123.89C184.602 121.3 188.908 118.922 193.206 116.542C193.584 116.329 194.246 116.616 194.777 116.672C196.916 121.198 199.06 125.723 201.207 130.247C201.474 131.372 201.617 132.308 200.243 133.038C196.617 134.962 193.107 137.102 189.488 139.035C188.949 139.325 188.058 138.972 187.331 138.916L181.529 129.543C181.354 129.318 181.181 129.092 181.009 128.865L180.383 128.046L179.572 126.713Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M432.538 160.959C431.873 160.589 427.115 153.91 425.021 151.205C423.772 149.591 423.727 148.315 425.519 146.932C429.24 144.061 432.853 141.047 436.444 138.023C437.387 137.232 438.034 136.83 438.942 138.036C441.483 141.409 447.112 149.114 447.842 149.882C443.239 153.149 437.13 157.683 432.538 160.959Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M389.26 95.2142C389.966 95.1759 390.878 94.8356 391.342 95.1461C395.121 97.6835 398.85 100.297 402.487 103.015C402.897 103.324 402.981 104.681 402.651 105.212C400.062 109.383 397.31 113.452 394.685 117.599C393.775 119.037 392.927 118.577 391.859 117.871C388.408 115.587 384.935 113.35 381.469 111.095C382 109.851 382.464 108.573 383.07 107.365C385.106 103.307 387.194 99.2637 389.26 95.2142Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M409.016 132.885C406.169 130.239 402.467 127.034 399.525 124.482C398.164 123.295 398.179 122.297 399.228 121.011C402.196 117.365 405.163 113.72 408.071 110.019C409.15 108.643 410.066 108.868 411.22 109.866C414.352 112.573 418.073 115.685 421.231 118.373C420.802 119.058 409.853 131.879 409.016 132.885Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M114.124 168.558C114.471 168.793 114.715 169.148 114.807 169.553C114.899 169.958 114.832 170.382 114.62 170.74C112.463 174.409 110.306 178.086 108.03 181.685C107.773 182.089 106.341 182.214 105.757 181.897C101.579 179.72 97.4588 177.431 93.0435 175.006C92.8427 174.897 92.6671 174.748 92.5276 174.568C92.3881 174.388 92.2878 174.182 92.2329 173.963C92.178 173.743 92.1698 173.514 92.2087 173.292C92.2476 173.069 92.3328 172.856 92.4589 172.667C95.153 168.605 97.6033 164.881 100.151 161.22C100.39 160.878 100.97 160.767 101.54 160.646C101.762 160.599 101.991 160.597 102.213 160.641C102.436 160.684 102.647 160.772 102.834 160.899L114.124 168.558Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M195.687 116.525C196.049 115.898 196.252 114.96 196.798 114.692C201.112 112.565 205.495 110.559 209.882 108.571C210.16 108.445 210.667 108.798 211.066 108.928C211.232 109.262 211.396 109.598 211.562 109.932C211.638 110.313 211.715 110.695 211.791 111.076L212.285 111.967C212.828 113.445 213.447 114.902 213.905 116.406C214.809 119.384 215.63 122.399 216.476 125.398C212.794 127.044 209.077 128.625 205.443 130.369C204.149 130.985 203.122 131.377 202.11 130.086C199.969 125.567 197.828 121.047 195.687 116.525Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M467.187 182.476C466.861 182.701 457.146 187.274 452.781 189.377C451.2 190.137 450.408 190.075 449.629 188.314C448.253 185.183 446.493 182.219 445.046 179.113C444.776 178.539 444.96 177.223 445.391 176.953C449.842 174.151 454.355 171.45 458.932 168.851C459.39 168.592 460.721 168.879 460.923 169.277C463.063 173.433 465.058 177.661 467.088 181.87C467.138 182.069 467.171 182.272 467.187 182.476Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M366.349 102.147C364.455 101.492 356.8 97.8726 354.751 96.9793C353.135 96.2774 352.704 95.5394 353.422 93.8933C355.238 89.7332 356.964 85.5376 358.599 81.3067C359.304 79.484 360.124 79.1479 361.931 80.0604C365.205 81.7172 368.631 83.0762 371.944 84.6607C372.688 85.0159 373.238 85.7837 373.866 86.3621L366.349 102.147Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M309.148 66.4424C310.675 66.4573 320.498 67.8355 323.885 68.5097C323.643 70.8088 321.236 83.104 320.276 86.9535L307.865 84.9267L306.454 84.2886C306.946 80.656 308.74 68.7649 309.148 66.4424Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M126.655 173.435C129.358 170.11 131.992 166.729 134.813 163.504C135.499 162.718 135.948 161.863 136.97 161.354C140.648 164.544 145.166 168.309 148.846 171.495C145.842 175.657 142.85 179.828 139.8 183.958C139.601 184.228 138.961 184.182 138.525 184.284C135.227 181.931 131.998 179.477 128.603 177.278C127.041 176.276 126.31 175.2 126.655 173.435Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M422.115 120.009C423.787 121.357 425.566 122.593 427.102 124.082C428.804 125.743 430.182 127.725 431.88 129.399C433.174 130.658 433.105 131.511 431.858 132.749C428.116 136.458 424.479 140.271 420.456 144.397L410.336 133.742L422.115 120.009Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M256.898 84.2759C253.505 84.7183 244.226 85.7647 242.923 85.8838C242.707 84.278 242.491 81.8428 242.276 80.2264C241.81 76.089 241.345 71.9516 240.882 67.8142C245.729 67.0273 250.791 66.2425 256.05 65.5002C256.093 65.5002 256.266 68.9287 256.309 70.158C256.494 74.8647 256.702 79.5692 256.898 84.2759Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M448.318 151.541C449.144 151.996 453.536 159.534 455.619 162.535C456.579 163.919 456.361 164.685 454.972 165.529C450.846 168.03 446.724 170.549 442.703 173.209C441.383 174.083 440.762 173.888 439.994 172.658C437.837 169.172 435.6 165.731 433.396 162.271C437.993 158.845 443.728 154.969 448.318 151.541Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M80.8306 200.901C82.4677 196.683 84.066 191.626 85.7916 187.442C85.9296 187.11 86.9758 186.825 87.4331 186.985C92.2258 188.656 96.9992 190.388 101.729 192.217C102.21 192.402 102.791 193.54 102.62 193.957C101 197.932 99.2274 201.847 97.4975 205.782C93.3194 204.582 82.1161 201.366 80.8306 200.901Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M103.302 224.576C103.065 224.519 102.861 224.372 102.735 224.166C102.608 223.961 102.568 223.715 102.625 223.481C102.737 223.02 102.821 222.675 102.998 222.124C104.092 218.706 105.269 215.318 106.579 211.964C106.794 211.401 107.025 210.639 107.497 210.286C107.713 210.116 108.632 210.395 108.902 210.478L121.589 214.306C123.304 214.804 124.233 214.606 123.161 217.283C122.053 220.686 120.953 224.07 119.779 227.439C119.658 227.782 119.6 227.905 119.525 228.077C119.438 228.28 119.28 228.445 119.079 228.541C118.878 228.637 118.649 228.658 118.433 228.6C115.569 227.843 106.374 225.317 103.302 224.576Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M380.242 109.649C379.189 109.319 368.284 102.973 367.669 102.171L375.471 86.4324C378.706 88.2104 387.422 93.5785 388.533 94.4867C388.102 94.9737 382.138 105.846 380.242 109.649Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M160.149 159.487C156.806 162.824 153.102 167.356 149.703 170.651L137.839 160.376C141.291 156.494 145.173 152.203 148.624 148.321C149.424 148.538 160.436 159.2 160.149 159.487Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M325.886 69.188C330.287 70.1387 335.233 71.3488 339.633 72.2995C339.391 73.4884 338.986 74.5327 338.69 75.7259C337.553 80.4602 335.066 90.2755 335.066 90.2755C331.184 89.2589 326.178 88.204 322.314 87.1384L323.906 78.2802L325.886 69.188Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M272.275 82.2532C271.789 82.6615 271.356 83.1592 270.853 83.1805C266.882 83.34 262.961 83.3931 258.897 83.6718C258.712 82.2872 257.711 65.5193 257.864 65.4895C262.471 64.6026 267.7 64.4112 271.746 65.0152C271.923 70.7619 272.099 76.5079 272.275 82.2532Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M98.7747 241.332C98.6237 240.728 98.2269 240.056 98.3606 239.526C99.4046 235.336 100.865 230.1 101.98 225.927C104.76 226.54 116.913 229.86 118.351 230.281L114.818 244.054C113.893 243.82 103.197 242.012 98.7747 241.332Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M375.44 120.677C377.535 121.806 379.698 122.827 381.696 124.099C383.206 125.058 384.394 126.498 385.913 127.433C387.517 128.425 387.489 129.286 386.506 130.709C384.042 134.273 381.66 137.892 379.358 141.566C378.38 143.129 377.561 143.599 375.872 142.364C373.471 140.607 370.84 139.157 368.374 137.483C367.792 137.087 367.47 136.324 367.028 135.728L375.44 120.677Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M315.007 115.861C311.193 115.332 307.006 114.605 303.208 114.003C302.761 113.932 302.58 113.516 302.186 113.137C302.229 112.712 304.593 96.2393 304.867 95.3567C309.245 96.2457 314.433 97.1985 318.814 98.0854C318.587 98.8 315.231 115.172 315.007 115.861Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M399.612 138.569C399.295 139.101 399.073 139.632 398.717 140.058C395.617 143.707 392.462 147.304 389.394 150.987C388.221 152.402 387.225 152.344 385.991 151.147C383.778 149.001 381.44 146.97 379.347 144.72C378.896 144.235 378.886 142.746 379.308 142.18C382.112 138.418 385.085 134.77 388.033 131.11C388.68 130.304 389.399 129.441 390.594 130.472C393.288 132.812 396.01 135.113 398.704 137.448C399.036 137.733 399.265 138.135 399.612 138.569Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M227.44 151.988C224.509 153.264 216.61 157.169 215.669 157.256C214.688 155.238 211.433 148.536 210.98 147.385L208.942 143.234C208.5 142.351 207.963 141.405 209.393 140.775C213.016 139.184 216.537 137.362 220.178 135.781C220.505 135.639 221.118 136.132 221.597 136.33C223.029 140.06 227.112 150.225 227.44 151.988Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M407.263 174.695C404.832 171.361 402.398 168.101 400.103 164.749C399.884 164.431 400.354 163.307 400.82 162.935C404.764 159.779 408.807 156.749 412.757 153.595C413.816 152.753 414.36 153.173 414.976 154.018C417.063 156.886 419.22 159.707 421.195 162.647C421.508 163.112 421.288 164.423 420.843 164.791C419.347 166.031 417.601 166.964 416.014 168.115C413.097 170.249 410.227 172.457 407.263 174.695Z" fill="#30697B" className="camaraparty26"/>
                                                <path d="M362.078 113.735C362.913 113.86 363.864 113.777 364.567 114.147C367.725 115.804 370.823 117.588 373.924 119.328L365.562 135.66C362.365 133.991 359.143 132.361 355.989 130.613C355.407 130.288 355.105 129.475 354.673 128.886C357.141 123.838 359.609 118.788 362.078 113.735Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M353.655 128.759C349.973 127.074 346.27 125.432 342.655 123.635C342.299 123.458 342.167 122.216 342.389 121.644C344.134 117.178 346.004 112.767 347.808 108.328C348.261 107.207 348.765 106.642 350.118 107.299C353.137 108.762 356.248 110.008 359.22 111.535C359.941 111.906 360.266 113.024 360.775 113.786L353.655 128.759Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M214.362 156.992C214.315 157.43 214.42 158.147 214.198 158.268C210.607 160.159 206.975 161.973 203.353 163.798L200.575 159.629C200.312 159.204 200.046 158.789 199.783 158.37L195.178 151.484C194.531 149.754 194.939 148.506 196.688 147.594C199.419 146.169 202.126 144.676 204.712 143.015C206.47 141.883 207.33 142.545 208.072 144.065L210.15 148.242C210.62 149.387 211.089 150.531 211.558 151.677L214.362 156.992Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M258.878 113.686C255.725 114.064 252.575 114.494 249.413 114.785C248.49 114.868 247.53 114.551 246.588 114.417L245.203 104.055L244.467 97.8875C244.532 97.8228 244.573 97.7383 244.582 97.648C244.592 97.5576 244.571 97.4666 244.521 97.3898C248.766 96.6646 253.007 95.9202 257.26 95.2524C257.551 95.2077 257.938 95.7458 258.281 96.0138L258.941 105.01C258.992 105.861 259.045 106.717 259.098 107.579L258.878 113.686Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M130.454 127.07C130.199 126.811 130.056 126.465 130.056 126.104C130.056 125.744 130.199 125.397 130.454 125.139C130.922 124.667 131.23 124.371 131.688 123.888C133.651 121.817 135.639 119.879 137.684 117.88C138.978 116.604 139.979 116.78 141.071 118.124C144.09 121.834 147.177 125.483 150.179 129.203C150.538 129.643 150.283 130.375 150.082 130.543C147.393 132.804 145.208 135.337 142.453 137.523C142.18 137.741 141.834 137.85 141.483 137.829C141.132 137.808 140.801 137.658 140.557 137.408C137.33 134.095 133.694 130.388 130.454 127.07Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M130.156 231.714C130.84 227.018 131.554 222.339 134.938 218.587C139.015 219.842 143.135 220.986 147.142 222.416C148.409 222.867 149.468 223.279 150.874 223.904C149.651 227.297 148.07 231.733 146.84 235.128C142.951 234.426 131.315 231.967 130.156 231.714Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M241.473 86.29C238.523 86.8727 235.537 87.3215 232.63 88.0893C230.891 88.5508 230.072 88.3594 229.755 86.4771C228.918 81.5386 227.984 76.6172 227.02 71.702C226.754 70.3515 227.362 69.7666 228.545 69.5752C232.179 68.9925 235.82 68.4522 239.461 67.895C240.147 72.9611 240.833 78.0272 241.521 83.0934L241.473 86.29Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M159.441 179.59C162.72 174.889 165.358 172.065 169.098 168.722C172.549 172.408 176 176.088 179.452 179.762C179.525 180.209 179.831 180.864 179.639 181.068C176.999 183.858 174.303 186.608 171.579 189.318C171.436 189.46 170.921 189.241 170.578 189.19L162.332 182.272L159.441 179.59Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M259.741 95.2608L272.322 94.5547C272.505 99.4294 272.689 104.304 272.875 109.179C272.885 110.238 272.896 111.298 272.909 112.358L272.881 112.324L271.49 112.994C268.85 113.077 266.209 113.25 263.574 113.207C262.551 113.188 261.54 112.663 260.524 112.356C260.292 108.901 260.062 105.444 259.833 101.986C259.795 101.362 259.755 100.739 259.715 100.116L259.741 95.2608Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M114.809 245.424C114.152 249.262 114.085 249.656 113.429 253.506C113.317 253.58 112.978 256.853 112.888 256.949C111.809 256.885 98.7702 255.988 95.9834 255.754C96.3005 252.41 97.6917 243.68 98.0713 242.659C101.812 243.103 113.884 245.207 114.809 245.424Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M113.11 258.132C112.946 261.522 112.827 263.976 112.666 267.366C112.627 267.587 112.609 267.81 112.614 268.034C111.645 268.494 110.69 269.31 109.706 269.346C105.435 269.491 101.156 269.346 96.883 269.436C95.0711 269.474 94.3442 268.825 94.5103 267.056C94.8037 263.793 95.0345 260.526 95.2911 257.272C95.7118 257.153 112.029 258.07 113.11 258.132Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M320.414 98.0854C321.54 98.3215 331.785 101.218 332.249 101.737C330.789 107.178 329.331 113.677 327.87 119.118L316.887 116.523C317.564 112.18 319.916 99.889 320.414 98.0854Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M108.515 209.129C108.362 208.734 107.98 208.255 108.084 207.958C109.249 204.886 110.513 201.854 111.706 198.793C112.422 196.96 113.444 196.409 115.405 197.33C119.41 199.21 123.489 200.945 127.576 202.647C128.942 203.215 128.858 203.951 128.353 205.042C127.016 207.926 125.745 210.837 124.447 213.736C123.8 213.76 120.931 212.845 120.079 212.581C116.244 211.384 112.374 210.272 108.515 209.129Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M440.023 194.828C436.062 196.879 431.091 200.048 427.107 202.06C424.423 203.414 424.419 203.276 423.205 200.571C421.859 197.563 420.304 194.645 418.74 191.489C423.608 188.818 428.662 186.172 433.407 183.546C433.97 183.503 440.076 194.546 440.023 194.828Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M126.004 245.385C126.932 241.523 127.859 237.66 128.785 233.796C129 233.552 129.205 233.309 129.417 233.065L129.436 233.05L133.644 233.705L146.179 236.521L142.801 247.623L130.84 246.109L126.004 245.385Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M169.726 190.011C169.821 190.453 170.157 191.096 169.974 191.311C167.435 194.288 164.825 197.204 162.235 200.135L149.778 191.819C149.754 191.381 149.528 190.77 149.739 190.526C152.656 187.14 155.632 183.803 158.594 180.453L161.486 183.054L169.726 190.011Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M201.306 80.8728C201.738 80.3943 202.074 79.6988 202.613 79.4755C205.374 78.3313 208.202 77.3487 210.976 76.2257C212.399 75.6494 213.113 76.1789 213.543 77.4678C215.115 82.2021 216.72 86.9279 218.228 91.6814C218.359 92.1068 217.997 93.0596 217.65 93.1851C214.328 94.3804 210.963 95.4501 207.602 96.5561L205.23 90.5414L203.905 87.0896L202.046 82.7572L201.306 80.8728Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M315.012 127.69C315.24 127.92 315.467 128.152 315.695 128.384C314.507 134.063 313.357 139.715 312.154 145.373C308.333 145.754 304.478 145.108 301 143.503C301.283 142.061 301.604 140.626 301.841 139.177C302.611 134.498 303.351 129.792 304.1 125.098L305.159 125L315.012 127.69Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M304.908 84.1781C301.933 83.9654 298.944 83.8612 295.997 83.4464C295.38 83.3614 294.438 82.1065 294.487 81.4387C294.783 76.4555 295.369 71.4873 295.682 66.5041C295.805 64.5666 296.761 64.2475 298.363 64.473C301.316 64.8983 304.647 65.4917 307.599 65.9065C307.565 67.0805 305.63 79.3991 304.908 84.1781Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M137.857 187.163C136.261 189.405 134.578 191.591 133.112 193.908C132.298 195.184 131.636 195.728 130.107 194.875C126.382 192.795 122.547 190.9 118.837 188.795C118.304 188.493 117.758 187.149 118 186.766C119.956 183.669 122.064 180.662 124.223 177.699C124.535 177.274 125.377 177.208 125.972 176.978C129.207 179.235 132.443 181.515 135.713 183.733C136.964 184.588 138.211 185.366 137.857 187.163Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M352.316 141.164C352.829 141.132 353.435 140.907 353.826 141.101C355.379 141.847 356.889 142.691 358.375 143.566C363.316 146.467 363.364 146.507 360.599 151.545C358.951 154.544 357.037 157.401 355.374 160.391C354.598 161.788 353.897 162.171 352.422 161.242C350.381 159.966 348.149 158.968 346.115 157.677C345.508 157.292 345.286 156.322 344.887 155.62L352.316 141.164Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M343.884 155.476C343.043 155.457 342.113 155.654 341.375 155.372C339.434 154.636 337.646 153.519 335.688 152.872C333.548 152.164 333.457 150.958 334.214 149.24C336.093 144.969 337.907 140.667 339.876 136.434C340.152 135.841 341.373 135.065 341.785 135.237C344.758 136.479 347.661 137.893 350.508 139.395C350.881 139.593 350.823 140.588 350.963 141.211L343.884 155.476Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M171.246 115.174C172.029 116.27 171.063 116.855 170.599 117.21C168.398 118.872 166.113 120.423 163.753 121.857C163.337 122.112 162.151 121.857 161.844 121.459C158.801 117.452 155.826 113.39 152.96 109.26C152.638 108.798 152.83 107.412 153.255 107.133C156.167 105.144 159.204 103.334 162.2 101.467L171.246 115.174Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M389.498 169.438C391.526 171.748 393.496 174.076 395.563 176.313C396.74 177.589 396.837 178.488 395.321 179.656C392.005 182.21 388.757 184.862 385.619 187.625C384.284 188.803 383.573 188.646 382.563 187.326C381.474 185.872 380.244 184.526 378.891 183.306C377.419 181.999 377.395 181.033 378.9 179.714C381.401 177.523 383.779 175.186 386.175 172.887C387.299 171.815 388.334 170.658 389.498 169.438Z" fill="#63D2CC" className="camaraparty25"/>
                                                <path d="M363.539 167.56C361.764 166.52 360.047 165.362 358.195 164.475C356.539 163.682 356.231 162.827 357.217 161.261C359.465 157.686 361.644 154.071 363.755 150.417C364.697 148.788 365.703 148.652 367.175 149.684C368.947 150.932 370.738 152.171 372.623 153.244C374.171 154.124 374.547 154.962 373.405 156.495C370.751 160.051 368.215 163.695 365.633 167.304L365.044 168.641L364.414 168.664L363.539 167.56Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M373.278 176.278C371.678 174.984 370.153 173.583 368.455 172.43C366.936 171.398 366.759 170.507 367.967 169.105C370.724 165.906 373.419 162.657 376.052 159.36C377.226 157.889 378.242 157.905 379.543 159.145C381.108 160.644 382.695 162.137 384.398 163.479C385.798 164.581 386.044 165.465 384.685 166.81C381.53 169.93 378.479 173.154 375.386 176.337L374.604 177.572L373.978 177.5L373.278 176.278Z" fill="#CB5D5F" className="camaraparty28"/>
                                                <path d="M396.985 159.778C395.511 158.274 394.125 156.67 392.534 155.298C391.11 154.07 391.036 153.143 392.429 151.86C395.608 148.932 398.731 145.946 401.796 142.902C403.163 141.545 404.194 141.68 405.37 143.095C406.786 144.803 408.224 146.507 409.798 148.072C411.091 149.357 411.237 150.286 409.698 151.493C406.123 154.294 402.641 157.214 399.122 160.087L398.182 161.25L397.553 161.103L396.985 159.778Z" fill="#8FC7E0" className="camaraparty29"/>
                                                <path d="M393.559 206.175L409.236 196.532C410.921 200.133 412.445 203.425 413.932 206.741C414.495 207.993 413.593 208.215 413.129 208.442C408.77 210.569 404.374 212.645 399.926 214.591C399.383 214.829 398.076 214.482 397.795 214.017C396.273 211.492 394.963 208.827 393.559 206.175Z" fill="#7A0000" className="camaraparty9"/>
                                                <path d="M410.395 253.925C409.936 251.358 409.468 248.421 408.969 245.862C408.618 244.067 408.734 242.929 411.1 242.598C415.427 241.996 419.709 241.049 423.984 240.131C425.539 239.795 426.357 240.031 426.555 241.732C426.861 244.323 427.258 246.905 427.72 249.469C428.048 251.284 427.153 251.941 425.526 252.134C421.178 252.658 411.107 253.983 410.395 253.925Z" fill="#8EBB40" className="camaraparty27"/>
                                                <path d="M407.287 240.772C406.672 238.238 406.025 235.335 405.371 232.812C404.91 231.042 404.956 229.899 407.298 229.423C411.58 228.557 415.795 227.35 420.006 226.171C421.538 225.741 422.368 225.926 422.67 227.612C423.135 230.179 423.689 232.732 424.307 235.264C424.745 237.055 423.892 237.765 422.28 238.058C417.972 238.847 408.001 240.786 407.287 240.772Z" fill="#89A900" className="camaraparty13"/>
                                                <path d="M318.468 129C321.624 129.663 324.775 130.338 327.933 130.98C329.227 131.241 329.68 131.907 329.335 133.183C328.063 137.909 326.747 142.626 325.604 147.38C325.213 148.99 324.655 149.388 323.002 148.841C320.386 147.977 317.672 147.395 315 146.693L315.582 141.944C315.671 141.775 315.708 141.586 315.69 141.397C316.618 137.264 317.544 133.131 318.468 129Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M266.313 142.158C262.911 142.4 259.063 142.957 255.664 143.2C255.385 140.931 255.106 138.663 254.827 136.394C254.6 132.736 254.372 129.077 254.143 125.417C254.311 125.309 265.778 124.041 265.918 124.118C265.965 125.458 266.014 126.797 266.062 128.135C266.026 129.397 266.341 138.636 266.313 142.158Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M337.126 88.3337C338.459 83.3952 339.699 78.6864 341.009 73.7671C341.037 73.6612 341.086 73.5622 341.155 73.476C341.223 73.3898 341.309 73.3183 341.406 73.2659C341.503 73.2135 341.611 73.1813 341.721 73.1713C341.831 73.1613 341.943 73.1737 342.048 73.2077C343.364 73.6331 345.284 74.3286 346.192 74.6072C351.785 76.4426 351.8 76.4448 349.999 82.0511C348.961 85.2924 347.842 88.5081 346.876 91.7707C346.401 93.3765 345.851 94.0847 343.981 93.319C342.074 92.5406 339.514 91.8685 337.368 91.2496C337.234 91.2109 337.109 91.1463 337 91.0597C336.891 90.973 336.801 90.866 336.734 90.7448C336.668 90.6236 336.626 90.4906 336.612 90.3535C336.598 90.2164 336.611 90.0778 336.652 89.9459C336.831 89.3185 337.046 88.657 337.126 88.3337Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M151.726 148.778C148.197 146.507 155.842 141.626 157.712 140.031C157.957 139.822 158.272 139.712 158.595 139.722C158.919 139.731 159.227 139.859 159.459 140.082C162.339 142.864 167.619 147.966 169.812 149.846C169.575 150.799 163.674 155.676 161.648 158.202C158.613 155.01 155.152 152.098 151.726 148.778Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M222.99 165.208C223.288 164.674 223.452 163.885 223.911 163.649C226.456 162.343 229.088 161.197 231.659 159.942C233.029 159.274 233.754 159.681 234.26 161.038C235.85 165.278 237.464 169.509 239.163 173.707C239.767 175.196 239.463 175.955 237.888 176.472C235.201 177.374 232.574 178.45 229.923 179.449L226.927 172.756C225.615 170.247 224.303 167.731 222.99 165.208Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M234.692 98.7809L243.002 97.3411L243.725 101.45L244.456 106.888L245.116 115.183C242.357 115.808 239.607 116.485 236.829 117.001C236.415 117.078 235.494 116.455 235.418 116.047C234.443 110.757 233.579 105.446 232.686 100.142L234.692 98.7809Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M135.048 217.303C136.248 214.257 137.376 211.18 138.715 208.196C138.914 207.757 140.277 207.419 140.883 207.632C145.026 209.08 149.095 210.726 153.219 212.224C154.972 212.862 155.639 213.636 154.623 215.491C153.473 217.588 152.308 220.299 151.327 222.484C150.717 222.386 150.225 222.158 149.601 221.965C144.744 220.448 139.899 218.866 135.048 217.303Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M301.096 113.445C298.305 113.339 295.318 112.99 292.542 112.731C292.233 112.701 291.793 111.406 291.843 110.727C292.214 105.623 292.729 100.546 293.12 95.4524C293.236 93.9338 294.17 93.9083 295.277 94.0253C297.339 94.2379 299.399 94.4974 301.458 94.7356C303.134 95.3162 303.119 96.6221 302.942 98.0258C302.477 101.69 301.325 112.074 301.096 113.445Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M181.246 198.869C181.147 198.786 180.474 197.778 180.728 197.483C182.885 194.977 186.656 190.568 187.273 190.026C187.564 189.769 188.332 189.943 188.981 190.009C189.16 190.025 189.329 190.1 189.46 190.222C192.503 193.14 195.545 196.058 198.588 198.978C198.731 199.116 198.815 199.303 198.825 199.5C198.834 199.697 198.767 199.891 198.638 200.041C196.552 202.413 194.512 204.484 192.072 207.06C191.932 207.209 191.74 207.298 191.534 207.309C191.329 207.32 191.127 207.252 190.972 207.119L181.246 198.869Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M273.735 65.1939C273.737 65.0011 273.815 64.8166 273.953 64.6798C274.091 64.5431 274.277 64.465 274.473 64.4622C276.886 64.4261 279.302 64.4176 281.714 64.3155C283.193 64.2559 283.767 64.7898 283.735 66.2785C283.625 71.3978 283.635 76.5214 283.519 81.6406V81.6576C283.507 82.0617 283.336 82.4453 283.043 82.7277C282.749 83.0101 282.357 83.1694 281.947 83.172C279.626 83.1911 277.389 83.1252 274.335 83.123C274.236 83.1233 274.138 83.1042 274.046 83.0669C273.955 83.0295 273.872 82.9746 273.802 82.9054C273.732 82.8361 273.677 82.7539 273.639 82.6635C273.602 82.5731 273.583 82.4763 273.584 82.3786C273.631 76.8638 273.681 70.713 273.735 65.1939Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M310.567 156.263C313.372 156.831 316.204 157.311 318.962 158.047C319.48 158.187 320.149 159.451 320.013 160.027C318.82 165.095 317.496 170.134 316.085 175.149C315.964 175.574 314.832 176.142 314.359 176C311.892 175.274 309.487 174.343 307.058 173.482C307.307 171.638 307.554 169.795 307.8 167.952C307.882 167.776 307.911 167.582 307.884 167.39L310.567 156.263Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M396.591 240.806C396.924 242.257 396.561 242.978 394.903 243.342C391.398 244.114 387.944 245.125 384.496 246.127C383.067 246.543 382.344 246.101 382.07 244.741C381.911 243.946 381.801 243.139 381.603 242.356C380.381 237.674 380.376 237.684 385.256 236.155C387.701 235.388 390.151 234.639 392.567 233.79C394.104 233.247 394.841 233.645 395.159 235.239C395.528 237.11 396.103 238.949 396.591 240.806Z" fill="#89A900" className="camaraparty13"/>
                                                <path d="M161.402 201.673C159.998 204.193 158.628 206.735 157.123 209.195C157.006 209.387 155.874 209.168 155.343 208.893C151.554 206.945 147.833 204.852 144.011 202.97C142.386 202.168 141.942 201.158 142.96 199.78C144.811 197.294 146.133 194.835 148.396 192.381C152.55 195.394 157.248 198.668 161.402 201.673Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M212.322 108.966C212.343 108.757 212.328 108.545 212.276 108.341L212.414 107.667C214.981 106.64 217.58 105.685 220.1 104.555C221.53 103.917 222.257 104.3 222.703 105.655C224.157 110.089 225.643 114.509 227.041 118.975C227.2 119.49 227.011 120.589 226.726 120.677C223.851 121.646 220.919 122.461 218.005 123.32C216.586 119.686 215.168 116.051 213.752 112.416L213.143 110.838C213.16 110.752 213.157 110.664 213.135 110.58C213.113 110.496 213.073 110.418 213.016 110.351C212.831 110.032 212.644 109.713 212.455 109.394C212.49 109.319 212.495 109.234 212.47 109.155C212.446 109.076 212.393 109.009 212.322 108.966Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M354.599 199.104C358.056 195.726 361.449 192.528 364.691 189.181C365.938 187.895 366.722 188.141 367.688 189.336C368.8 190.711 369.945 192.06 371.122 193.385C372.209 194.605 372.389 195.556 370.858 196.702C367.759 199.023 364.769 201.494 361.799 203.98C360.694 204.904 359.911 205.017 358.929 203.837C357.605 202.251 356.141 200.775 354.599 199.104Z" fill="#CFBB5D" className="camaraparty24"/>
                                                <path d="M415.437 187.264C413.209 183.791 410.975 180.391 408.884 176.908C408.685 176.578 409.221 175.483 409.709 175.14C413.835 172.226 418.051 169.443 422.183 166.531C423.29 165.754 423.808 166.205 424.372 167.085C426.284 170.073 428.269 173.018 430.065 176.07C430.349 176.553 430.051 177.849 429.585 178.19C428.018 179.338 426.218 180.165 424.566 181.219C421.526 183.175 418.529 185.207 415.437 187.264Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M400.108 182.27C401.901 185.021 403.631 187.783 405.476 190.464C406.527 191.992 406.492 192.957 404.711 193.962C400.816 196.159 396.977 198.468 393.238 200.913C391.647 201.956 390.919 201.682 390.052 200.132C389.121 198.428 388.025 196.817 386.78 195.322C385.422 193.715 385.543 192.69 387.336 191.524C390.315 189.588 393.185 187.478 396.069 185.411C397.421 184.449 398.692 183.383 400.108 182.27Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M362.818 207.966C367.229 205.025 371.536 202.263 375.709 199.311C377.314 198.176 378.11 198.601 378.909 200.085C379.83 201.793 380.791 203.48 381.792 205.146C382.717 206.682 382.719 207.744 380.836 208.669C377.024 210.544 373.299 212.603 369.594 214.682C368.215 215.455 367.348 215.418 366.528 213.946C365.422 211.969 364.141 210.081 362.818 207.966Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M228.982 180.136C226.478 181.319 223.219 183.229 220.715 184.411L214.544 174.611L212.374 171.382C212.379 171.159 212.334 170.938 212.242 170.734L212.374 170.019C214.923 168.53 217.451 166.992 220.042 165.57C220.559 165.285 221.314 165.414 221.961 165.357L226.142 173.715C227.203 175.997 228.007 177.856 228.982 180.136Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M273.75 94.6271C276.573 94.4144 279.395 94.1294 282.22 94.0635C282.615 94.0635 283.379 95.1014 283.387 95.6671C283.454 100.772 283.37 105.876 283.387 110.993C283.387 112.254 282.956 112.937 281.588 112.85C279.194 112.697 276.798 112.556 274.401 112.412C274.194 107.795 273.987 103.177 273.778 98.5575L273.75 94.6271Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M156.75 237.803C157.783 234.826 158.792 231.848 159.858 228.885C160.356 227.497 161.368 227.463 162.662 227.886C166.48 229.134 170.328 230.302 174.176 231.463C175.776 231.946 175.092 233.237 174.84 234.015C174.029 236.495 173.306 238.031 171.967 241.336L156.75 237.803Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M268.142 153.281C268.137 153.025 268.234 152.778 268.413 152.593C268.592 152.408 268.838 152.301 269.097 152.294C271.366 152.228 274.058 152.151 276.168 151.981C278.077 151.828 278.56 152.607 278.497 154.344C278.342 158.796 278.27 163.251 278.282 167.709C278.282 169.474 278.079 169.542 276.054 169.506C273.987 169.47 271.612 169.451 269.397 169.353C269.15 169.343 268.915 169.24 268.741 169.067C268.567 168.893 268.465 168.661 268.457 168.417C268.291 163.698 268.189 155.982 268.142 153.281Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M339.361 167.437C342.165 168.939 344.969 170.395 347.706 171.993C348.019 172.173 348.138 173.269 347.885 173.694C345.549 177.735 343.162 181.742 340.647 185.672C340.368 186.098 339.057 186.336 338.49 186.085C336.391 185.154 333.693 183.32 331.665 182.238C331.973 181.093 337.342 171.444 339.361 167.437Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M399.616 261C399.731 263.153 399.78 265.316 399.98 267.462C400.128 269.046 399.44 269.676 397.949 269.749C396.824 269.813 391.415 270.01 389.83 269.999C386.761 269.978 386.761 269.989 386.175 267.008C386.119 266.321 386.061 265.633 386 264.944C386.065 261.682 386.067 261.725 389.216 261.586C392.69 261.448 396.15 261.204 399.616 261Z" fill="#698ED4" className="camaraparty17"/>
                                                <path d="M267.48 169.545C264.456 170.147 261.395 170.55 258.317 170.751C258.134 169.972 257.646 166.267 257.586 165.195L257.049 160.771L256.95 159.738C256.751 158.036 256.326 154.278 256.326 154.278C260.064 152.879 263.185 152.972 266.803 152.802C267.02 158.321 267.251 164.019 267.48 169.545Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M186.443 141.12C184.516 142.345 182.539 143.502 180.686 144.822C179.573 145.618 178.857 145.46 178.115 144.433C175.369 140.669 172.591 136.915 169.881 133.125C169.623 132.763 169.506 131.821 169.681 131.715C172.511 129.99 174.668 127.106 178.309 126.772L179.674 128.865L180.239 129.716C180.254 129.922 180.341 130.118 180.485 130.268L181.009 130.949C182.459 133.221 183.986 135.449 185.323 137.783C185.897 138.778 186.08 139.999 186.443 141.12Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M169.806 167.301C171.62 165.721 174.161 163.43 175.938 161.812C176.993 160.85 177.823 160.789 178.824 161.933C181.611 165.123 184.497 168.222 187.281 171.41C187.674 171.859 188.08 172.931 187.868 173.152C185.858 175.279 183.733 177.295 181.574 179.275C181.404 179.432 180.735 179.062 180.297 178.941C177.558 175.887 170.44 168.162 169.806 167.301Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M303.79 154.872L302.692 154.733C303.062 154.75 303.429 154.796 303.79 154.872Z" fill="#F39A01"/>
                                                <path d="M329.987 181.54C328.115 180.674 326.262 179.766 324.361 178.962C322.918 178.352 321.264 178.071 322.67 175.651C323.102 174.896 327.595 166.354 329.594 162.171C332.83 163.991 334.758 165.219 338.334 166.929C336.887 169.347 335.422 172.084 334.001 174.515C332.64 176.844 331.324 179.196 329.987 181.54Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M357.448 180.286C354.426 184.182 351.586 187.89 348.646 191.494C348.312 191.903 347.294 191.737 346.599 191.818C346.521 191.824 346.433 191.697 346.344 191.638C340.857 188.069 340.852 188.069 344.576 182.57C346.353 179.949 348.159 177.348 350.085 174.543L357.448 180.286Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M374.628 225C374.307 224.605 372.881 221.95 372.422 221.119C370.268 217.136 370.268 217.156 374.479 214.89C377.118 213.47 379.821 212.144 382.334 210.531C383.908 209.521 384.486 210.034 385.268 211.376C385.541 211.844 389 217.641 389 217.641C387.113 218.549 375.267 224.684 374.628 225Z" fill="#7A0000" className="camaraparty9"/>
                                                <path d="M333.776 102.148C336.6 103.186 339.863 104.153 342.635 104.896C342.887 104.964 342.359 106.116 342.158 106.754C340.722 111.325 339.162 115.857 337.689 120.415C337.225 121.855 336.531 122.087 335.101 121.466C333.526 120.779 331.796 120.432 330.14 119.937L329.406 119.298C330.791 113.786 332.396 107.646 333.776 102.148Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M298.756 142.583C296.519 142.534 294.272 142.57 292.052 142.353C291.677 142.317 291.099 141.171 291.142 140.575C291.522 135.273 291.972 129.973 292.561 124.69C292.619 124.171 293.763 123.363 294.382 123.38C296.552 123.446 298.713 123.805 300.876 124.064C300.715 126.204 300.628 128.352 300.372 130.481C299.884 134.518 299.3 138.542 298.756 142.583Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M172.998 208.136C173.235 207.105 173.762 206.724 174.266 205.839C175.331 203.964 176.552 202.178 177.916 200.501C178.38 199.935 179.18 198.655 180.252 199.554C183.738 202.532 187.225 204.918 190.711 207.889C189.218 209.657 186.397 213.389 184.508 215.759C180.839 213.468 176.665 210.416 172.998 208.136Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M254.066 143.206C251.831 143.676 249.61 144.231 247.353 144.546C246.907 144.608 245.958 143.814 245.865 143.306C244.976 138.376 244.191 133.427 243.471 128.469C243.402 127.986 243.902 127 244.245 126.949C246.909 126.555 249.599 126.334 252.28 126.057C252.445 126.995 252.611 127.933 252.776 128.871C252.961 130.69 253.146 132.509 253.33 134.326C253.576 137.279 253.821 140.238 254.066 143.206Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M222.876 136.502C222.852 136.444 222.834 136.383 222.82 136.321C222.585 135.22 222.911 134.529 224.149 134.118C225.954 133.52 227.714 132.791 229.707 131.991C229.867 131.928 230.037 131.897 230.209 131.902C230.381 131.906 230.55 131.946 230.706 132.017C230.862 132.089 231.001 132.191 231.115 132.318C231.229 132.445 231.315 132.594 231.368 132.755C232.951 137.549 234.509 142.24 236.014 146.947C236.165 147.421 236.489 148.153 235.846 148.491C233.786 149.578 232.455 149.844 229.705 150.892C229.551 150.951 229.386 150.98 229.22 150.976C229.055 150.972 228.891 150.935 228.74 150.869C228.589 150.802 228.453 150.706 228.339 150.587C228.226 150.468 228.137 150.329 228.079 150.176L222.876 136.502Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M202.542 164.591C200.722 165.927 198.961 167.356 197.04 168.526C196.608 168.787 195.314 168.362 194.921 167.867C192.117 164.262 189.499 160.535 186.747 156.898C185.927 155.816 186.058 155.161 187.191 154.463C188.85 153.442 190.396 152.234 192.102 151.303C192.579 151.043 193.448 151.477 194.136 151.594L199.074 159.127L199.891 160.386L202.542 164.591Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M190.073 189.203C189.182 188.337 189.876 187.348 190.306 186.84C194.835 181.449 195.726 182.586 199.962 186.815C200.996 187.878 204.522 191.2 205.605 192.251C205.605 192.251 201.884 196.664 199.958 198.655L190.073 189.203Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M219.707 184.771C219.155 186.21 215.478 188.722 214.457 188.476C213.89 188.337 213.355 187.748 212.973 187.24C210.452 183.871 208.023 180.434 205.461 177.097C204.464 175.795 204.52 174.843 205.931 174.051C207.592 173.118 208.929 172.452 210.614 171.563C211.554 173.162 212.861 174.568 213.799 176.168C215.633 178.915 217.887 182.021 219.707 184.771Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M294.554 171.363C292.971 171.338 291.355 171.482 289.822 171.201C289.263 171.099 288.469 169.951 288.512 169.33C288.851 164.459 289.358 159.602 289.832 154.74C289.966 153.359 290.719 152.73 292.175 152.964C293.631 153.198 295.145 153.389 296.631 153.585C296.383 156.958 294.847 169.749 294.66 170.687C294.63 170.912 294.593 171.138 294.554 171.363Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M256.93 170.751C251.106 171.58 250.33 172.137 249.437 166.578C248.949 163.553 248.537 160.514 247.97 157.505C247.634 155.723 248.11 154.629 250.032 154.459C251.686 154.315 253.349 154.264 255.008 154.17C255.207 155.561 255.404 156.951 255.599 158.341C255.791 160.044 256.986 169.407 256.93 170.751Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M184.359 216.797C183.651 218.445 182.747 220.334 181.88 222.069C181.781 222.265 180.608 222.143 180.068 221.89C176.434 220.189 171.093 217.975 167.595 216.014C166.715 215.521 170.66 209.268 172.293 209.499C175.968 211.794 180.685 214.502 184.359 216.797Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M167.077 261.609C163.279 261.596 151.064 260.503 150.384 260.492C150.384 259.588 152.338 252.378 153.055 250.113C154.933 250.405 166.84 252.746 167.791 252.953C169.579 253.531 167.446 261.609 167.077 261.609Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M141.886 259.174C140.693 259.174 139.742 259.12 138.696 259.059C133.541 258.752 128.739 258.219 123.597 257.744C123.597 257.557 125.381 248.901 125.856 246.861C127.465 247.093 138.746 248.482 142.717 249.126C142.488 251.051 141.886 258.997 141.886 259.174Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M170.625 247.583L170.636 247.542L170.621 247.563C170.621 247.563 170.621 247.583 170.621 247.591C170.345 248.723 169.543 251.075 169.275 252.174C168.596 252.2 157.328 249.548 153.158 248.848C153.158 248.848 155.19 241.498 155.906 239.23L158.113 239.698L159.426 239.892L171.822 242.104C171.59 243.892 171.037 245.702 170.625 247.583Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M170.636 247.542C170.633 247.556 170.63 247.569 170.625 247.582C170.625 247.582 170.625 247.563 170.625 247.555L170.636 247.542Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M278.655 122.961C278.618 126.485 278.427 134.907 278.593 138.423C278.694 140.601 279.358 142.121 277.344 142.023C274.727 141.898 270.605 142.023 267.987 141.981C268.004 140.639 267.683 128.293 267.501 123.46C268.852 123.409 278.655 122.961 278.655 122.961Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M305.997 169.696C305.408 172.152 304.569 173.073 302.205 172.828C300.369 172.563 297.675 171.748 295.837 171.482C296.187 170.142 298.525 155.831 298.736 154.231L302.692 154.733L303.79 154.872L308.516 155.614C308.072 158.6 306.391 168.045 305.997 169.696Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M303.79 154.872L302.692 154.733C303.062 154.75 303.429 154.796 303.79 154.872Z" fill="#F39B05"/>
                                                <path d="M234.692 98.7809L232.695 100.14C232.649 98.6767 233.551 98.5576 234.692 98.7809Z" fill="#E7494F"/>
                                                <path d="M141.685 260.622C141.303 263.11 141.088 266.364 140.822 269.048C140.786 269.406 138.327 269.342 137.371 269.363C132.876 269.459 128.377 269.427 123.879 269.44C122.285 269.538 121.548 268.802 121.8 267.249C122.231 264.542 122.723 261.838 123.187 259.135C128.993 259.597 135.876 260.177 141.685 260.622Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M149.66 261.885C154.5 262.113 159.34 262.357 164.183 262.559C167.159 262.683 167.418 262.972 166.821 265.75C166.657 266.52 166.547 267.302 166.411 268.079C166.393 268.291 166.374 268.504 166.359 268.717L165.654 269.393C160.908 269.393 156.141 269.435 151.387 269.393C148.426 269.363 148.238 269.074 148.799 266.22C149.088 264.775 149.375 263.33 149.66 261.885Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M170.636 247.542L170.625 247.582C170.625 247.582 170.625 247.563 170.625 247.555L170.636 247.542Z" fill="#993A87" className="camaraparty2"/>
                                                </g>
                                                <path d="M412.36 268.03C412.131 265.432 411.926 262.465 411.658 259.872C411.468 258.053 411.685 256.93 414.072 256.81C418.435 256.596 422.784 256.035 427.124 255.501C428.703 255.305 429.496 255.613 429.542 257.325C429.616 259.933 429.782 262.54 430.013 265.136C430.178 266.972 429.227 267.547 427.59 267.594C423.212 267.728 413.064 268.15 412.36 268.03Z" fill="#0C2355" className="camaraparty19"/>
                                                <path d="M61.999 235.701C61.6729 237.767 60.008 250.865 59.089 250.863C58.6938 250.863 40.9374 249.824 38.8179 249.559C38.9491 247.49 39.2098 245.431 39.5985 243.393C40.1321 240.599 40.5125 237.765 41.2832 235.033C41.5475 234.094 41.6463 232.035 43.5732 232.611C43.84 232.638 51.5942 233.984 52.8293 234.194C55.8826 234.721 58.9433 235.198 61.999 235.701Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M36.8867 267.773C36.8867 262.885 37.3324 256.566 38.1064 252.797C44.7822 252.747 51.4603 253.447 58.1361 253.399L57.9732 255.755C57.5131 259.743 58.1025 268.279 56.2527 268.249C50.9955 268.165 42.1583 267.766 36.8867 267.773Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M176.94 80.952C176.446 81.4696 176.02 82.1347 175.404 82.4836C170.457 85.2938 165.452 87.9916 160.515 90.8041C159.251 91.5231 158.18 91.9493 157.076 90.687C153.836 85.9308 150.259 80.4017 147.018 75.6454C146.402 74.6291 160.166 67.0369 165.408 64.0908C166.749 63.3391 167.82 63.0792 168.745 64.5241C171.476 69.9993 174.208 75.4753 176.94 80.952Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M79.4412 142.946C79.3869 142.91 79.3345 142.871 79.2842 142.83C78.0283 141.828 78.3914 141.13 78.8206 140.521C82.7452 134.977 86.7827 129.524 90.8618 124.098C90.9926 123.932 91.1521 123.791 91.3328 123.681C91.733 123.435 92.2027 123.329 92.6691 123.378C93.1356 123.428 93.5728 123.631 93.9132 123.955L105.741 135.058C106.128 135.419 106.369 135.91 106.42 136.439C106.471 136.967 106.328 137.496 106.018 137.925C104.86 139.512 103.703 141.098 102.52 142.667C100.298 145.628 98.0537 148.554 95.8142 151.493C95.4852 151.925 95.0067 152.217 94.4737 152.31C93.9407 152.404 93.3922 152.293 92.937 151.999C89.6084 149.855 82.6692 145.08 79.4412 142.946Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M195.292 73.3115L178.1 81.8335C177.286 80.5271 176.374 79.2692 175.681 77.9021C173.48 73.5587 171.362 69.1765 169.213 64.8137C169.514 63.8054 169.511 62.1984 170.162 61.8833C175.938 59.0717 181.827 56.4782 187.699 53.863C187.997 53.7296 188.466 53.9672 188.855 54.0326L190.011 56.9412C190.741 58.8237 191.47 60.7069 192.198 62.591L193.781 66.5345C194.283 68.7951 194.787 71.0541 195.292 73.3115Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M94.329 120.376C97.0926 117.499 104.207 110.483 107.149 107.493C107.313 107.326 107.512 107.191 107.733 107.097C107.955 107.004 108.195 106.953 108.439 106.947C108.683 106.942 108.925 106.983 109.151 107.068C109.377 107.152 109.582 107.278 109.754 107.439C113.438 110.878 117.706 114.806 121.362 118.219C121.658 118.496 121.841 118.86 121.878 119.248C121.914 119.637 121.803 120.025 121.563 120.345C121.053 121.03 120.603 121.68 120.017 122.353C117.335 125.424 113.575 128.801 110.394 131.95C109.74 132.598 108.84 132.983 107.884 133.022C106.929 133.062 105.995 132.754 105.282 132.163C102.207 129.607 97.4874 125.36 94.4385 122.698C94.0981 122.4 93.8962 121.991 93.8757 121.557C93.8553 121.124 94.0179 120.7 94.329 120.376Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M65.8633 215.158C64.5761 221.01 63.2873 226.861 61.9969 232.711L61.246 233.477C61.246 233.477 41.6604 229.915 41.7164 229.688C43.2303 223.537 44.7954 217.401 46.3483 211.262C50.3074 211.985 64.0691 214.648 65.8633 215.158Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M47.5112 210.349C47.8461 208.38 51.5325 196.106 53.0408 191.944C54.6078 192.183 67.6617 195.8 72.6241 197.377L67.119 214.16L60.9807 213.395L47.5112 210.349Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M453.46 144.616C452.676 144.179 447.057 136.281 444.583 133.081C443.108 131.172 443.055 129.663 445.172 128.028C449.566 124.633 453.832 121.069 458.074 117.492C459.187 116.556 459.951 116.081 461.023 117.507C464.024 121.496 470.673 130.609 471.533 131.517C466.098 135.38 458.884 140.743 453.46 144.616Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M404.025 69.5129C404.822 69.4673 405.852 69.0621 406.376 69.4318C410.643 72.4531 414.854 75.5654 418.96 78.8019C419.423 79.1691 419.518 80.7848 419.146 81.418C416.223 86.3841 413.115 91.2287 410.151 96.167C409.123 97.8789 408.166 97.3319 406.96 96.4911C403.063 93.7713 399.142 91.1071 395.228 88.4227C395.827 86.9412 396.351 85.4192 397.035 83.9808C399.334 79.1489 401.692 74.3347 404.025 69.5129Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M427.595 112.742C424.332 109.74 420.091 106.102 416.721 103.206C415.161 101.859 415.178 100.728 416.379 99.2674C419.78 95.1307 423.181 90.9939 426.512 86.7944C427.748 85.2329 428.798 85.4887 430.12 86.6207C433.709 89.693 437.972 93.224 441.59 96.2746C441.098 97.0518 428.553 111.6 427.595 112.742Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M92.0447 153.914C92.4588 154.189 92.75 154.605 92.8598 155.078C92.9696 155.552 92.8899 156.048 92.6367 156.467C90.0626 160.759 87.4886 165.061 84.7729 169.271C84.4666 169.744 82.7574 169.89 82.0599 169.52C77.0739 166.972 72.1574 164.295 66.8883 161.458C66.6487 161.33 66.4391 161.155 66.2727 160.945C66.1062 160.735 65.9865 160.494 65.921 160.237C65.8555 159.98 65.8456 159.713 65.892 159.452C65.9385 159.191 66.0401 158.942 66.1907 158.721C69.4057 153.969 72.3299 149.612 75.3699 145.33C75.6556 144.93 76.348 144.801 77.0276 144.659C77.2923 144.604 77.5658 144.601 77.8315 144.652C78.0971 144.703 78.3491 144.806 78.572 144.955L92.0447 153.914Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M492.782 169.024C492.415 169.282 481.458 174.518 476.535 176.927C474.752 177.797 473.859 177.726 472.981 175.709C471.429 172.124 469.444 168.729 467.811 165.174C467.507 164.516 467.714 163.008 468.201 162.699C473.22 159.491 478.31 156.399 483.472 153.422C483.988 153.125 485.489 153.454 485.718 153.91C488.131 158.668 490.381 163.51 492.67 168.33C492.726 168.558 492.763 168.79 492.782 169.024Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M377.8 77.9701C375.6 77.2134 366.704 73.032 364.323 72.0001C362.445 71.1894 361.944 70.3369 362.779 68.4353C364.889 63.6298 366.894 58.7834 368.794 53.896C369.614 51.7905 370.566 51.4023 372.667 52.4563C376.472 54.3701 380.452 55.94 384.302 57.7703C385.167 58.1806 385.806 59.0675 386.536 59.7358L377.8 77.9701Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M313.217 36.438C314.909 36.4548 325.795 38.0124 329.549 38.7744C329.281 41.3728 326.613 55.2685 325.549 59.6191L311.794 57.3284L310.231 56.6073C310.776 52.5018 312.765 39.0628 313.217 36.438Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M441.73 98.2539C443.669 99.8026 445.733 101.222 447.514 102.932C449.487 104.839 451.086 107.116 453.055 109.038C454.556 110.485 454.476 111.464 453.03 112.886C448.689 117.146 444.472 121.526 439.806 126.264L428.069 114.026L441.73 98.2539Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M254.211 56.8243C250.118 57.3273 238.926 58.5174 237.354 58.6528C237.094 56.8267 236.834 54.0572 236.574 52.219C236.012 47.5137 235.452 42.8085 234.893 38.1033C240.739 37.2083 246.845 36.3158 253.188 35.4717C253.24 35.4717 253.448 39.3707 253.5 40.7687C253.724 46.1214 253.974 51.4716 254.211 56.8243Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M471.207 133.991C472.152 134.506 477.173 143.03 479.555 146.424C480.652 147.99 480.403 148.856 478.815 149.811C474.098 152.639 469.385 155.487 464.788 158.496C463.279 159.484 462.569 159.263 461.691 157.873C459.225 153.931 456.668 150.039 454.147 146.126C459.403 142.251 465.96 137.869 471.207 133.991Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M53.3066 190.053C55.1908 185.081 57.0303 179.118 59.0163 174.185C59.1752 173.794 60.3792 173.458 60.9055 173.646C66.4215 175.617 71.9152 177.658 77.3593 179.815C77.9129 180.033 78.5807 181.374 78.3846 181.866C76.5202 186.552 74.4796 191.169 72.4887 195.808C67.6801 194.394 54.7862 190.602 53.3066 190.053Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M393.359 86.6629C392.141 86.2652 379.519 78.6082 378.808 77.6408L387.837 58.6523C391.581 60.7975 401.669 67.2741 402.955 68.3698C402.455 68.9574 395.553 82.0749 393.359 86.6629Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M332.566 40.3003C337.475 41.3889 342.991 42.7747 347.9 43.8633C347.63 45.2247 347.178 46.4205 346.848 47.7867C345.58 53.2079 342.806 64.4473 342.806 64.4473C338.475 63.2832 332.891 62.0752 328.582 60.8551L330.358 50.7116L332.566 40.3003Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M271.595 55.9565C271.042 56.4546 270.549 57.0616 269.977 57.0875C265.457 57.2821 260.994 57.3469 256.369 57.6868C256.158 55.9981 255.019 35.5469 255.193 35.5106C260.437 34.4289 266.388 34.1954 270.993 34.9321C271.195 41.9411 271.395 48.9493 271.595 55.9565Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M109.801 105.588C109.498 105.301 109.328 104.915 109.328 104.513C109.328 104.112 109.498 103.726 109.801 103.438C110.358 102.912 110.725 102.583 111.269 102.046C113.603 99.7389 115.968 97.5815 118.4 95.3554C119.94 93.9344 121.13 94.131 122.428 95.6277C126.019 99.7578 129.691 103.822 133.261 107.964C133.687 108.454 133.385 109.268 133.146 109.456C129.947 111.973 127.348 114.793 124.072 117.228C123.747 117.471 123.335 117.592 122.918 117.569C122.501 117.545 122.108 117.378 121.817 117.1C117.98 113.41 113.654 109.283 109.801 105.588Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M235.804 59.2665C232.477 59.9285 229.111 60.4383 225.833 61.3105C223.873 61.8348 222.949 61.6174 222.591 59.4791C221.648 53.8689 220.594 48.2781 219.507 42.6944C219.208 41.1602 219.894 40.4958 221.227 40.2783C225.325 39.6163 229.43 39.0026 233.535 38.3696C234.309 44.1248 235.083 49.8799 235.858 55.6351L235.804 59.2665Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M191.427 52.7918C191.869 52.2298 192.214 51.4131 192.768 51.1509C195.6 49.8071 198.5 48.6532 201.346 47.3345C202.806 46.6576 203.538 47.2796 203.979 48.7931C205.592 54.3528 207.238 59.9025 208.784 65.4847C208.919 65.9842 208.548 67.1032 208.191 67.2505C204.784 68.6542 201.332 69.9105 197.885 71.2093L195.451 64.146L194.093 60.0924L192.186 55.0047L191.427 52.7918Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M308.222 57.6868C304.936 57.4373 301.633 57.3151 298.378 56.8288C297.697 56.729 296.655 55.2575 296.71 54.4744C297.037 48.6307 297.685 42.8045 298.03 36.9609C298.166 34.6887 299.222 34.3146 300.992 34.579C304.255 35.0778 307.934 35.7737 311.196 36.26C311.158 37.6368 309.021 52.0825 308.222 57.6868Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M156.32 92.5101C157.208 93.7492 156.112 94.4108 155.586 94.8126C153.091 96.6926 150.501 98.4472 147.825 100.069C147.353 100.358 146.008 100.069 145.661 99.6195C142.211 95.0868 138.838 90.4916 135.589 85.8193C135.224 85.2973 135.442 83.7286 135.924 83.4135C139.225 81.1639 142.668 79.1165 146.064 77.0042L156.32 92.5101Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M345.558 61.3468C346.983 55.7516 348.309 50.4166 349.709 44.8431C349.739 44.7232 349.792 44.6109 349.865 44.5133C349.938 44.4157 350.029 44.3347 350.133 44.2753C350.237 44.2159 350.352 44.1795 350.47 44.1681C350.588 44.1568 350.707 44.1708 350.82 44.2094C352.227 44.6913 354.279 45.4792 355.25 45.7949C361.229 47.8744 361.245 47.8768 359.32 54.2287C358.21 57.901 357.014 61.5444 355.981 65.2408C355.473 67.0601 354.885 67.8625 352.886 66.9951C350.848 66.1131 348.111 65.3517 345.816 64.6505C345.673 64.6065 345.539 64.5334 345.423 64.4352C345.307 64.3371 345.21 64.2158 345.139 64.0785C345.068 63.9412 345.023 63.7905 345.008 63.6352C344.993 63.4798 345.008 63.3228 345.051 63.1733C345.242 62.4625 345.473 61.7131 345.558 61.3468Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M273.685 35.5452C273.687 35.3182 273.769 35.1009 273.913 34.9399C274.057 34.7789 274.253 34.6869 274.457 34.6837C276.983 34.6411 279.512 34.6311 282.035 34.5108C283.584 34.4407 284.185 35.0694 284.151 36.8226C284.036 42.8512 284.047 48.8848 283.925 54.9134V54.9335C283.912 55.4093 283.733 55.861 283.426 56.1936C283.12 56.5262 282.708 56.7137 282.279 56.7168C279.85 56.7393 277.509 56.6617 274.313 56.6592C274.209 56.6595 274.107 56.637 274.011 56.593C273.915 56.549 273.828 56.4844 273.755 56.4028C273.682 56.3213 273.624 56.2245 273.585 56.118C273.546 56.0116 273.526 55.8975 273.527 55.7825C273.577 49.2881 273.629 42.0447 273.685 35.5452Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M29.6418 231.927C29.2614 234.211 27.3189 248.688 26.2468 248.686C25.7857 248.686 5.06992 247.537 2.59717 247.245C2.75031 244.958 3.05443 242.682 3.50788 240.429C4.13039 237.341 4.57422 234.209 5.4734 231.19C5.78177 230.152 5.89705 227.875 8.14501 228.513C8.45627 228.542 17.5029 230.03 18.9439 230.262C22.506 230.844 26.0768 231.371 29.6418 231.927Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M0.666016 269.37C0.666016 263.565 1.19274 256.061 2.10743 251.586C9.99698 251.526 17.8894 252.358 25.7789 252.3L25.5863 255.098C25.0426 259.834 25.7393 269.971 23.5531 269.935C17.34 269.836 6.8961 269.361 0.666016 269.37Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M161.002 54.1387C160.444 54.7277 159.963 55.4845 159.268 55.8815C153.682 59.0793 148.032 62.1493 142.457 65.3497C141.03 66.1678 139.821 66.6528 138.575 65.2165C134.916 59.8042 130.878 53.5125 127.219 48.1002C126.523 46.9436 142.063 38.3042 147.981 34.9519C149.495 34.0964 150.705 33.8006 151.749 35.4448C154.833 41.6753 157.917 47.9065 161.002 54.1387Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M49.1915 125.68C49.1278 125.638 49.0664 125.593 49.0074 125.544C47.535 124.376 47.9607 123.561 48.4639 122.85C53.0652 116.382 57.7988 110.02 62.5812 103.691C62.7345 103.496 62.9215 103.332 63.1334 103.204C63.6026 102.917 64.1533 102.793 64.7001 102.851C65.247 102.908 65.7596 103.145 66.1587 103.524L80.0258 116.477C80.479 116.898 80.7623 117.472 80.8222 118.088C80.8821 118.704 80.7144 119.321 80.3507 119.822C78.9934 121.673 77.636 123.524 76.2499 125.354C73.6444 128.809 71.013 132.223 68.3874 135.651C68.0017 136.155 67.4407 136.496 66.8158 136.605C66.1909 136.714 65.5478 136.584 65.0141 136.241C61.1117 133.74 52.976 128.17 49.1915 125.68Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M183.5 43.4998L162.842 54.543C161.878 53.0564 160.797 51.6249 159.976 50.0694C157.367 45.1268 154.857 40.1402 152.31 35.1756C152.666 34.0282 152.663 32.1996 153.434 31.841C160.28 28.6416 167.26 25.6904 174.219 22.7144C174.573 22.5627 175.128 22.833 175.589 22.9075L176.96 26.2172C177.824 28.3593 178.688 30.5024 179.551 32.6464C181.164 36.5049 182.549 39.4135 183.5 43.4998Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M66.8791 99.9038C70.1192 96.6006 78.4599 88.5459 81.9091 85.1124C82.1013 84.9204 82.3345 84.7658 82.5946 84.6581C82.8546 84.5504 83.1361 84.4919 83.4218 84.4861C83.7076 84.4802 83.9916 84.5272 84.2567 84.6242C84.5219 84.7211 84.7625 84.866 84.964 85.0501C89.2822 88.9988 94.2872 93.5089 98.5725 97.4277C98.9203 97.7451 99.1345 98.1633 99.1776 98.6093C99.2207 99.0554 99.0901 99.501 98.8084 99.8685C98.2112 100.655 97.6826 101.401 96.9958 102.174C93.8512 105.699 89.4435 109.578 85.7136 113.193C84.9477 113.936 83.8918 114.378 82.7716 114.424C81.6515 114.47 80.5562 114.115 79.7201 113.437C76.1157 110.502 70.5821 105.626 67.0075 102.57C66.6084 102.228 66.3717 101.758 66.3477 101.26C66.3237 100.763 66.5144 100.276 66.8791 99.9038Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M34.4715 207.862C32.9783 214.732 31.4833 221.6 29.9864 228.468L29.1154 229.367C29.1154 229.367 6.39604 225.185 6.46108 224.919C8.21721 217.699 10.0327 210.495 11.8341 203.288C16.4266 204.137 32.3901 207.263 34.4715 207.862Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M12.9648 201.719C13.3406 199.498 17.4767 185.66 19.169 180.969C20.9272 181.238 35.5737 185.315 41.1416 187.094L34.9648 206.014L28.0776 205.152L12.9648 201.719Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M479.803 127.95C478.883 127.437 472.295 118.177 469.395 114.426C467.666 112.188 467.603 110.419 470.085 108.502C475.237 104.521 480.239 100.342 485.212 96.1491C486.517 95.0521 487.413 94.4947 488.67 96.1668C492.188 100.844 499.983 111.527 500.992 112.592C494.619 117.122 486.161 123.409 479.803 127.95Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M423.302 41.2887C424.226 41.2369 425.421 40.7758 426.029 41.1965C430.978 44.6345 435.863 48.1761 440.627 51.859C441.164 52.2769 441.274 54.1155 440.842 54.8359C437.451 60.487 433.846 65.9998 430.408 71.6193C429.215 73.5673 428.105 72.9449 426.707 71.9881C422.186 68.8931 417.637 65.8615 413.097 62.8068C413.792 61.121 414.4 59.3891 415.194 57.7522C417.861 52.2538 420.595 46.7756 423.302 41.2887Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M450.152 90.281C446.406 86.8497 441.537 82.693 437.667 79.383C435.876 77.8439 435.896 76.5503 437.275 74.8815C441.18 70.1538 445.084 65.4261 448.909 60.6267C450.328 58.8421 451.534 59.1345 453.052 60.4281C457.172 63.9394 462.067 67.9748 466.221 71.4612C465.656 72.3494 451.253 88.9763 450.152 90.281Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M64.3938 138.679C64.867 138.995 65.1998 139.475 65.3254 140.022C65.4509 140.568 65.3598 141.141 65.0704 141.624C62.1286 146.576 59.1868 151.54 56.0832 156.398C55.7331 156.943 53.7798 157.113 52.9825 156.685C47.2843 153.745 41.6655 150.656 35.6436 147.383C35.3698 147.235 35.1303 147.034 34.94 146.791C34.7498 146.549 34.613 146.271 34.5381 145.974C34.4632 145.678 34.452 145.369 34.505 145.068C34.5581 144.767 34.6743 144.48 34.8464 144.225C38.5207 138.742 41.8626 133.715 45.3368 128.774C45.6634 128.312 46.4547 128.163 47.2313 127.999C47.5339 127.935 47.8465 127.933 48.1501 127.992C48.4536 128.051 48.7416 128.169 48.9964 128.341L64.3938 138.679Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M525.139 155.21C524.715 155.499 512.073 161.364 506.392 164.061C504.335 165.035 503.305 164.956 502.292 162.697C500.501 158.682 498.21 154.88 496.327 150.897C495.976 150.161 496.215 148.472 496.776 148.126C502.568 144.533 508.441 141.069 514.397 137.736C514.992 137.403 516.724 137.772 516.988 138.282C519.772 143.612 522.369 149.034 525.01 154.432C525.074 154.688 525.118 154.948 525.139 155.21Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M476.803 205.651C476.457 205.844 466.345 209.455 461.796 211.125C460.149 211.728 459.367 211.59 458.763 209.762C457.696 206.513 456.231 203.391 455.091 200.161C454.879 199.563 455.189 198.27 455.644 198.043C460.345 195.686 465.099 193.435 469.906 191.291C470.387 191.077 471.683 191.492 471.847 191.907C473.574 196.251 475.15 200.652 476.763 205.038C476.793 205.241 476.807 205.446 476.803 205.651Z" fill="#FAD700" className="camaraparty10"/>
                                                <path d="M448.288 212.492C444.055 213.893 438.646 216.24 434.395 217.6C431.532 218.516 431.549 218.378 430.776 215.516C429.92 212.334 428.844 209.208 427.796 205.845C433.024 203.973 438.431 202.155 443.531 200.308C444.094 200.355 448.386 212.221 448.288 212.492Z" fill="#7A0000" className="camaraparty9"/>
                                                <path d="M399.357 218.519L416.373 211.505C417.461 215.328 418.44 218.822 419.378 222.332C419.733 223.659 418.808 223.733 418.314 223.884C413.671 225.287 409 226.634 404.298 227.844C403.723 227.992 402.489 227.441 402.286 226.937C401.186 224.201 400.32 221.361 399.357 218.519Z" fill="#7A0000" className="camaraparty9"/>
                                                <path d="M379.47 235.711C379.2 235.303 378.081 232.627 377.719 231.788C376.026 227.772 376.024 227.791 380.263 226.007C382.919 224.891 385.628 223.869 388.181 222.555C389.781 221.731 390.286 222.277 390.91 223.635C391.128 224.109 393.902 229.984 393.902 229.984C392.012 230.68 380.112 235.467 379.47 235.711Z" fill="#7A0000" className="camaraparty9"/>
                                                <path d="M503.58 194.74C503.189 194.962 491.778 199.113 486.644 201.033C484.785 201.726 483.903 201.569 483.225 199.477C482.027 195.759 480.38 192.188 479.1 188.491C478.861 187.807 479.212 186.326 479.727 186.065C485.033 183.358 490.399 180.773 495.825 178.311C496.367 178.065 497.829 178.538 498.012 179.013C499.953 183.983 501.724 189.02 503.536 194.039C503.569 194.271 503.584 194.506 503.58 194.74Z" fill="#FAD700" className="camaraparty10"/>
                                                <path d="M537.124 184.124C536.674 184.371 523.524 188.984 517.608 191.119C515.466 191.889 514.448 191.71 513.659 189.364C512.265 185.195 510.354 181.188 508.865 177.042C508.587 176.275 508.988 174.618 509.58 174.327C515.692 171.312 521.873 168.433 528.124 165.692C528.749 165.419 530.437 165.953 530.65 166.486C532.905 172.061 534.964 177.709 537.07 183.338C537.11 183.598 537.128 183.861 537.124 184.124Z" fill="#FAD700" className="camaraparty10"/>
                                                <path d="M483.138 224.328C482.774 224.483 472.339 227.014 467.64 228.199C465.939 228.625 465.176 228.406 464.766 226.525C464.046 223.181 462.917 219.924 462.122 216.591C461.973 215.975 462.417 214.722 462.893 214.544C467.816 212.692 472.779 210.952 477.785 209.324C478.285 209.162 479.531 209.71 479.65 210.14C480.912 214.641 482.019 219.183 483.163 223.714C483.171 223.919 483.163 224.124 483.138 224.328Z" fill="#C973FF" className="camaraparty11"/>
                                                <path d="M454.046 230.979C449.755 232.193 444.249 234.3 439.942 235.472C437.042 236.261 437.065 236.125 436.418 233.231C435.703 230.015 434.765 226.844 433.866 223.438C439.171 221.798 444.653 220.22 449.829 218.598C450.389 218.67 454.156 230.713 454.046 230.979Z" fill="#C973FF" className="camaraparty11"/>
                                                <path d="M510.911 216.285C510.5 216.464 498.716 219.396 493.409 220.768C491.488 221.262 490.627 221.013 490.172 218.862C489.37 215.038 488.107 211.314 487.221 207.503C487.055 206.798 487.56 205.362 488.099 205.157C493.66 203.021 499.267 201.013 504.921 199.133C505.486 198.945 506.891 199.568 507.023 200.06C508.432 205.207 509.666 210.401 510.941 215.582C510.95 215.817 510.94 216.052 510.911 216.285Z" fill="#BBE77B" className="camaraparty12"/>
                                                <path d="M545.383 209.243C544.91 209.441 531.348 212.65 525.242 214.153C523.031 214.694 522.037 214.41 521.498 211.994C520.549 207.702 519.068 203.517 518.022 199.238C517.826 198.446 518.399 196.839 519.018 196.613C525.413 194.254 531.862 192.04 538.365 189.969C539.015 189.763 540.638 190.471 540.794 191.023C542.452 196.804 543.908 202.637 545.413 208.455C545.424 208.718 545.415 208.982 545.383 209.243Z" fill="#BBE77B" className="camaraparty12"/>
                                                <path d="M487.254 243.736C486.877 243.857 476.256 245.431 471.469 246.184C469.736 246.455 468.996 246.167 468.759 244.256C468.345 240.861 467.516 237.515 467.027 234.124C466.934 233.496 467.49 232.289 467.981 232.155C473.051 230.757 478.152 229.475 483.285 228.308C483.797 228.192 484.989 228.851 485.068 229.29C485.917 233.887 486.606 238.511 487.334 243.127C487.324 243.332 487.298 243.535 487.254 243.736Z" fill="#A20000" className="camaraparty15"/>
                                                <path d="M457.679 247.719C453.295 248.539 447.62 250.137 443.225 250.913C440.265 251.436 440.301 251.302 439.919 248.361C439.499 245.094 438.852 241.851 438.266 238.378C443.698 237.226 449.301 236.151 454.602 235.007C455.154 235.129 457.812 247.464 457.679 247.719Z" fill="#120881" className="camaraparty14"/>
                                                <path d="M515.643 238.246C515.216 238.387 503.215 240.237 497.806 241.122C495.848 241.44 495.013 241.114 494.755 238.93C494.304 235.05 493.383 231.226 492.847 227.351C492.746 226.633 493.379 225.25 493.934 225.094C499.666 223.471 505.433 221.98 511.234 220.621C511.813 220.486 513.156 221.234 513.243 221.736C514.179 226.989 514.936 232.274 515.736 237.549C515.724 237.784 515.692 238.017 515.643 238.246Z" fill="#A34200" className="camaraparty16"/>
                                                <path d="M550.612 234.362C550.123 234.517 536.326 236.482 530.108 237.425C527.857 237.763 526.894 237.39 526.576 234.935C526.021 230.574 524.926 226.272 524.272 221.915C524.149 221.109 524.865 219.561 525.502 219.392C532.084 217.623 538.708 216.003 545.373 214.532C546.038 214.385 547.59 215.237 547.696 215.802C548.823 221.709 549.743 227.65 550.713 233.581C550.701 233.844 550.667 234.105 550.612 234.362Z" fill="#43A089" className="camaraparty18"/>
                                                <path d="M488.415 265.115C488.027 265.194 477.297 265.584 472.456 265.804C470.703 265.881 470 265.513 469.975 263.588C469.939 260.168 469.485 256.75 469.374 253.326C469.351 252.692 470.037 251.554 470.54 251.475C475.733 250.646 480.945 249.936 486.175 249.343C486.697 249.284 487.809 250.071 487.839 250.516C488.174 255.179 488.348 259.851 488.562 264.519C488.529 264.721 488.48 264.921 488.415 265.115Z" fill="#E17405" className="camaraparty21"/>
                                                <path d="M458.5 266.5C454.053 266.83 448.316 267.096 443.862 267.381C440.862 267.573 440.913 267.445 440.858 264.48C440.802 261.185 440.518 257.891 440.319 254.374C445.846 253.83 452.104 253.551 457.5 253C458.034 253.182 458.661 266.261 458.5 266.5Z" fill="#D2083C" className="camaraparty20"/>
                                                <path d="M517.236 262.797C516.797 262.89 504.665 263.402 499.191 263.684C497.209 263.783 496.416 263.367 496.401 261.168C496.381 257.261 495.889 253.36 495.785 249.448C495.764 248.724 496.546 247.419 497.115 247.326C502.991 246.347 508.887 245.502 514.803 244.793C515.394 244.722 516.645 245.614 516.676 246.123C517.026 251.447 517.194 256.783 517.406 262.115C517.368 262.346 517.311 262.575 517.236 262.797Z" fill="#F8CC2C" className="camaraparty22"/>
                                                <path d="M552.421 262.802C551.917 262.902 537.988 263.33 531.704 263.579C529.429 263.667 528.513 263.189 528.468 260.714C528.399 256.319 527.786 251.922 527.618 247.52C527.585 246.705 528.467 245.246 529.119 245.148C535.857 244.118 542.619 243.24 549.405 242.514C550.083 242.442 551.531 243.461 551.574 244.033C552.041 250.028 552.299 256.035 552.607 262.037C552.566 262.297 552.504 262.553 552.421 262.802Z" fill="#6ED35C" className="camaraparty23"/>
                                                <path d="M393.306 50.6794C390.753 49.7826 380.434 44.8268 377.672 43.6038C375.494 42.643 374.912 41.6326 375.881 39.3789C378.329 33.6835 380.655 27.9395 382.859 22.1471C383.81 19.6517 384.914 19.1916 387.351 20.4408C391.765 22.7091 396.382 24.5697 400.848 26.7389C401.851 27.2252 402.593 28.2763 403.439 29.0683L393.306 50.6794Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M318.976 2.3855C320.922 2.40443 333.442 4.15671 337.758 5.01393C337.45 7.93711 334.382 23.5698 333.159 28.4643L317.34 25.8872L315.542 25.076C316.169 20.4573 318.456 5.33843 318.976 2.3855Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M466.451 73.8606C468.677 75.6229 471.047 77.2379 473.092 79.1836C475.358 81.3545 477.193 83.9451 479.454 86.1327C481.177 87.7782 481.085 88.8929 479.425 90.5106C474.442 95.3583 469.599 100.342 464.242 105.735L450.766 91.8087L466.451 73.8606Z" fill="#F6A136" className="camaraparty7"/>
                                                <path d="M250.83 25.4408C246.123 26.0068 233.252 27.3456 231.445 27.498C231.145 25.4436 230.846 22.3279 230.547 20.2599C229.901 14.9665 229.257 9.67312 228.614 4.37972C235.337 3.37292 242.359 2.36884 249.654 1.41919C249.714 1.41919 249.953 5.80556 250.013 7.37834C250.27 13.4001 250.557 19.4191 250.83 25.4408Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M500.928 115.393C502.048 115.967 507.998 125.475 510.822 129.26C512.122 131.007 511.827 131.972 509.945 133.037C504.354 136.192 498.768 139.369 493.32 142.725C491.532 143.827 490.69 143.581 489.65 142.03C486.727 137.633 483.696 133.292 480.709 128.927C486.937 124.606 494.709 119.718 500.928 115.393Z" fill="#268247" className="camaraparty8"/>
                                                <path d="M19.9844 179.397C22.086 173.776 24.1377 167.035 26.3529 161.459C26.5301 161.017 27.873 160.637 28.46 160.85C34.6125 163.078 40.7401 165.385 46.8123 167.823C47.4298 168.07 48.1746 169.586 47.9559 170.142C45.8765 175.44 43.6004 180.658 41.3797 185.902C36.0164 184.303 21.6346 180.018 19.9844 179.397Z" fill="#F30017" className="camaraparty1"/>
                                                <path d="M411.043 60.3385C409.678 59.8722 395.542 50.895 394.746 49.7609L404.858 27.4985C409.052 30.0136 420.35 37.6068 421.79 38.8914C421.231 39.5804 413.5 54.9594 411.043 60.3385Z" fill="#3F95F8" className="camaraparty6"/>
                                                <path d="M341.574 7.2146C347.463 8.4774 354.084 10.0849 359.974 11.3477C359.65 12.9269 359.107 14.314 358.712 15.8989C357.19 22.1875 353.861 35.2251 353.861 35.2251C348.665 33.8748 341.963 32.4735 336.792 31.0582L338.923 19.2918L341.574 7.2146Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M271.113 24.5859C270.463 25.1462 269.883 25.8291 269.21 25.8583C263.892 26.0771 258.642 26.1501 253.2 26.5324C252.952 24.6326 251.612 1.62504 251.817 1.58418C257.986 0.367269 264.987 0.104626 270.405 0.933412C270.642 8.81855 270.878 16.7027 271.113 24.5859Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M84.2982 82.0953C83.9342 81.7595 83.7305 81.3094 83.7305 80.8409C83.7305 80.3724 83.9342 79.9224 84.2982 79.5866C84.9663 78.9732 85.4065 78.5892 86.0591 77.962C88.8605 75.2709 91.6988 72.7539 94.6171 70.1568C96.4642 68.4991 97.8926 68.7284 99.4503 70.4745C103.76 75.293 108.165 80.0342 112.45 84.8665C112.962 85.4384 112.598 86.3888 112.312 86.6071C108.473 89.544 105.355 92.8346 101.424 95.6749C101.033 95.9581 100.539 96.0999 100.038 96.0723C99.5374 96.0447 99.0662 95.8497 98.7176 95.5257C94.1123 91.2211 88.922 86.4054 84.2982 82.0953Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M229.52 28.7917C225.802 29.5364 222.04 30.11 218.375 31.0912C216.185 31.681 215.152 31.4364 214.752 29.0309C213.698 22.7194 212.521 16.4297 211.306 10.1481C210.971 8.42214 211.738 7.67466 213.227 7.43003C217.807 6.68526 222.396 5.99486 226.984 5.28271C227.848 11.7573 228.714 18.2318 229.58 24.7064L229.52 28.7917Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <path d="M178.389 21.5882C178 20.4998 178.385 20.304 179 19.9998C182.147 18.441 186.249 16.7874 189.41 15.2577C191.033 14.4725 191.846 15.194 192.336 16.9497C194.128 23.3989 195.957 29.8366 197.675 36.312C197.825 36.8914 197.412 38.1894 197.016 38.3603C193.23 39.9886 189.395 41.4459 185.565 42.9524L182.861 34.759L181.351 30.0568L179.232 24.1551L178.389 21.5882Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M313.138 26.5324C309.414 26.2518 305.67 26.1143 301.981 25.5672C301.209 25.4549 300.029 23.7995 300.091 22.9185C300.461 16.3443 301.195 9.78988 301.587 3.21577C301.741 0.659634 302.937 0.238756 304.944 0.536177C308.641 1.09735 312.811 1.88018 316.508 2.42732C316.465 3.97615 314.043 20.2276 313.138 26.5324Z" fill="#7E087C" className="camaraparty4"/>
                                                <path d="M138.405 67.8037C139.414 69.2492 138.169 70.0211 137.572 70.4899C134.736 72.6833 131.792 74.7302 128.752 76.6229C128.216 76.9597 126.687 76.6229 126.293 76.098C122.372 70.8098 118.54 65.4487 114.847 59.9978C114.433 59.3887 114.68 57.5586 115.228 57.1909C118.979 54.5665 122.891 52.1778 126.751 49.7134L138.405 67.8037Z" fill="#993A87" className="camaraparty2"/>
                                                <path d="M355.803 31.1244C357.496 24.5966 359.07 18.3725 360.732 11.87C360.768 11.7301 360.831 11.5992 360.918 11.4853C361.004 11.3714 361.113 11.2769 361.236 11.2076C361.36 11.1384 361.496 11.0958 361.636 11.0826C361.777 11.0693 361.918 11.0857 362.052 11.1307C363.722 11.6929 366.159 12.6122 367.312 12.9805C374.412 15.4066 374.432 15.4094 372.145 22.8199C370.828 27.1043 369.407 31.3549 368.18 35.6674C367.578 37.7899 366.879 38.726 364.505 37.714C362.085 36.6851 358.835 35.7967 356.11 34.9786C355.94 34.9274 355.781 34.8421 355.643 34.7275C355.505 34.613 355.39 34.4716 355.306 34.3114C355.221 34.1512 355.169 33.9754 355.15 33.7941C355.132 33.6129 355.15 33.4298 355.201 33.2553C355.428 32.426 355.702 31.5517 355.803 31.1244Z" fill="#D9D9D9" className="camaraparty5"/>
                                                <path d="M273.231 1.62885C273.233 1.37219 273.329 1.12658 273.5 0.944565C273.67 0.762545 273.901 0.65857 274.143 0.654881C277.128 0.606749 280.116 0.595424 283.099 0.459521C284.929 0.380244 285.639 1.0909 285.599 3.07282C285.463 9.88777 285.476 16.7084 285.332 23.5233V23.546C285.317 24.0838 285.106 24.5945 284.743 24.9705C284.38 25.3464 283.894 25.5585 283.387 25.5619C280.517 25.5874 277.75 25.4996 273.972 25.4968C273.85 25.4971 273.729 25.4717 273.615 25.422C273.502 25.3722 273.4 25.2992 273.313 25.207C273.227 25.1148 273.159 25.0054 273.112 24.885C273.066 24.7647 273.043 24.6358 273.044 24.5058C273.103 17.1642 273.164 8.97609 273.231 1.62885Z" fill="#2F3FC9" className="camaraparty3"/>
                                                <defs>
                                                <clipPath id="clip0_829_9842">
                                                <rect width="400.841" height="205.733" fill="white" transform="translate(66.3467 64.3154)"/>
                                                </clipPath>
                                                </defs>
                                            </svg>                                        
                                        </div>
                                    </div>
                                </div>

                                {/* Parties */}
                                <div className="col-md-3 mb-0">
                                    <div className="card">
                                        <div className="card-body overflow-auto">
                                            <ul className="parties">
                                                <li className="camara15">
                                                    <img src="./assets/images/parties/camara/14.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/27`} className="no-text-decoration">{ camaraParties[14].name }</Link>
                                                </li>
                                                <li className="camara16">
                                                    <img src="./assets/images/parties/camara/15.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/28`} className="no-text-decoration">{ camaraParties[15].name }</Link>
                                                </li>
                                                <li className="camara17">
                                                    <img src="./assets/images/parties/camara/17.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/29`} className="no-text-decoration">{ camaraParties[16].name }</Link>
                                                </li>
                                                <li className="camara18">
                                                    <img src="./assets/images/parties/camara/16.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/30`} className="no-text-decoration">{ camaraParties[17].name }</Link>
                                                </li>
                                                <li className="camara19">
                                                    <img src="./assets/images/parties/camara/18.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/31`} className="no-text-decoration">{ camaraParties[18].name }</Link>
                                                </li>
                                                <li className="camara20">
                                                    <img src="./assets/images/parties/camara/19.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/32`} className="no-text-decoration">{ camaraParties[19].name }</Link>
                                                </li>
                                                <li className="camara21">
                                                    <img src="./assets/images/parties/camara/20.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/33`} className="no-text-decoration">{ camaraParties[20].name }</Link>
                                                </li>
                                                <li className="camara22">
                                                    <img src="./assets/images/parties/camara/22.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/34`} className="no-text-decoration">{ camaraParties[21].name }</Link>
                                                </li>
                                                <li className="camara23">
                                                    <img src="./assets/images/parties/camara/23.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/35`} className="no-text-decoration">{ camaraParties[22].name }</Link>
                                                </li>
                                                <li className="camara24">
                                                    <img src="./assets/images/parties/camara/24.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/36`} className="no-text-decoration">{ camaraParties[23].name }</Link>
                                                </li>
                                                <li className="camara25">
                                                    <img src="./assets/images/parties/camara/25.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/37`} className="no-text-decoration">{ camaraParties[24].name }</Link>
                                                </li>
                                                <li className="camara26">
                                                    <img src="./assets/images/parties/camara/26.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/38`} className="no-text-decoration">{ camaraParties[25].name }</Link>
                                                </li>
                                                <li className="camara27">
                                                    <img src="./assets/images/parties/camara/27.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/39`} className="no-text-decoration">{ camaraParties[26].name }</Link>
                                                </li>
                                                <li className="camara28">
                                                    <img src="./assets/images/parties/camara/28.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/40`} className="no-text-decoration">{ camaraParties[27].name }</Link>
                                                </li>
                                                <li className="camara29">
                                                    <img src="./assets/images/parties/camara/29.png" alt="Party Badge" />
                                                    <Link to={`/parties/camara/41`} className="no-text-decoration">{ camaraParties[28].name }</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* )} */}
        </div>

        <br/>

        <div className="text-center my-4 px-3 d-flex justify-content-center gap-3">
            <img src="./assets/images/atlas.png" />

            <img src="./assets/images/icp-logo-2.png" className="me-4" />
        </div>

        {/* <br/>

        <div className="text-center mb-4 px-3">
            <Link className="btn btn-primary ver-mas" target="_blank" to={'https://icpcolombia.org/wp-content/uploads/2024/02/PREGUNTAS-FRECUENTES-IALE.pdf'}>Preguntas frecuentes</Link>
        </div> */}
    
    </>
}

export default Home;