const Footer = () => {
    return <>
        <div className="footer">
            <div className="row">
                <div className="col-md-6 col-xs-12 mb-4">
                    <p className="footer-title mb-2">
                        Instituto de Ciencia Política Hernán Echavarría Olózaga
                    </p>

                    <div className="row">
                        <div className="col-md-4 col-xs-6 d-flex align-items-start">
                            <img src="./assets/images/location.svg" className="me-2" />

                            <p className="footer-info-text me-4">
                                Calle 70#7A- 29 Bogotá,<br/>Colombia
                            </p>
                        </div>

                        <div className="col-md-4 col-xs-6 d-flex align-items-start">
                            <img src="./assets/images/call.svg" className="me-2" />

                            <p className="footer-info-text me-4">
                                +601 317 79 79 <br/>+57 313 431 20 95
                            </p>
                        </div>

                        <div className="col-md-4 col-xs-6 d-flex align-items-start">
                            <img src="./assets/images/message.svg" className="me-2" />

                            <p className="footer-info-text me-4">
                                info@icpcolombia.org
                            </p>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: 32 }}>
                        <div className="col-md-12 col-xs-12 d-flex align-items-start social-links">
                            <a href="https://www.facebook.com/ICPColombia" target="_blank">
                                <img src="./assets/images/facebook.svg" />
                            </a>

                            <a href="https://www.tiktok.com/@icp.colombia" target="_blank">
                                <img src="./assets/images/tiktok.svg" />
                            </a>

                            <a href="https://www.instagram.com/icp.colombia/?hl=es" target="_blank">
                                <img src="./assets/images/instagram.svg" />
                            </a>

                            <a href="https://www.youtube.com/@ICPColombia" target="_blank">
                                <img src="./assets/images/youtube.svg" />
                            </a>

                            <a href="https://www.linkedin.com/in/icp-instituto-de-ciencia-pol%C3%ADtica-hern%C3%A1n-echavarr%C3%ADa-ol%C3%B3zaga-6781ab51/" target="_blank">
                                <img src="./assets/images/linkedin.svg" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xs-12 mb-4">
                    <p className="footer-link"><a href="https://icpcolombia.org/" target="_blank" className="mb-2">¡Descubre qué hacemos y únete a la comunidad ICP!</a></p>

                    <p className="footer-info-text-right">
                        Copyright 2023 ICP | Todos los derechos reservados |<br/>
                        Diseñado por <a href="https://www.frixb.com/" target="_blank">frixB</a>
                    </p>
                </div>
            </div>
        </div>
    </>
}

export default Footer