import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { baseUrl } from "../../Config";

const Projects = () => {

    const [ projects, setProjects ] = useState([]);

    useEffect(() => {
        fetch(`${baseUrl}/api/v1/projects-filter`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setProjects(data.data);
        })
    }, [])

    return <>
        {/* Breadcrumb Section */}
        <div className="breadcrumb-m">
        </div>

        <div className="projects-box">
            <div className="container">

                <img src="./assets/images/ellipse_1.png" className="ellipse1" style={{ zIndex: -1 }} />
                <img src="./assets/images/ellipse_2.png" className="ellipse2" style={{ zIndex: -1 }} />

                <div className="row mb-4">
                    <div className="col-md-12">
                        <h2 className="text-center">NORMATIVIDAD</h2>

                        <p className="sub-title">Elija un proyecto para verlo en detalle.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="project-header">
                            <p>Proyectos de Ley</p>
                        </div>

                        { projects['pending']?.length == 0 && (
                            <p className="empty-projects">Sin proyectos...</p>
                        )}

                        { projects['pending']?.map((item) => (
                            <div className="project-list-item" key={item.id}>
                                <span>{ item.name }</span>

                                <Link className="project-list-button" to={`../member-profile/448/project/${item.id}/normatividad`}>Ver más</Link>
                            </div>
                        ))}
                    </div>
                    <div className="col-md-6">
                        <div className="project-header">
                            <p>Leyes Sancionadas</p>
                        </div>

                        { projects['finished']?.length == 0 && (
                            <p className="empty-projects">Sin proyectos...</p>
                        )}

                        { projects['finished']?.map((item) => (
                            <div className="project-list-item" key={item.id}>
                                <span>{ item.name }</span>

                                <Link className="project-list-button" to={`../member-profile/448/project/${item.id}/normatividad`}>Ver más</Link>
                            </div>
                        ))}
                    </div>
                </div>

                <br/>
                <br/>
                <br/>
            </div>
        </div>
    </>
}

export default Projects